import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";
export default makeStyles((theme: Theme) =>
  createStyles({
    sectionSig: {
      padding: "calc(4.5rem + 3rem + 64px) 0 4rem",
    },
    titleSig: {
      textAlign: "center",
      padding: "1.2rem 1rem",
      position: "relative",
      "&::after": {
        content: "''",
        width: 14,
        height: 14,
        position: "absolute",
        background: theme.palette.primary.main,
        bottom: -7,
        transform: "rotate(45deg)",
        zIndex: 1,
      },
    },
    iconSig: {
      color: theme.palette.secondary.dark,
    },
    contIcon: {
      minHeight: "4.4rem",
      marginBottom: ".8rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "4rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "3rem ",
      },
    },
    itemPlanification: {
      padding: ".8rem",
      "&.itemLeft": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          paddingBottom: "2.4rem",
        },
      },
    },
    sectionGray: {
      padding: "1.4rem 2%",
    },
    sectionGrayTwo: {
      padding: "2rem 4%",
    },
    key: {
      height: "50%",
      position: "absolute",
      "& img": {
        height: "100%",
        width: "auto",
      },
    },
    textKey: {
      paddingLeft: "3rem",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "2.5rem",
      },
    },
    itemKey: {
      padding: ".6em 0",
    },
  })
);