import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Box, Container, Grid, Typography } from "@mui/material";

import useStyles from "./styles";

interface IBannerProps {
  image: string;
  title: any;
}

export default function BannerModelThree({image, title}: IBannerProps) {
  const classes = useStyles();

  return (
    <Box className={classes.contBannerModelThree}>
      <Box className={classes.bannerModelThree} sx={{ background: `url(${image})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "top center" }}>
        <Box className={classes.bannerCaption} sx={{ display: "flex", alignItems: "center" }}>
          <Container >
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} sm={8} >
                <Typography variant="subtitle1" component="div" sx={{ color: "primary.main", textAlign: "left", marginBottom: "1.8rem" }} className={classes.textCapion}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}