import React from "react";

import { SvgIcon } from "@mui/material";

export default function ScrewIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 120.5 120.4">
      <path d="M119.6,88.2c0,2.2,0,4.4,0,6.7c-0.5,3.1-1.3,6.1-2.7,9c-1.7,3.3-4.2,3.8-6.8,1.2c-3.5-3.5-6.9-6.9-10.4-10.4
		c-0.7-0.7-1.4-1.4-2.1-2.1C95.6,91,93,91.8,92.2,94c-0.5,1.5,0.1,2.7,1.1,3.7c4,4,8,8,12,12c2.5,2.5,2,5.1-1.2,6.7
		c-2.9,1.4-5.9,2.2-9.1,2.8c-2.2,0-4.4,0-6.7,0c-0.3-0.1-0.5-0.1-0.8-0.2c-14-2.5-23.6-13.9-23.4-27.9c0-2,0.3-3.9,0.7-5.8
		c0.1-0.7-0.8-2.2-1.6-2.5c-0.8-0.3-1,0.4-1.4,0.8c-9.7,9.7-19.5,19.4-29.1,29.1c-3.2,3.3-6.8,5.7-11.4,6.5c-1.7,0-3.4,0-5.1,0
		c-0.6-0.1-1.1-0.3-1.7-0.4c-7.6-1.8-13.1-8.8-13.2-16.6c0-5.1,1.9-9.4,5.4-13c6.1-6.1,12.3-12.3,18.4-18.4c4-4,8.1-8.1,12.1-12.1
		c0.3-0.3,0.7-0.5,0.6-1c-0.1-0.8-2-1.9-2.8-1.8c-1.6,0.3-3.2,0.6-4.9,0.7C20.8,56.9,12.8,53.3,7,45.4c-6.6-9.2-7.1-19.1-2.2-29.2
		c1.2-2.5,3.9-2.9,5.9-1c0.3,0.3,0.6,0.5,0.8,0.8c3.8,3.8,7.5,7.5,11.3,11.3c2,2,3.9,2.2,5.4,0.7c1.5-1.5,1.2-3.4-0.7-5.3
		c-4-4-8-8-11.9-12c-2.1-2.1-1.7-4.8,0.9-6.2C27.5-1.3,41.5,1,49.7,10.3c6.3,7,8.5,15.3,6.7,24.5c-0.2,0.9-0.1,1.6,0.6,2.2
		c1.7,1.6,3.3,3.2,4.9,4.9c1.2,1.2,1.2,1.2,2.3,0c5.5-5.5,11-11,16.4-16.4c0.2-0.2,0.4-0.4,0.6-0.6c0.4-0.4,0.4-0.7,0-1.1
		c-0.6-0.6-1.2-1.1-1.7-1.7c-1.7-1.9-1.5-4.3,0.7-5.7c0.3-0.2,0.6-0.4,0.9-0.5c7.6-4.5,15.1-9.1,22.7-13.6c2.4-1.4,3.8-1.3,5.7,0.7
		c2.9,2.9,5.7,5.7,8.6,8.6c0.6,0.6,1.1,1.3,1.4,2.1c0,0.7,0,1.4,0,2.1c-0.3,0.5-0.6,1.1-0.9,1.6c-4.5,7.5-9,15-13.5,22.5
		c-0.3,0.5-0.5,0.9-0.9,1.4c-1.3,1.7-3.5,2-5.2,0.6c-0.6-0.5-1.2-1.1-1.8-1.8c-0.5-0.6-0.9-0.6-1.5,0c-5.7,5.7-11.4,11.5-17.2,17.2
		c-0.6,0.6-0.6,1,0,1.6c1.8,1.8,3.6,3.5,5.4,5.4c0.6,0.6,1.1,0.8,1.9,0.6c3-0.7,6-0.9,9-0.5c9.5,1.2,16.6,6,21.3,14.3
		C118.1,81.5,119,84.8,119.6,88.2z M49.8,29.1c0-3.5-0.5-5.8-2-9.1C44,11.9,34.3,7,25.6,8.9c-0.3,0.1-0.8,0-0.9,0.4
		c-0.1,0.4,0.3,0.6,0.5,0.8c2.5,2.6,5.1,5.1,7.6,7.6c2.1,2.1,3.5,4.6,3.5,7.7C36.5,31.4,31.9,36,26,36.1c-3.2,0-5.8-1.3-8-3.5
		c-2.5-2.5-5.1-5.1-7.7-7.6c-0.2-0.2-0.4-0.7-0.8-0.5c-0.3,0.1-0.3,0.6-0.4,0.9c-0.3,1.9-0.4,3.7-0.3,5.6
		c0.6,7.9,6.3,15.2,13.9,17.5c4.3,1.3,8.6,1.4,12.9,0.1c1.8-0.6,3.4-0.3,4.7,1.1c10.3,10.3,20.6,20.7,31,31c1.3,1.3,1.7,2.8,1.2,4.6
		c-0.5,1.5-0.8,3.1-1,4.7c-0.7,9.8,5,18.4,14.4,21.4c3.2,1,6.5,1.2,9.9,0.5c0.3-0.1,0.7-0.1,0.8-0.3c0.2-0.4-0.3-0.6-0.5-0.8
		c-2.4-2.5-4.9-4.9-7.3-7.3c-0.6-0.6-1.2-1.2-1.7-1.9c-4.4-5.4-1.9-14.1,4.8-16.2c4.3-1.4,8.1-0.3,11.3,2.8c2.6,2.6,5.2,5.2,7.8,7.8
		c0.2,0.2,0.4,0.6,0.7,0.5c0.4-0.1,0.4-0.5,0.4-0.9c0.3-1.9,0.5-3.8,0.3-5.7c-0.5-5.2-2.6-9.5-6.4-13c-5.8-5.2-12.5-6.8-20-4.6
		c-2.3,0.6-3.9,0.2-5.6-1.5C70.4,60.5,60.3,50.4,50.1,40.3c-1.5-1.5-2-3-1.3-5C49.5,33.3,49.9,31.2,49.8,29.1z M18.1,112.1
		c4.1,0,6.7-1.4,8.9-3.6C37,98.5,47,88.4,57.1,78.4c0.8-0.8,0.7-1.2,0-1.9c-4.2-4.2-8.4-8.4-12.6-12.6c-0.8-0.8-1.2-0.7-1.9,0
		c-9.9,9.9-19.8,19.9-29.7,29.8c-0.6,0.6-1.2,1.3-1.8,2c-2.5,3.2-3,6.8-1.3,10.5C11.5,110.1,14.8,112,18.1,112.1z M100.5,33
		c0.5,0,0.6-0.4,0.8-0.6c3.3-5.4,6.5-10.8,9.8-16.2c0.4-0.7,0.3-1.1-0.2-1.6c-1.5-1.4-2.9-2.8-4.3-4.3c-0.6-0.6-1-0.6-1.7-0.2
		c-5.4,3.2-10.8,6.5-16.1,9.7c-0.7,0.4-0.8,0.8-0.2,1.4c3.8,3.8,7.6,7.6,11.5,11.4C100.1,32.7,100.4,32.8,100.5,33z M91.3,34.1
		c-0.1-0.1-0.2-0.3-0.3-0.4c-1.2-1.2-2.4-2.4-3.6-3.6c-0.5-0.5-0.9-0.3-1.3,0.1c-5.7,5.8-11.5,11.5-17.2,17.2
		c-0.5,0.5-0.6,0.9,0,1.4c1.1,1.1,2.2,2.1,3.3,3.3c0.5,0.5,0.9,0.6,1.4,0c5.7-5.8,11.5-11.5,17.2-17.3C91,34.7,91.3,34.5,91.3,34.1z
		"/>
      <path d="M19.6,98.2c0-0.8,0.5-1.6,1.2-2.3c6.9-6.9,13.8-13.8,20.7-20.7c1.6-1.6,3.8-1.6,5.2-0.2
		c1.5,1.5,1.4,3.6-0.2,5.2c-6.9,6.9-13.8,13.8-20.7,20.7c-1.2,1.2-2.6,1.5-4.1,0.9C20.3,101.3,19.5,99.9,19.6,98.2z"/>
    </SvgIcon>
  )
}
