import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";
export default makeStyles((theme: Theme) =>
  createStyles({
    modalPaper: {
      overflow: "initial !important",
    },
    btnClose: {
      transition: "all .4s ease !important",
      "& svg": {
        fontSize: 28,
      },
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main} !important`,
        transform: "scale(1.1)",
      },
    },
  })
);