import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import * as React from "react";

import { Box, Container, Grid, Hidden, Typography } from "@mui/material";

import Slider from "react-slick";
import useStyles from "./styles";

export default function ServicesUs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleClick = (n: number) => {
    setValue(n);
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  return (
    <Box className={classes.servicesUs}>
      <Container>
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                color: "primary.main",
                textAlign: "center",
                marginBottom: "4rem",
              }}>
              Nos preocupamos por ofrecerte
              <br /> servicios integrales en construcción
            </Typography>
            <Hidden mdDown>
              <Box sx={{ display: "flex" }}>
                <Box
                  onMouseEnter={() => handleClick(0)}
                  sx={{ bgcolor: "secondary.dark", m: 1 }}
                  className={`${classes.letterTitleProject} ${
                    value === 0 ? "active" : ""
                  }`}>
                  <Box sx={{ width: "100%" }} className={classes.textProject}>
                    <Typography
                      variant="body1"
                      sx={{ color: "primary.contrastText", fontSize: 17 }}>
                      Ejecutamos proyectos de construcción inmobiliarios, obras
                      industriales, retail, edificaciones comerciales, almacenes
                      e infraestructura educativa.
                    </Typography>
                  </Box>
                  <Box
                    sx={{ color: "primary.contrastText" }}
                    className={classes.title}>
                    C<span>onstruction</span>
                  </Box>
                </Box>
                <Box
                  onMouseEnter={() => handleClick(1)}
                  sx={{ bgcolor: "secondary.dark", m: 1 }}
                  className={`${classes.letterTitleProject} ${
                    value === 1 ? "active" : ""
                  }`}>
                  <Box sx={{ width: "100%" }} className={classes.textProject}>
                    <Typography
                      variant="body1"
                      sx={{ color: "primary.contrastText", fontSize: 17 }}>
                      Creamos espacios ideales para el desarrollo de las
                      actividades de nuestros clientes.
                    </Typography>
                  </Box>
                  <Box
                    sx={{ color: "primary.contrastText" }}
                    className={classes.title}>
                    Ar<span>chitecture</span>
                  </Box>
                </Box>
                <Box
                  onMouseEnter={() => handleClick(2)}
                  sx={{ bgcolor: "secondary.dark", m: 1 }}
                  className={`${classes.letterTitleProject} ${
                    value === 2 ? "active" : ""
                  }`}>
                  <Box sx={{ width: "100%" }} className={classes.textProject}>
                    <Typography
                      variant="body1"
                      sx={{ color: "primary.contrastText", fontSize: 17 }}>
                      Gestionamos proyectos asegurando altos estándares de
                      calidad, seguridad y cuidado del medio ambiente aplicando
                      la filosofía Lean Construction, las buenas prácticas del
                      PMI y VDC-BIM.
                    </Typography>
                  </Box>
                  <Box
                    sx={{ color: "primary.contrastText" }}
                    className={classes.title}>
                    M<span>anagement</span>
                  </Box>
                </Box>
                <Box
                  onMouseEnter={() => handleClick(3)}
                  sx={{ bgcolor: "secondary.dark", m: 1 }}
                  className={`${classes.letterTitleProject} ${
                    value === 3 ? "active" : ""
                  }`}>
                  <Box sx={{ width: "100%" }} className={classes.textProject}>
                    <Typography
                      variant="body1"
                      sx={{ color: "primary.contrastText", fontSize: 17 }}>
                      Gestionamos la ingeniería de proyectos utilizando BIM como
                      metodología bajo entornos de colaboración y tecnologías
                      avanzadas.
                    </Typography>
                  </Box>
                  <Box
                    sx={{ color: "primary.contrastText" }}
                    className={classes.title}>
                    EN<span>gineering</span>
                  </Box>
                </Box>
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box>
                <Slider {...settings}>
                  <Box
                    sx={{ bgcolor: "secondary.dark", m: 1 }}
                    className={`${classes.letterTitleProject} active`}>
                    <Box sx={{ width: "100%" }} className={classes.textProject}>
                      <Typography
                        variant="body1"
                        sx={{ color: "primary.contrastText", fontSize: 17 }}>
                        Ejecutamos proyectos de construcción inmobiliarios,
                        obras industriales, retail, edificaciones comerciales,
                        almacenes e infraestructura educativa.
                      </Typography>
                    </Box>
                    <Box
                      sx={{ color: "primary.contrastText" }}
                      className={classes.title}>
                      C<span>onstruction</span>
                    </Box>
                  </Box>
                  <Box
                    sx={{ bgcolor: "secondary.dark", m: 1 }}
                    className={`${classes.letterTitleProject} active`}>
                    <Box sx={{ width: "100%" }} className={classes.textProject}>
                      <Typography
                        variant="body1"
                        sx={{ color: "primary.contrastText", fontSize: 17 }}>
                        Creamos espacios ideales para el desarrollo de las actividades de nuestros clientes. 
                      </Typography>
                    </Box>
                    <Box
                      sx={{ color: "primary.contrastText" }}
                      className={classes.title}>
                      Ar<span>chitecture</span>
                    </Box>
                  </Box>
                  <Box
                    sx={{ bgcolor: "secondary.dark", m: 1 }}
                    className={`${classes.letterTitleProject} active`}>
                    <Box sx={{ width: "100%" }} className={classes.textProject}>
                      <Typography
                        variant="body1"
                        sx={{ color: "primary.contrastText", fontSize: 17 }}>
                        Gestionamos proyectos asegurando altos estándares de
                        calidad, seguridad y cuidado del medio ambiente
                        aplicando la filosofía Lean Construction, las buenas
                        prácticas del PMI y VDC-BIM.
                      </Typography>
                    </Box>
                    <Box
                      sx={{ color: "primary.contrastText" }}
                      className={classes.title}>
                      M<span>anagement</span>
                    </Box>
                  </Box>

                  <Box
                    sx={{ bgcolor: "secondary.dark", m: 1 }}
                    className={`${classes.letterTitleProject} active`}>
                    <Box sx={{ width: "100%" }} className={classes.textProject}>
                      <Typography
                        variant="body1"
                        sx={{ color: "primary.contrastText", fontSize: 17 }}>
                        Gestionamos la ingeniería de proyectos utilizando BIM como metodología bajo entornos de colaboración y tecnologías avanzadas.
                      </Typography>
                    </Box>
                    <Box
                      sx={{ color: "primary.contrastText" }}
                      className={classes.title}>
                      EN<span>gineering</span>
                    </Box>
                  </Box>
                </Slider>
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
