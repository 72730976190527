import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    building: {
      padding: "7rem 0 0",
      [theme.breakpoints.down("sm")]: {
        padding: "4.5rem 0 0",
      },
    },
    letter: {
      width: "100%",
      fontFamily: ["'OptimalRegular'", "'sans-serif'"].join(","),
      fontSize: 92,
      lineHeight: .8,
      paddingTop: 7,
      paddingLeft: 50,
      paddingRight: 50,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: 48,
      },
    },
    contTitleProject: {
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
    titleProject: {
      alignItems: "flex-end !important",
      borderRight: ".1rem solid rgba(40 40 40 / .20) !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: 65,
        minHeight: "auto !important",
        minWidth: "auto !important",
      },
      "&:last-child": {
        borderRight: "none !important",
      },
      "&.Mui-selected": {
        "& $letter": {
          fontSize: 122,
          [theme.breakpoints.down("sm")]: {
            fontSize: 60,
          },
        },
      },
    },
    textBuilding: {
      padding: "6rem 0",
      borderBottom: ".1rem solid rgba(40 40 40 / .20)",
      [theme.breakpoints.down("sm")]: {
        padding: "3rem 0 4rem",
      },
      "& h4": {
        marginBottom: "1rem",
      },
    },

  })
);