import React from "react";

import { SvgIcon } from "@mui/material";

export default function LinkedinIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 116 30.73">
      <path d="M0.7,26.6h12.7v-4.2H5.4v-16H0.7V26.6z M19.9,26.6V12.7h-4.6v13.9H19.9z M17.6,10.8c1.6,0,2.6-1.1,2.6-2.4
	c0-1.4-1-2.4-2.6-2.4c-1.6,0-2.6,1-2.6,2.4C15.1,9.8,16.1,10.8,17.6,10.8L17.6,10.8L17.6,10.8z M22.1,26.6h4.6v-7.7
	c0-0.4,0-0.8,0.2-1.1c0.3-0.8,1.1-1.7,2.4-1.7c1.7,0,2.3,1.3,2.3,3.1v7.4h4.6v-8c0-4.3-2.3-6.2-5.3-6.2c-2.5,0-3.6,1.4-4.2,2.3h0v-2
	h-4.6C22.1,14,22.1,26.6,22.1,26.6z M42.7,6.5h-4.6v20.1h4.6v-4.5l1.2-1.5l3.6,5.9h5.7L47,18l5.3-5.9h-5.6c0,0-3.8,5.2-4.1,5.9V6.5z
	"/>
      <path d="M65.4,20.8c0.1-0.4,0.2-1,0.2-1.8c0-3.6-1.8-7.2-6.6-7.2c-5.1,0-7.5,4.1-7.5,7.7c0,4.5,2.9,7.4,7.9,7.4
	c2,0,3.9-0.3,5.4-0.9l-0.6-3c-1.2,0.4-2.5,0.6-4.1,0.6c-2.2,0-4-0.9-4.2-2.8L65.4,20.8L65.4,20.8L65.4,20.8z M55.8,17.7
	c0.1-1.2,0.9-2.9,2.9-2.9c2.1,0,2.6,1.9,2.6,2.9H55.8L55.8,17.7z M77,6.5v7h-0.1c-0.7-1-2.1-1.6-3.9-1.6c-3.6,0-6.7,2.8-6.6,7.7
	c0,4.5,2.8,7.4,6.3,7.4c1.9,0,3.7-0.8,4.6-2.4h0.1l0.2,2.1h4.1c-0.1-1-0.1-2.7-0.1-4.3V6.5L77,6.5L77,6.5z M77,20.2
	c0,0.4,0,0.7-0.1,1c-0.3,1.3-1.4,2.2-2.7,2.2c-1.9,0-3.2-1.5-3.2-4c0-2.3,1.1-4.1,3.2-4.1c1.4,0,2.4,1,2.7,2.2
	C77,17.7,77,18,77,18.3L77,20.2L77,20.2z"/>
      <path d="M114.5,0.9h-26c-1.2,0-2.3,1-2.3,2.2v26.3c0,1.2,1,2.2,2.3,2.2h26c1.2,0,2.3-1,2.3-2.2V3.1
	C116.7,1.8,115.7,0.9,114.5,0.9z M90.9,26.6V12.7h4.6v13.9H90.9z M93.2,10.8L93.2,10.8L93.2,10.8c-1.6,0-2.6-1.1-2.6-2.4
	c0-1.4,1-2.4,2.6-2.4c1.6,0,2.5,1,2.6,2.4C95.8,9.7,94.8,10.8,93.2,10.8z M112.1,26.6h-4.6v-7.4c0-1.9-0.7-3.1-2.3-3.1
	c-1.3,0-2,0.9-2.4,1.7c-0.1,0.3-0.2,0.7-0.2,1.1v7.7H98v0v0l0,0c0,0,0.1-12.6,0-13.9h4.6v2c0.6-0.9,1.7-2.3,4.2-2.3
	c3,0,5.3,2,5.3,6.2V26.6z"/>
    </SvgIcon>
  )
}
