import React from "react";

import { SvgIcon } from "@mui/material";

export default function FacebookIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 73.3 60">
      <path d="M73.3,30.7c-0.5,1.1-1.4,1.5-2.7,1.5c-1.5-0.1-3.1,0-4.6,0c-0.5,0-0.8,0.1-1.1,0.5
		C56.1,41.5,47.4,50.2,38.6,59c-0.4,0.4-0.8,0.8-1.5,0.9c-0.9,0.1-1.6-0.2-2.2-0.8c-7-7-14-14-21-21c-1.8-1.8-3.6-3.6-5.5-5.5
		c-0.3-0.3-0.6-0.5-1.1-0.4c-1.7,0-3.3,0-5,0C0.9,32.2,0,31.3,0,30c0-1.3,1-2.2,2.3-2.2c0.7,0,1.4,0,2.1,0c0.5,0,0.5-0.1,0.3-0.5
		C3.5,24.7,2.9,21.9,3,19c0.1-6,2.5-10.9,7.1-14.7c7.7-6.2,18.6-5.6,25.7,1.3c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.5,0.7,0.4,1.1,0
		c2.7-2.7,5.9-4.6,9.6-5.4c10.9-2.4,21.6,4.8,23.2,15.8c0.6,3.8,0.1,7.4-1.5,10.9c-0.2,0.5-0.2,0.7,0.4,0.6c0.5,0,1.1,0,1.6,0
		c1.2-0.1,2.1,0.4,2.6,1.5C73.3,29.8,73.3,30.3,73.3,30.7z M57.6,27.8c1.6,0,3.1,0,4.7,0c0.7,0,1-0.2,1.4-0.8
		c1.9-3.2,2.6-6.6,2-10.3C64,6.4,52,1.1,43.1,6.8c-1.6,1.1-2.9,2.5-4.3,3.8c-1.7,1.7-2.7,1.7-4.4,0c-0.7-0.7-1.4-1.5-2.2-2.2
		c-2.8-2.6-6.1-3.9-9.8-4C18,4.4,14.3,6.1,11.2,9.4c-4.4,4.8-5,12.5-1.5,17.9c0.2,0.4,0.5,0.6,1,0.6c2.2,0,4.4,0,6.6,0
		c0.4,0,0.7-0.1,0.9-0.6c0.2-0.6,0.5-1.3,0.8-1.9c1.4-3.4,2.8-6.8,4.3-10.1c0.4-0.9,0.9-1.6,2-1.7c1.1-0.1,1.8,0.5,2.3,1.5
		c2.8,5.6,5.6,11.1,8.5,16.7c0.1,0.2,0.1,0.5,0.4,0.6c0.3,0,0.3-0.3,0.5-0.6c2.1-3.7,4.2-7.3,6.3-11c1.1-2,3-2,4.3-0.1
		c1.4,2.2,2.8,4.4,4.2,6.6c0.3,0.5,0.6,0.6,1.2,0.6C54.4,27.8,56,27.8,57.6,27.8z M25.7,21.2c-0.3,0.2-0.3,0.5-0.4,0.8
		c-1.2,2.9-2.4,5.7-3.6,8.6c-0.5,1.1-1.2,1.7-2.5,1.7c-1.4,0-2.9,0-4.3,0c-0.2,0-0.4-0.1-0.5,0.1c0.1,0.1,0.2,0.2,0.2,0.3
		C21.8,39.8,29,47,36.2,54.2c0.5,0.5,0.7,0.3,1.1-0.1c7.1-7.1,14.2-14.2,21.2-21.2c0.2-0.2,0.4-0.3,0.4-0.5
		c-0.2-0.2-0.5-0.1-0.7-0.1c-2.4,0-4.7,0-7.1,0c-1.1,0-1.8-0.4-2.4-1.3c-0.9-1.5-1.9-3-2.9-4.5c-0.4-0.6-0.4-0.6-0.8,0
		c-2.2,3.8-4.3,7.6-6.5,11.4c-0.5,0.9-1.1,1.6-2.3,1.6c-1.1,0-1.7-0.7-2.1-1.6c-2.7-5.3-5.4-10.6-8.1-15.9
		C26,21.7,26,21.4,25.7,21.2z"/>
    </SvgIcon >
  )
}
