import * as React from "react";

import BannerModelThree from "Components/BannerModelThree";
import CarouselServices from "Components/CarouselServices";
import Liabilities from "Components/Liabilities";
import bannerServices from "./../../Assets/images/banner_services.jpg";

const TitleBanner = () => {

  return (
    <div>
      Nuestra experiencia<br /> en construcción<br /> nos respalda
    </div>
  );
};
export default function ConstructionPage() {

  return (
    <>
      <BannerModelThree image={bannerServices} title={<TitleBanner />} />
      <Liabilities />
      <CarouselServices />
    </>
  );
}
