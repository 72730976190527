import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import * as React from "react";

import { Box, Dialog, DialogContent, IconButton, Skeleton, Typography } from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { Img } from "react-image";
import { LogoIcon } from "Components/CustomIcons";
import Slider from "react-slick";
import useStyles from "./styles";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
interface ILoginModalProps {
  openModal: boolean;
  cont: any;
  closeModal(): void;
}


const loadImage = (imageName: string) => (require(`./../../Assets/images/${imageName}`).default);

export default function PortafolioModal({ openModal, closeModal, cont }: ILoginModalProps) {
  console.log(cont);
  
  const classes = useStyles();
  const settings = {
    className: classes.carrouselGallery,
    infinite: cont.gallery ? cont.gallery.length > 1 ? true : false : true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 500,
    pauseOnHover: true,
    cssEase: "linear",
    arrows: false,
    prevArrow: <div><IconButton aria-label="arrow-left" sx={{ padding: 0 }} color="secondary"><ChevronLeftIcon /></IconButton></div>,
    nextArrow: <div><IconButton aria-label="arrow-right" sx={{ padding: 0 }} color="secondary"><ChevronRightIcon /></IconButton></div>,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {cont && (
        <Dialog
          open={openModal}
          keepMounted
          onClose={closeModal}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogContent>
            <IconButton
              aria-label="close"
              onClick={closeModal}
              sx={{
                position: "absolute",
                right: -19,
                top: -19,
                color: (theme) => theme.palette.primary.contrastText,
                background: (theme) => theme.palette.secondary.main,
                borderRadius: 0,
                padding: ".3rem",
              }}
              className={classes.btnClose}
            >
              <CloseIcon />
            </IconButton>
            <Slider {...settings}>
              {cont.gallery.map((picture: any) => {
                return (
                  <Box className={classes.boxImage}>
                    <Img src={loadImage(picture.image)} loader={<Box sx={{ "height": 320, "width": "100%" }}>
                      <Skeleton variant="rectangular" animation="wave" height={320} width="100%" />
                    </Box>} />
                  </Box>
                )
              })}
            </Slider>
            <Box sx={{ padding: "6px 6px 20px" }}>
              <Box sx={{ width: "100%", display: "flex", alignItems: "center", marginBottom: .4 }}>
                <LogoIcon sx={{ color: "secondary.main", fontSize: "1.9rem", marginRight: 1 }} />
                <Typography variant="h6" component="h6" sx={{ textAlign: "left", color: "primary" }}>{cont.title}</Typography>
              </Box>
              <Box sx={{ width: "100%", paddingLeft: 4.7 }}>
                <Typography variant="body1" sx={{ color: "primary" }}>
                  {cont.desc !== "" ? cont.desc : cont.text}
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
