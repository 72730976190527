import Sig from "Components/Sig";
import * as React from "react";

export default function SigPage() {
  return (
    <>
      <Sig />
    </>
  );
}
