import React from "react";

import { SvgIcon } from "@mui/material";

export default function HandsTwoIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 101.3 89.1">

      <path d="M101.3,50.3c-0.6,1.7-1.7,2.8-3.2,3.6c-1.4,0.7-2.8,1.5-4.2,2.3c-2.9,1.6-5.6,0.9-7.3-1.9
		c-0.4-0.7-0.8-0.7-1.3-0.3c-0.3,0.2-0.7,0.4-1,0.7c-1.6,1.1-1.6,1.2-0.8,3c1.8,3.7-0.1,8.1-4.1,9.3c-0.8,0.2-1.1,0.5-1.1,1.4
		c-0.2,2.8-2.1,5.2-4.9,6c-0.5,0.1-1,0.2-1,0.9c-0.3,4.4-3.5,7.1-7.9,6.5c-0.7-0.1-1,0.1-1.2,0.7c-1.6,4.2-6.6,5.7-10.3,3.1
		c-0.1-0.1-0.2-0.1-0.2-0.2c-0.8-0.5-1.4-1.6-2.3-1.6c-0.8,0.1-1.4,1-2.2,1.6c-0.8,0.7-1.7,1.3-2.5,2c-2.2,1.8-4.7,2.3-7.3,1.2
		c-2.6-1.1-3.8-3.2-4-6c0-0.6-0.3-0.8-0.8-1c-3.3-1.1-4.8-3.1-5-6.4c0-0.5-0.2-0.7-0.6-0.8c-3.4-1-5-3-5.2-6.5
		c0-0.6-0.4-0.7-0.8-0.9c-3.4-1-5.2-3.8-4.9-7.3c0.1-1.1,0.5-2.1,1.1-2.9c0.3-0.5,0.3-0.8-0.1-1.2c-0.7-0.7-1.5-1.3-2.4-1.8
		c-0.5-0.3-0.7-0.1-0.9,0.3c-2.1,3.1-4.5,3.7-7.8,1.8c-1.4-0.8-2.9-1.6-4.3-2.4c-2.7-1.5-3.6-4.4-2.1-7.1c5-9,10.1-17.9,15.2-26.8
		c1.4-2.5,4.2-3.3,6.7-2c1.8,0.9,3.5,1.9,5.3,2.9c2.2,1.3,3.1,3.3,2.5,5.8c-0.1,0.6,0,0.9,0.6,1c0.4,0.1,0.8,0.2,1.1,0.3
		c1.6,0.6,3,0.8,4.7-0.4c2.2-1.5,4.9-1.5,7.5-1.7c2.7-0.2,5.4-0.5,8.1-0.7c1.6-0.2,3.2,0.1,4.8,0.6c2.5,0.9,5,1.7,7.5,2.7
		c0.9,0.3,1.8,0.4,2.8,0.1c1-0.3,2-0.6,3-0.9c0.5-0.1,0.6-0.4,0.5-0.9c-0.6-2.7,0.3-4.7,2.6-6c1.6-0.9,3.3-1.8,4.9-2.7
		c2.7-1.4,5.4-0.6,7,2c1,1.7,1.9,3.4,2.9,5.1c0.7,1.3,0.4,2.5-0.6,3.1c-1.1,0.6-2.2,0.2-3-1c-0.9-1.6-1.8-3.2-2.7-4.7
		c-0.6-1-1-1.1-1.9-0.5c-1.4,0.8-2.8,1.6-4.2,2.3c-1.1,0.6-1.2,1-0.6,2.1c4,7.1,8,14.2,12,21.2c0.9,1.6,1.8,3.2,2.7,4.8
		c0.4,0.8,1,0.9,1.7,0.5c1.5-0.9,3-1.7,4.6-2.5c0.8-0.4,0.9-1,0.5-1.8c-1.1-1.9-2.1-3.7-3.1-5.6c-0.7-1.4-0.1-2.8,1.3-3.1
		c0.9-0.2,1.7,0.1,2.3,1.1c1.3,2.3,2.8,4.6,3.9,7.1C101.3,48.6,101.3,49.4,101.3,50.3z M40.5,52.5c0.5,0.1,1,0.1,1.5,0.2
		c4.2,1.1,6.4,5.5,4.5,9.5c-0.3,0.6-0.3,1,0.3,1.4c2.8,1.9,3.7,5.4,2.2,8.6c-0.3,0.6-0.2,0.9,0.4,1.3c1.9,1.2,2.8,3.1,3,5.3
		c0,0.4,0,0.8,0.4,1.1c1,0.8,1.9,1.6,3,2.3c1.6,1.1,3.5,0.2,3.9-1.6c0.3-1.1-0.2-1.9-1-2.6c-0.8-0.7-1.7-1.3-2.5-2
		c-1.1-0.9-1.3-2.1-0.6-3c0.7-0.9,2-1.1,3.1-0.3c0.3,0.2,0.5,0.4,0.8,0.6c1.6,1.3,3.2,2.6,4.9,3.9c1.6,1.2,3.8,0.3,4.1-1.6
		c0.2-1.1-0.4-2-1.2-2.6c-1.8-1.4-3.6-2.8-5.3-4.3c-0.9-0.7-1.1-1.7-0.7-2.6c0.4-0.8,1.4-1.3,2.3-1.1c0.5,0.1,0.9,0.5,1.4,0.8
		c1.7,1.4,3.4,2.8,5.2,4.1c1,0.8,2.1,0.8,3,0.2c1-0.6,1.2-1.6,1.1-2.7c-0.1-0.8-0.7-1.3-1.3-1.8c-1.6-1.3-3.3-2.6-4.8-3.9
		c-0.9-0.8-1-2-0.3-2.9c0.7-0.9,1.9-1,2.9-0.3c0.2,0.2,0.5,0.4,0.7,0.5c1.5,1.2,3.1,2.5,4.6,3.7c1.6,1.2,3.6,0.4,4-1.5
		c0.3-1.2-0.3-2.1-1.2-2.8c-3.2-2.5-6.4-5.1-9.6-7.6c-4-3.1-8.1-6.3-12.4-9c-2.1-1.3-4.5-1.8-7-1.5c-2.1,0.3-4.2,0.7-6.4,0.7
		c-4.9-0.2-8.4-2.9-9.6-7.6c-0.2-0.6-0.5-0.9-1-1c-1.3-0.4-2.7-0.7-4-1.2c-0.6-0.2-0.9-0.1-1.2,0.5c-3.3,5.9-6.6,11.8-10,17.6
		c-0.4,0.6-0.2,0.9,0.4,1.2c1.2,0.6,2.3,1.4,3.2,2.5c0.5,0.5,0.8,0.6,1.4,0.1c1.5-1.2,3-2.5,4.6-3.6c3.2-2.4,7.5-1.8,9.6,1.5
		C37.7,52.2,38.4,53.4,40.5,52.5z M53.2,28.9c-3.4,0.3-6.9,0.6-10.3,0.9c-1.2,0.1-2.5,0.3-3.7,0.8c-1.4,0.5-1.6,1.1-1.1,2.4
		c0.6,1.7,1.9,2.8,3.7,3.3c1.9,0.5,3.9,0.3,5.8-0.1c4.2-0.8,8.2-0.2,11.9,2.1c2.6,1.6,5,3.5,7.5,5.3c4,2.9,7.8,6,11.7,9.1
		c0.4,0.3,0.7,0.8,1.2,0.2c1-1.1,2.2-2.1,3.6-2.8c0.5-0.2,0.6-0.5,0.3-0.9c-0.3-0.6-0.6-1.1-1-1.7c-3-5.2-5.9-10.5-8.9-15.7
		c-0.4-0.8-0.9-1-1.8-0.7c-1.3,0.4-2.6,0.8-3.9,1.1c-1.7,0.5-3.3,0.4-4.9-0.3c-2.4-0.9-4.8-1.7-7.2-2.5
		C55.2,29.1,54.3,28.9,53.2,28.9z M26.4,25c0-0.5-0.3-0.7-0.7-0.9c-1.4-0.8-2.9-1.6-4.3-2.4c-1.1-0.6-1.5-0.5-2.1,0.6
		c-1.5,2.7-3.1,5.4-4.6,8.2C11.3,36.3,8,42.1,4.7,48c-0.7,1.3-0.7,1.5,0.7,2.2c1.3,0.7,2.6,1.4,3.9,2.1c1.3,0.7,1.5,0.7,2.2-0.6
		c4.9-8.6,9.7-17.2,14.6-25.8C26.2,25.6,26.4,25.3,26.4,25z M27.2,67.7c0,1.2,0.5,1.9,1.4,2.4c1,0.5,2,0.4,2.9-0.3
		c3.5-2.8,7-5.6,10.5-8.4c1.2-1,1.4-2.5,0.5-3.6c-0.9-1.1-2.4-1.4-3.6-0.5c-3.6,2.8-7.2,5.7-10.7,8.6C27.5,66.3,27.2,67,27.2,67.7z
		 M45.5,69.2c0-1-0.5-1.8-1.3-2.2c-1-0.6-2-0.4-2.9,0.3c-2.4,1.9-4.9,3.8-7.2,5.8c-1.3,1-1.4,2.5-0.5,3.7c0.9,1.2,2.4,1.3,3.7,0.3
		c2.4-1.9,4.8-3.9,7.3-5.8C45.2,70.7,45.6,70,45.5,69.2z M33.9,54.9c0-1.4-0.5-2.1-1.4-2.6c-1-0.5-2-0.4-2.9,0.3
		c-2.4,1.9-4.8,3.8-7.2,5.8c-1.2,1-1.4,2.5-0.5,3.7c0.9,1.1,2.4,1.3,3.7,0.3c2.4-1.9,4.8-3.8,7.2-5.7C33.5,56.2,33.9,55.5,33.9,54.9
		z M48.1,79.1c0-1-0.5-1.8-1.3-2.2c-0.9-0.5-1.9-0.4-2.7,0.2c-1.4,1.1-2.8,2.1-4.1,3.2c-1.2,1-1.4,2.5-0.6,3.6
		c0.9,1.2,2.5,1.5,3.8,0.5c1.4-1,2.7-2.2,4.1-3.3C47.8,80.6,48.1,79.9,48.1,79.1z"/>
      <path d="M37.6,18.3c-0.8,0-1.4-0.4-1.9-1c-1.7-2.7-3.4-5.3-5-8c-0.7-1.2-0.5-2.5,0.5-3.1c1.1-0.6,2.2-0.3,3,0.9
		c1.7,2.6,3.4,5.3,5,7.9C40.1,16.6,39.2,18.3,37.6,18.3z"/>
      <path d="M63.9,18.3c-1.7,0-2.6-1.7-1.7-3.2c1.3-2.2,2.7-4.3,4.1-6.5C66.6,8,67,7.4,67.4,6.9c0.7-1.1,1.9-1.3,2.9-0.7
		C71.2,6.8,71.5,8,70.9,9c-1.7,2.8-3.5,5.6-5.2,8.3C65.3,18,64.7,18.3,63.9,18.3z"/>
      <path d="M48.7,7.1c0-1.6,0-3.2,0-4.8c0-1.4,0.8-2.3,2.1-2.3c1.2,0,2,0.9,2,2.3c0,3.2,0,6.4,0,9.6c0,1.4-0.8,2.3-2,2.3
		c-1.3,0-2.1-0.9-2.1-2.3C48.7,10.2,48.7,8.7,48.7,7.1z"/>
      <path d="M91.2,35.9c-1.1,0-2.1-0.9-2.1-2c0-1.1,0.9-2.1,2-2.1c1.1,0,2.1,0.9,2.1,2C93.3,34.8,92.3,35.8,91.2,35.9z" />
    </SvgIcon>
  )
}
