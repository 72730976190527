import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    gridPortafolio: {
      margin: "4.5rem 0",
    },
    contCardPortafolio: {
      height: "25rem",
      overflow: "hidden",
      textDecoration: "none",
    },
    cardPortafolio: {
      position: "relative",
      padding: "2.4rem 8%",
      cursor: "pointer",
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundImage: `linear-gradient(to top, ${theme.palette.primary.dark} 10%, transparent 50%)`,
        zIndex: 1,
        transition: "background-image .4s",
      },
      "&:hover":{
        "& $descriptionCard": {
          maxHeight: "8rem",
          opacity: 1,
          transition: "max-height .4s ease-in, opacity .4s ease",
        },
      },
    },
    titleCard: {
      paddingBottom: 0,
      transition: "all .5s ease",
      marginBottom: ".2rem",
    },
    descriptionCard: {
      width: "100%",
      maxHeight: 0,
      opacity: 0,
      transition: "max-height .3s ease-out, opacity .5s ease",
      "& p": {
        marginBottom: ".4rem",
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
      "& button": {
        marginTop: "1rem",
      },
    },
  })
);