import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    servicesUs: {
      padding: "4.5rem 0 1.8rem",
    },
    letterTitleProject: {
      width: "calc(50% / 3)",
      transition: "width .4s ease-in",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      overflow: "hidden",
      paddingBottom: "3.2rem",
      paddingTop: "3.2rem",
      height: 337,
      [theme.breakpoints.down("md")]: {
        margin: "0 !important",
        height: 322,
      },
      [theme.breakpoints.down("sm")]: {
        height: 332,
      },
      "&.active": {
        width: "50%",
        "& $textProject": {
          opacity: 1,
          paddingLeft: "30%",
          paddingRight: "5%",
          marginLeft: "0%",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: "5%",
          },
        },
        "& $title": {
          "& span": {
            opacity: 1,
          },
        },
      },
      "&:first-child": {
        "& $title": {
          fontSize: 124,
          [theme.breakpoints.down("sm")]: {
            fontSize: 55,
          },
        },
      },
    },
    textProject: {
      opacity: 0,
      transition: "margin-left .5s, opacity .8s",
      marginLeft: "-150%",
      height: 165,
      overflow: "hidden",
      [theme.breakpoints.down("md")]: {
        height: 150,
      },
      [theme.breakpoints.down("sm")]: {
        height: 190,
        "& p": {
          fontSize: 15,
        },
      },
    },
    title: {
      fontSize: 96,
      [theme.breakpoints.down("sm")]: {
        fontSize: 50,
      },
      fontFamily: ["'OptimalRegular'", "'sans-serif'"].join(","),
      textTransform: "uppercase",
      lineHeight: .7,
      "& span": {
        fontSize: 32,
        fontFamily: ["'OptimalLTStdDemiBold'", "'sans-serif'"].join(","),
        opacity: 0,
        letterSpacing: ".07rem",
      },
    },
  })
);