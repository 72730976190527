import * as React from "react";

import BannerModelThree from "Components/BannerModelThree";
import CarouselPortafolio from "Components/CarouselPortafolio";
import SectionGrayContact from "Components/SectionGrayContact";
import imgPortafolioBanner from "./../../Assets/images/portafolio_banner.jpg"

const TitleBanner = () => {

  return (
    <div>
      Proyectos que<br /> trascienden con<br /> el tiempo
    </div>
  );
};
export default function BriefcasePage() {

  return (
    <>
      <BannerModelThree image={imgPortafolioBanner} title={<TitleBanner />} />
      <CarouselPortafolio />
      <SectionGrayContact />
    </>
  );
}
