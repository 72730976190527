import React from "react";

import { SvgIcon } from "@mui/material";

export default function FacebookIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 114.5 87.3">
      <path d="M0,75.2c1-3.3,2.8-5.9,6-7.3c0.6-0.3,0.6-0.8,0.6-1.3c0-3.4,0-6.8,0-10.2C6.7,46,9.9,36.6,15.8,28.2
		c5.9-8.3,13.6-14.3,23.1-18c1-0.4,1.5-0.9,1.7-2c0.8-4.6,4.9-8.1,9.6-8.1c4.7-0.1,9.3-0.1,14,0c4.7,0.1,8.8,3.5,9.6,8.1
		c0.2,1.1,0.7,1.6,1.7,2c13,5.2,22.4,14.2,28.2,27c2.6,5.9,4,12.1,4.1,18.6c0,3.5,0,7.1,0,10.6c0,0.8,0.2,1.3,1,1.6
		c4.3,2.1,6.6,6.7,5.6,11.1c-1.1,4.9-5,8.1-10,8.1c-31.4,0-62.8,0-94.2,0c-4.6,0-8.3-2.8-9.7-7.2c-0.1-0.3-0.2-0.6-0.4-0.9
		C0,77.8,0,76.5,0,75.2z M26.9,25.9c-4.4,4.3-7.6,8.9-9.9,14.2c-2.1,4.9-3.3,9.9-3.5,15.2c-0.1,3.3-0.1,6.6-0.1,9.9
		c0,1.8,0,1.8,1.7,1.8c28,0,56,0,84,0c0.3,0,0.5,0,0.8,0c0.7,0.1,0.9-0.2,0.9-0.9c0-4.4,0.2-8.9-0.3-13.3c-0.9-9-4.5-17-10.3-23.9
		c-0.8-1-1.8-1.9-2.8-3c0,0.5-0.1,0.7-0.1,0.9c0,3.3,0,6.7,0,10c0,1.3-0.5,2.3-1.6,3c-1.1,0.7-2.3,0.7-3.5,0.1
		c-1.2-0.7-1.8-1.7-1.8-3c0-5.1,0-10.3,0-15.4c0-0.8-0.2-1.2-0.9-1.6c-1.5-0.8-3-1.7-4.5-2.4c-0.9-0.4-1.1-0.2-1.1,0.8
		c0,4.9,0,9.8,0,14.6c0,3.6,0,7.1,0,10.7c0,2.1-1.5,3.5-3.6,3.5c-2-0.1-3.3-1.5-3.3-3.7c0-10.8,0-21.5,0-32.3c0-3-1.2-4.2-4.2-4.2
		c-3.8,0-7.7,0-11.5,0c-3,0-4.2,1.2-4.2,4.2c0,3.6,0,7.1,0,10.7c0,7.3,0,14.5,0,21.8c0,2-1.5,3.5-3.4,3.5c-2,0-3.4-1.4-3.4-3.5
		c0-0.4,0-0.8,0-1.2c0-8,0-16.1,0-24.1c0-1.2-0.1-1.2-1.1-0.7c-1.5,0.6-2.9,1.4-4.3,2.2c-0.9,0.5-1.2,1-1.2,2c0.1,5.1,0,10.2,0,15.3
		c0,1.9-1.5,3.3-3.5,3.3c-1.9,0-3.3-1.4-3.4-3.3c0-1,0-2,0-3C26.9,31.4,26.9,28.8,26.9,25.9z M57.2,80.4c15.3,0,30.6,0,45.9,0
		c0.7,0,1.4,0,2.1-0.2c1.6-0.4,2.6-2,2.3-3.6c-0.3-1.9-1.7-2.7-4.1-2.7c-30.8,0-61.6,0-92.3,0c-0.6,0-1.2,0-1.8,0.2
		c-1.4,0.4-2.4,1.7-2.4,3.1c0,1.4,1,2.7,2.4,3c0.7,0.2,1.4,0.2,2.1,0.2C26.6,80.4,41.9,80.4,57.2,80.4z"/>
    </SvgIcon>
  )
}
