import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    contBannerUs: {
      paddingTop: "calc(3.8rem + 3rem)",
    },
    bannerUs: {
      width: "100%",
      height: "calc(120vh - (3.8rem + 2.8rem))",
      position: "relative",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        height: "calc(130vh - (3.8rem + 2.8rem))",
      },
    },
    bannerCaption: {
      position: "relative",
      zIndex: 2,
      paddingTop: "8rem",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "2.5rem",
      },
    },
    textCapion: {
      textAlign: "center",
      paddingBottom: 16,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      "&::after": {
        content: "''",
        position: "absolute",
        bottom: 0,
        width: 94,
        height: ".15rem",
        background: theme.palette.secondary.main,
      },
    },
    logo: {
      width: "9.5rem",
    },
  })
);