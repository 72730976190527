import React from "react";

import { SvgIcon } from "@mui/material";

export default function GroupIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 85.6 85.6">
      <path d="M32.1,85.6c-3.1-0.5-5.9-1.7-8.3-3.9c-2.8-2.5-4.6-5.6-5.1-9.4c-0.1-0.7-0.4-0.9-1.1-0.9c-1.8,0-3.6,0-5.3,0
		c-0.6,0-1,0.2-1.3,0.8c-1,2.2-3.2,3.6-5.4,3.5C3,75.5,1,74,0.3,71.6c-0.9-2.8,0.5-5.9,3.3-7c2.6-1.1,5.9-0.1,7.2,2.5
		c0.6,1.1,1.2,1.4,2.3,1.3c1.6-0.1,3.1,0,4.7,0c0.6,0,0.9-0.2,1-0.8c1.3-7.4,6.7-12.6,14.1-13.3c6.2-0.6,11.1,2,14.6,7.1
		c0.4,0.5,0.6,0.6,1.1,0.2c1.3-1.1,2.7-2.1,4.1-3.1c0.6-0.4,0.8-0.9,0.6-1.7c-1.9-7.1,1.2-14.2,7.5-17.8c0.9-0.5,1.9-0.9,2.9-1.3
		c0.6-0.2,0.6-0.4,0.2-0.8c-1.8-2.4-3.7-4.8-5.5-7.3c-0.4-0.5-0.7-0.3-1-0.1c-6.1,3.2-12.8,2.4-18-2.1c-2.4-2.1-4-4.7-4.8-7.8
		c-0.2-1-0.3-1-1-0.3c-1.7,1.5-3.4,2.9-5.1,4.4c-0.4,0.3-0.6,0.6-0.2,1c1.8,2.4,2.8,5.1,3.1,8c0.1,0.5,0.3,0.9,0.7,1.2
		c2.2,1.7,4.4,3.4,6.5,5.1c0.5,0.4,0.8,0.4,1.4,0.1c3.4-1.9,7.5-0.1,8.4,3.7c0.5,2.2-0.1,4.2-1.8,5.6c-1.8,1.6-3.9,1.9-6.1,0.9
		c-2.1-1-3.3-2.6-3.4-5c0-0.6,0-1.2,0.2-1.8c0.2-0.5,0-0.8-0.4-1.1c-1.7-1.3-3.3-2.5-4.9-3.9c-0.6-0.5-0.8-0.4-0.9,0.3
		c-1.2,4.9-4.2,8.4-8.7,10.6c-5.4,2.6-12.4,1.6-16.9-2.5C1.5,42.6-0.3,38.2,0,32.9C0.5,26,6.3,19.7,13.2,18.7c0.7-0.1,1-0.4,1-1.2
		c-0.1-1.8,0-3.6,0-5.3c0-0.6-0.2-0.9-0.8-1.2c-2.2-1-3.7-3.3-3.5-5.5C10.2,3,11.7,1,14,0.3c2.8-0.9,5.8,0.5,7.1,3.2
		c1.2,2.6,0.1,5.9-2.5,7.2c-1.1,0.5-1.3,1.1-1.3,2.2c0.1,1.6,0,3.1,0,4.7c0,0.7,0.2,1,0.9,1c2.6,0.4,4.9,1.4,7,2.9
		c0.5,0.4,0.8,0.4,1.4,0c2.4-2.1,4.8-4.1,7.2-6.2c0.3-0.3,0.5-0.5,0.5-1c0.5-7.2,6.2-13.3,13.3-14.3C56-1,63.6,4.3,65.4,12.5
		c0.1,0.5,0.3,0.8,0.7,1c3.1,1.5,6.2,3,9.3,4.5c0.5,0.3,0.9,0.2,1.4-0.1c3.2-2,7.2-0.5,8.5,3c0.1,0.2,0.2,0.4,0.3,0.6
		c0,0.8,0,1.7,0,2.5c-0.1,0.1-0.1,0.3-0.2,0.4c-1.2,3.1-4,4.7-7,4c-2.8-0.7-5-3.5-4.2-7c0.1-0.5-0.1-0.7-0.5-0.9
		c-2.4-1.2-4.9-2.3-7.3-3.5c-0.6-0.3-0.8-0.1-0.9,0.5c-0.4,3.6-1.9,6.7-4.5,9.2c-0.5,0.5-0.4,0.8-0.1,1.2c1.8,2.4,3.7,4.8,5.4,7.3
		c0.9,1.3,1.8,2,3.4,1.8c0.1,0,0.3,0,0.4,0c8.6,1.1,14.7,8.8,13.9,17.2c-0.8,7.8-7.4,14.1-15.2,14.2c-5.8,0.1-10.3-2.4-13.6-7.1
		c-0.4-0.6-0.7-0.7-1.3-0.3c-1.4,1.1-2.8,2.1-4.2,3.2c-0.5,0.3-0.6,0.7-0.4,1.3c0.4,1.3,0.6,2.5,0.6,3.9c0,0.7,0.3,1,0.9,1.3
		c2.8,1.3,5.5,2.6,8.3,4c0.9,0.4,1.5,0.5,2.4,0c2.4-1.4,5.4-0.6,7.2,1.6c1.6,2,1.6,5.1-0.1,7.1c-0.8,1-2,1.6-3.2,2.1
		c-0.9,0-1.8,0-2.7,0c-0.1-0.1-0.2-0.1-0.4-0.2c-3.1-1.2-4.5-3.7-3.9-6.9c0.1-0.5-0.2-0.7-0.5-0.9c-2.6-1.2-5.1-2.4-7.7-3.7
		c-0.6-0.3-0.9-0.3-1.1,0.5c-0.9,3.2-2.8,5.8-5.4,7.9c-2.2,1.8-4.7,2.7-7.5,3.3C35,85.6,33.6,85.6,32.1,85.6z M59.7,61.8
		c0.2-0.2,0.1-0.5,0.1-0.8c0.3-2.3,2-4,4.3-4c2.9-0.1,5.8-0.1,8.7,0c2.4,0.1,4.2,1.9,4.3,4.4c0,0.2-0.1,0.4,0.2,0.6
		c3.7-3.2,4.9-9.2,3-14c-1.7-4.2-6.4-8.5-13.3-7.8c-5,0.5-9.6,4.4-10.9,9.6C54.9,54.7,57.1,59.8,59.7,61.8z M24.4,43.5
		c3.5-2.7,5-8.8,3.4-13.4c-1.9-5.2-6.5-8.6-12.1-8.5C9.8,21.7,5,25.5,3.5,30.8C2.1,35.8,4,41,7,43.3c0.2-0.3,0.2-0.6,0.2-0.9
		c0.4-2.4,2.1-3.9,4.5-3.9c2.8,0,5.6-0.1,8.3,0c1.8,0,3.1,0.9,4,2.5C24.3,41.7,24.4,42.5,24.4,43.5z M41.2,24.9
		c0.2-0.1,0.1-0.3,0.1-0.5c0.2-2.6,1.9-4.4,4.5-4.5c2.8-0.1,5.6-0.1,8.3,0c2.6,0.1,4.4,1.9,4.4,4.5c0,0.2-0.1,0.3,0.1,0.5
		c3.1-2.5,5-7.9,3.4-13C60.4,6.6,55.4,3,49.9,3c-5.5,0-10.3,3.5-12.1,8.8C36.2,16.9,38,22.2,41.2,24.9z M42.9,79
		c2.9-2.2,5-7.4,3.6-12.5c-1.5-5.4-6.4-9.2-11.9-9.3c-5.9-0.1-10.8,3.4-12.5,8.9C20.6,71,22.4,76.4,25.5,79c0-0.2,0-0.3,0.1-0.5
		c0.3-2.6,1.6-4.1,4.2-4.4c3.1-0.2,6.3-0.3,9.4,0c1.8,0.2,3.3,1.8,3.6,3.5C42.9,78.1,42.9,78.6,42.9,79z M34.3,82.5
		c1.1,0,2.2-0.1,3.2-0.4c3-0.9,2.3-0.6,2.4-3.4c0-0.1,0-0.1,0-0.2c-0.1-1-0.6-1.5-1.6-1.5c-2.7,0-5.5,0-8.2,0c-1,0-1.4,0.6-1.5,1.6
		c0,0.6,0,1.3,0,1.9c0,0.5,0.2,0.8,0.7,1C30.9,82.2,32.6,82.6,34.3,82.5z M15.8,41.5c-1.4,0-2.7,0-4.1,0c-1,0-1.5,0.6-1.6,1.6
		c0,0.5,0,1.1,0,1.6c-0.1,0.8,0.3,1.2,1,1.5c2.7,1,5.4,1.1,8.2,0.3c2.1-0.6,2.1-0.6,2.1-2.7c0-0.2,0-0.4,0-0.7
		c-0.1-1.1-0.6-1.6-1.7-1.6C18.4,41.5,17.1,41.5,15.8,41.5z M49.9,23C49.9,23,49.9,23,49.9,23c-1.3,0-2.7,0-4,0
		c-1,0-1.5,0.5-1.5,1.6c0,0.6,0,1.1,0,1.7c-0.1,0.8,0.3,1.1,0.9,1.3c2.8,1,5.6,1.1,8.4,0.3c1.8-0.5,1.8-0.5,1.8-2.4
		c0-0.3,0-0.5,0-0.8c0-1.2-0.5-1.7-1.7-1.7C52.5,23,51.2,23,49.9,23z M68.5,60c-1.3,0-2.7,0-4,0c-1.1,0-1.6,0.5-1.6,1.6
		c0,0.5,0,1.1,0,1.6c-0.1,0.7,0.2,1.1,0.9,1.4c2.9,1.1,5.9,1.2,8.9,0.2c1.4-0.5,1.4-0.5,1.4-1.9c0-0.4,0-0.8,0-1.3
		c0-1.1-0.5-1.6-1.6-1.6C71.2,60,69.8,60,68.5,60z M64.2,82.6c1.6,0,2.8-1.1,2.8-2.7c0-1.4-1.3-2.8-2.8-2.8c-1.4,0-2.7,1.3-2.8,2.7
		C61.4,81.3,62.6,82.5,64.2,82.6z M18.4,5.9c0-1.6-1-2.8-2.6-2.8c-1.5,0-2.7,1.1-2.8,2.6c-0.1,1.5,1.2,2.8,2.7,2.8
		C17.2,8.6,18.4,7.4,18.4,5.9z M3.1,69.9c0,1.5,1.1,2.7,2.6,2.8c1.5,0,2.8-1.2,2.8-2.7c0-1.5-1.2-2.7-2.7-2.7
		C4.2,67.2,3.1,68.3,3.1,69.9z M42.8,47c1.5,0,2.8-1.2,2.8-2.7c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.8,1.2-2.8,2.7
		C40,45.7,41.3,46.9,42.8,47z M79.9,25.6c1.5,0,2.8-1.2,2.7-2.8c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.8,1.2-2.8,2.8
		C77.1,24.4,78.4,25.6,79.9,25.6z"/>
      <path d="M68.7,54.3c-3.1,0.2-5.8-2.3-6.1-5.6c-0.2-2.8,2.3-5.7,5.1-5.9c3.4-0.3,6.2,2.1,6.4,5.4
		C74.5,51.3,72.1,54,68.7,54.3z M71.2,48.5c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.8,1.2-2.8,2.7c0,1.5,1.2,2.7,2.7,2.7
		C70,51.3,71.2,50.1,71.2,48.5z"/>
      <path d="M21.5,30c0,3.2-2.6,5.7-5.7,5.8c-3.1,0-5.8-2.6-5.8-5.8c0-3.1,2.6-5.8,5.8-5.8C19,24.2,21.5,26.8,21.5,30z
		 M15.7,27.3c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.8,2.7,2.8c1.5,0,2.8-1.2,2.8-2.7C18.5,28.4,17.3,27.3,15.7,27.3z"/>
      <path d="M55.7,11.5c0,3.1-2.7,5.8-5.8,5.7c-3.2,0-5.9-2.7-5.8-5.8c0.1-3.2,2.7-5.8,5.8-5.7
		C53.2,5.7,55.8,8.4,55.7,11.5z M49.9,8.8c-1.6,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7
		C52.7,9.9,51.5,8.8,49.9,8.8z"/>
      <path d="M40,65.7c0,3.2-2.7,5.8-5.8,5.8c-3.1,0-5.7-2.7-5.7-5.8c0-3.1,2.7-5.8,5.8-5.8C37.4,59.8,40,62.5,40,65.7z
		 M37,65.7c0-1.6-1.1-2.8-2.7-2.8c-1.5,0-2.7,1.2-2.8,2.6c0,1.5,1.1,2.8,2.6,2.8C35.7,68.4,36.9,67.3,37,65.7z"/>
    </SvgIcon>
  )
}
