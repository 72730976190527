import * as React from "react";

import Contact from "Components/Contact";

export default function ContactPage() {
  return (
    <>
      <Contact />
    </>
  );
}
