import React from "react";

import { SvgIcon } from "@mui/material";

export default function FacebookIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 75.1 74.1">
      <path d="M75.1,53c-0.5,1.1-1.5,1.5-2.7,1.5c-7.2,0-14.4,0-21.6,0c-0.3,0-0.6,0-0.9-0.1c-0.6-0.1-1-0.5-1-1.2
		c0-0.6,0.4-1,1-1.1c0.3-0.1,0.6,0,0.9,0c7,0,14,0,21,0c0.9,0,1-0.1,1-1c0-0.6-0.2-0.7-0.8-0.7c-9.5,0-19,0-28.4,0
		c-0.7,0-0.7,0.4-0.7,0.9c0,0.5,0.1,0.8,0.7,0.8c0.7,0,1.3,0,2,0c0.8,0,1.3,0.5,1.3,1.2c0,0.7-0.5,1.1-1.3,1.2c-1,0-2,0-2.9,0
		c-1.1-0.1-1.9-0.8-2.1-1.9c-0.1-0.8-0.1-1.6,0-2.4c0.1-1.3,1-2.1,2.3-2.1c0.6,0,0.9-0.2,0.8-0.8c0-0.5,0-1,0-1.5
		c0-1.1,0.7-1.9,1.8-2c0.3,0,0.6-0.1,0.9-0.1c1.2,0.2,2-0.3,2.9-1.2c2.4-2.5,4.9-5,7.4-7.4c0.4-0.3,0.5-0.7,0.5-1.2
		c0-3.5,0-6.9,0-10.4c0-0.7-0.2-0.9-0.9-0.9c-3.2,0-6.4,0-9.6,0c-0.7,0-0.9,0.2-0.9,0.9c0,1.5,0,3,0,4.5c0,1.7-1.2,2.9-3,2.9
		c-2.8,0-5.6,0-8.4,0c-1.6,0-2.8-1.1-2.9-2.7c-0.2-1.6-0.1-3.2-0.1-4.8c0-0.6-0.2-0.9-0.8-0.9c-1.4,0-2.9,0-4.3,0
		c-0.5,0-0.7,0.2-0.7,0.7c0.2,4.7,0.3,9.4,0.4,14c0.1,1.8,0.1,3.5,0.1,5.3c0.2,4.4,0.2,8.7,0.4,13.1c0.1,3.2,0.3,6.5,0.3,9.7
		c0,0.5,0.2,0.7,0.7,0.7c0.5,0,1.1,0,1.6,0c2.1,0,3.8,1.7,3.9,3.8c0,1,0,2,0,2.9c0,0.8-0.3,1.2-1.2,1.3c-0.2,0-0.4,0-0.7,0
		c-4,0-8,0-11.9,0c-0.3,0-0.5,0-0.8-0.1c-0.7-0.1-1.1-0.7-1-1.3c0.1-0.6,0.5-1,1.2-1c0.9,0,1.8,0,2.7,0c2.8,0,5.7,0,8.5,0
		c0.7,0,1-0.2,0.9-0.9c0-0.2,0-0.3,0-0.5c-0.1-1.3-0.7-2-2-2c-1.9,0-3.8,0-5.6,0c-2.5,0-4.9,0-7.4,0c-1.9,0-2.8,1.3-2.3,3.1
		c0.1,0.3,0.3,0.3,0.6,0.3c0.9,0.1,1.3,0.4,1.4,1.1c0,0.7-0.4,1.2-1.3,1.2c-0.6,0.1-1.3,0.1-1.9,0c-0.6-0.1-1.1-0.5-1.1-1.1
		c0-1.2-0.2-2.5,0.1-3.7c0.4-1.9,1.9-3.2,3.9-3.2c1,0,1,0,1-1c0.1-5.1,0.3-10.2,0.5-15.3c0.1-4.8,0.3-9.6,0.4-14.4
		c0.1-3.9,0.3-7.9,0.4-11.8c0-1,0-1-1-1c-1.7,0-3.4,0-5.1,0c-0.5,0-0.7,0.1-0.8,0.6c-0.4,1.4-1.4,2.1-2.9,2.2c-1.3,0-2.6,0-4,0
		c-1.5,0.1-2.7-0.5-3.4-1.9c0-3.2,0-6.3,0-9.5c0.6-1.4,1.6-2,3.1-2c1.5,0,2.9,0,4.4,0c1.3,0,2.2,0.7,2.6,1.9c0.2,0.5,0.5,0.7,1,0.7
		c1.9,0,3.8,0,5.6,0c0.7,0,0.9-0.2,1-0.9c0.1-1.9,0.3-3.8,0.5-5.7c0.2-2.2,0.4-4.4,0.6-6.6c0.1-0.6,0.4-1,1-1.1
		C21,0,22.4-0.2,23.6,0.4C34,4.5,44.3,8.6,54.7,12.7c2.1,0.8,4.2,2,6.6,1.8c0.5-0.1,0.9,0.3,1.2,0.6c1.3,1.7,2.7,3.5,4,5.2
		c0.4,0.5,0.7,0.9,0.3,1.5c-0.3,0.6-0.8,0.7-1.4,0.7c-1.7,0-3.5,0-5.2,0c-0.7,0-0.8,0.2-0.8,0.8c0,3.5,0,7,0,10.5
		c0,0.6,0.2,1,0.6,1.3c2.4,2.4,4.8,4.7,7.1,7.1c0.8,0.9,1.6,1.5,2.9,1.3c0.2,0,0.5,0,0.7,0.1c1.1,0.2,1.8,0.9,1.8,2
		c0,0.6,0,1.2,0,1.8c0,0.4,0.1,0.5,0.5,0.6c0.9,0.1,1.5,0.7,2,1.5C75.1,50.7,75.1,51.8,75.1,53z M52.2,14.4c0,0,0-0.1,0-0.1
		c-0.3-0.1-0.5-0.2-0.8-0.3c-5.1-2-10.3-4.1-15.4-6.1c-3.7-1.5-7.4-3-11.2-4.4c-0.7-0.3-0.8-0.3-0.8,0.5c0,0.1,0,0.2,0,0.4
		c0.3,3.1,0.6,6.2,0.8,9.3c0,0.6,0.3,0.8,0.8,0.8c2,0,4,0,5.9,0c0.3,0,0.6,0,0.9,0.1c0.7,0.1,1.1,0.6,1.1,1.2c0,0.6-0.5,1.1-1.2,1.1
		c-0.2,0-0.5,0-0.7,0c-6.8,0-13.5,0-20.3,0c-0.2,0-0.4,0-0.7,0c-0.4,0-0.5,0.1-0.5,0.5c0,0.8,0,1.5,0,2.3c0,0.4,0.2,0.5,0.6,0.5
		c0.2,0,0.4,0,0.7,0c16.9,0,33.8,0,50.7,0c0.2,0,0.3,0,0.5,0c0.5,0,0.5-0.2,0.2-0.5c-0.6-0.7-1.1-1.4-1.7-2.1
		c-0.3-0.5-0.7-0.7-1.3-0.7c-7.4,0-14.8,0-22.1,0c-0.2,0-0.5,0-0.7,0c-0.7,0-1.2-0.5-1.2-1.1c0-0.6,0.4-1.1,1.1-1.2
		c0.3-0.1,0.7-0.1,1-0.1c4.5,0,8.9,0,13.4,0C51.7,14.4,51.9,14.4,52.2,14.4z M38.6,22.5c-1.4,0-2.7,0-4.1,0c-0.5,0-0.7,0.2-0.7,0.7
		c0,1.4,0,2.8,0,4.2c0,0.8,0.2,1,1,1c2.6,0,5.2,0,7.8,0c0.6,0,0.9-0.2,0.9-0.9c0-1.5,0-2.9,0-4.4c0-0.6-0.2-0.7-0.8-0.7
		C41.3,22.5,39.9,22.5,38.6,22.5z M58.1,48c3.8,0,7.5,0,11.3,0c0.3,0,0.6,0.1,0.8-0.2c0.3-0.5,0.2-1.2,0.1-1.7
		c-0.1-0.4-0.5-0.2-0.8-0.2c-5,0-10.1,0-15.1,0c-2.4,0-4.9,0-7.3,0c-0.3,0-0.6-0.1-0.7,0.2c-0.2,0.5-0.2,1.1,0,1.7
		c0.1,0.5,0.6,0.3,1,0.3C50.7,48,54.4,48,58.1,48z M7.9,18.5c0-1.2,0-2.3,0-3.5c0-0.5-0.2-0.8-0.7-0.8c-1.4,0-2.7,0-4.1,0
		c-0.5,0-0.7,0.2-0.7,0.7c0,2.4,0,4.8,0,7.2c0,0.5,0.2,0.7,0.8,0.7c1.3,0,2.7,0,4,0c0.6,0,0.8-0.2,0.8-0.8
		C7.9,20.9,7.9,19.7,7.9,18.5z M23.8,48.9c-0.2,0.1-0.2,0.2-0.3,0.2c-1.2,1.5-2.5,3.1-3.7,4.6c-0.3,0.4-0.2,0.6,0.1,0.8
		c1.2,0.8,2.3,1.7,3.5,2.5c0.2,0.1,0.3,0.4,0.6,0.3c0.2-0.1,0.1-0.4,0.1-0.6C24,55,24,53.3,24,51.6C24,50.7,23.9,49.8,23.8,48.9z
		 M21.2,14.4c1.4,0,1.4,0,1.3-1.4c0,0,0,0,0-0.1c-0.3-3.2-0.6-6.4-0.9-9.7c0-0.3,0-0.7-0.3-0.7c-0.5,0-0.3,0.4-0.4,0.7
		c-0.3,3.5-0.6,7.1-1,10.6c0,0.4,0.1,0.6,0.6,0.6C20.8,14.4,21,14.4,21.2,14.4z M18.2,64.8c0.1,0.1,0.2,0.1,0.2,0
		c1.3-1.7,2.7-3.3,4-5c0.2-0.3,0.2-0.5-0.1-0.7c-1.1-0.8-2.3-1.6-3.4-2.4c-0.3-0.2-0.4-0.2-0.4,0.2C18.4,59.6,18.3,62.2,18.2,64.8z
		 M57.1,38C57.1,38,57,38,57,37.9c-1.7,1.7-3.4,3.4-5.2,5.2c-0.3,0.3,0,0.4,0.2,0.4c1.5,0,3,0,4.5,0c0.3,0,0.6-0.1,0.6-0.5
		C57.1,41.3,57.1,39.7,57.1,38z M65.1,43.5c-1.9-1.9-3.6-3.6-5.3-5.3c-0.3-0.3-0.3,0.1-0.3,0.3c0,1.5,0,3,0,4.5
		c0,0.5,0.2,0.6,0.6,0.6c0.7,0,1.3,0,2,0C63,43.5,63.9,43.5,65.1,43.5z M18.7,51.4c1.3-1.7,2.5-3.2,3.8-4.7c0.3-0.3,0.2-0.5-0.1-0.8
		c-0.9-0.7-1.9-1.3-2.8-2c-0.2-0.1-0.3-0.4-0.6-0.2c-0.2,0.1-0.1,0.4-0.1,0.5c0,1.6,0,3.2-0.1,4.7C18.6,49.7,18.7,50.4,18.7,51.4z
		 M23.6,36.4c-0.1,0-0.2,0-0.2-0.1c-1.1,1.5-2.2,3-3.3,4.5c-0.2,0.3,0,0.5,0.2,0.6c0.9,0.6,1.8,1.3,2.7,1.9c0.2,0.2,0.4,0.4,0.7,0.4
		C23.6,41.4,23.6,38.9,23.6,36.4z M19.1,37.8c0,0,0.1,0,0.1,0.1c1-1.3,1.9-2.6,2.9-3.9c0.2-0.2,0.2-0.4-0.1-0.6
		c-0.8-0.5-1.5-1.1-2.3-1.7c-0.4-0.3-0.5-0.1-0.5,0.3c0,0.7-0.1,1.3-0.1,2C19.1,35.2,19.1,36.5,19.1,37.8z M23.1,25.1
		c-1,1.3-1.8,2.4-2.7,3.5c-0.2,0.3-0.1,0.5,0.1,0.7c0.8,0.5,1.5,1.1,2.2,1.6c0.1,0.1,0.3,0.3,0.4,0.1c0.1-0.1,0.1-0.3,0.1-0.4
		C23.3,28.9,23.3,27.1,23.1,25.1z M24.4,61.6c-0.1,0-0.2,0-0.2,0c-1.1,1.3-2.1,2.6-3.2,4c-0.2,0.3-0.2,0.4,0.2,0.4c1,0,1.9,0,2.9,0
		c0.2,0,0.4-0.1,0.4-0.3C24.4,64.3,24.4,62.9,24.4,61.6z M22.1,22.5c-0.8,0-1.4,0-2,0c-0.3,0-0.5,0-0.5,0.4c-0.1,1-0.2,2-0.1,3.2
		C20.4,24.8,21.2,23.7,22.1,22.5z"/>
    </SvgIcon>
  )
}
