import React from "react";

import { SvgIcon } from "@mui/material";

export default function PhoneIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16.59 16.6">
      <path d="M0,4.47A2,2,0,0,1,.45,3.4a21.57,21.57,0,0,1,1.6-1.57,1.12,1.12,0,0,1,1.43,0l.28.25c.64.65,1.32,1.28,1.93,2a1.11,1.11,0,0,1,0,1.57c-.38.45-.82.84-1.24,1.26-.2.21-.21.26-.08.54A8.86,8.86,0,0,0,6,9.65a12.14,12.14,0,0,0,2.42,2.1,8,8,0,0,0,.91.49.28.28,0,0,0,.36-.07c.38-.39.76-.77,1.15-1.15a1.46,1.46,0,0,1,.51-.37,1.13,1.13,0,0,1,1.18.2l.3.27c.59.59,1.18,1.17,1.76,1.76a1.2,1.2,0,0,1,0,1.87c-.42.45-.85.89-1.3,1.31A2,2,0,0,1,12,16.6,6.49,6.49,0,0,1,9.08,16a15.56,15.56,0,0,1-4.15-2.64A16.53,16.53,0,0,1,1,8.22,9.65,9.65,0,0,1,0,5.59c0-.07,0-.14,0-.21Z"/><path d="M8.72,0a11.17,11.17,0,0,1,1.61.41A9.49,9.49,0,0,1,16,5.69a8.73,8.73,0,0,1,.6,2c0,.12,0,.16-.13.18l-1,.17c-.11,0-.15,0-.18-.12,0-.26-.1-.51-.17-.76a8.21,8.21,0,0,0-2.35-3.76,7.93,7.93,0,0,0-4.18-2c-.16,0-.21-.09-.18-.25C8.45.73,8.5.36,8.56,0Z"/><path d="M13.23,7.93c0,.08,0,.1-.1.11l-1.07.19c-.11,0-.13-.05-.15-.14A4.4,4.4,0,0,0,8.4,4.65c-.16,0-.21-.08-.18-.24.06-.32.09-.65.14-1,0-.1.06-.13.16-.11a5.42,5.42,0,0,1,2.46,1,5.69,5.69,0,0,1,2.24,3.5S13.23,7.9,13.23,7.93Z"/>
    </SvgIcon>
  )
}
