import React from "react";

import { SvgIcon } from "@mui/material";

export default function ShieldIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 107 129.1">
      <path d="M54.7,3c2.5,0.6,4.2,2.4,6.1,4.1c7.2,6.4,15.6,10.1,25,11.6c2.7,0.4,5.4,0.5,8.1,0.9c6,0.9,10.6,6.3,10.9,12.4
		c0.3,6.8,0.3,13.6,0.2,20.4c-0.2,10.6-1.5,21-5,31c-5.3,15.2-15.1,26.6-29.1,34.6c-5.1,2.9-10.4,5.2-16,7.1c-1.3,0-2.5,0-3.8,0
		c-6.6-2.3-13-5.1-18.9-8.9C18.5,107.6,9.4,95.4,4.9,79.9c-1.9-6.4-2.8-13-3.4-19.7C0.8,51.6,1.1,43,1.1,34.4c0-2.8,0.3-5.6,1.7-8.1
		c2.4-4.3,6.1-6.5,11-6.9c7.7-0.5,15-2.1,21.9-5.7c4-2.1,7.6-4.6,10.8-7.7c1.4-1.3,2.9-2.4,4.8-3C52.4,3,53.6,3,54.7,3z M97.5,47.3
		c0-1.2,0-2.4,0-3.6c0-3.5,0-7.1-0.1-10.6c-0.1-3.7-1.9-5.7-5.5-6.2c-2.5-0.3-5-0.5-7.6-0.9c-11.1-1.8-20.9-6.3-29.2-14
		c-2.1-2-2.2-2-4.3,0c-4.1,3.8-8.6,6.9-13.7,9.2c-6.9,3.3-14.3,5-21.9,5.5c-4.5,0.3-6.7,2.4-6.7,6.4c0,8.1-0.4,16.1,0.1,24.2
		C9.2,64.7,10.2,72,12.4,79c3.1,9.9,8.3,18.4,16.2,25.3c6.9,6,14.8,10.1,23.3,13.2c0.9,0.3,1.8,0.2,2.7-0.1c3.1-1.2,6.1-2.4,9.1-3.9
		c15.6-7.7,26.1-19.8,30.7-36.7C96.9,67.1,97.5,57.2,97.5,47.3z"/>
      <path d="M83.5,64.1c-0.1,16.8-13.9,30.6-30.6,30.5c-16.8-0.1-30.5-14-30.4-30.7c0.1-16.8,13.9-30.5,30.6-30.4
		C69.8,33.5,83.6,47.4,83.5,64.1z M76,64.1C76.1,51.3,65.8,41,53,41c-12.7,0-23,10.3-23,23c0,12.8,10.2,23.1,23,23.2
		C65.7,87.2,76,76.9,76,64.1z"/>
      <path d="M47.6,77.5c-1.1,0-2-0.5-2.8-1.2c-2-2-4.1-4-6-6.1c-1.6-1.7-1.6-3.8-0.1-5.3c1.5-1.5,3.7-1.5,5.4,0.1
		c0.9,0.9,1.8,1.8,2.7,2.7c0.7,0.7,1.1,0.7,1.8,0c4.2-4.3,8.5-8.5,12.7-12.7c0.9-0.9,1.8-1.5,3.1-1.5c1.5,0,2.5,0.7,3.2,1.9
		c0.7,1.2,0.6,2.5-0.1,3.6c-0.3,0.5-0.7,0.9-1.1,1.4C61.2,65.5,56.1,70.6,51,75.8C50,76.7,49,77.5,47.6,77.5z"/>
    </SvgIcon>
  )
}
