import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { Box, IconButton, Typography } from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LogoIcon } from "Components/CustomIcons";
import Slider from "react-slick";
import calidad from "./../../Assets/images/calidad.jpg";
import experiencia from "./../../Assets/images/experiencia.jpg";
import innovacion from "./../../Assets/images/innovacion.jpg";
import respaldo from "./../../Assets/images/respaldo.jpg";
import seguridad from "./../../Assets/images/seguridad.jpg";
import useStyles from "./styles";

export default function CarouselServices() {
  const classes = useStyles();
  const settings = {
    className: classes.carouselService,
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2200,
    cssEase: "linear",
    arrows: true,
    prevArrow: <div><IconButton aria-label="arrow-left" sx={{ padding: 0}} color="secondary"><ChevronLeftIcon /></IconButton></div>,
    nextArrow: <div><IconButton aria-label="arrow-right" sx={{ padding: 0}} color="secondary"><ChevronRightIcon /></IconButton></div>,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <>
      <Box className={classes.contCarouselService}>
        <Slider {...settings}>
          <Box className={classes.contItemCarousel} >
            <Box sx={{ background: `url(${experiencia})`, backgroundSize: "cover", backgroundPosition: "top center", backgroundRepeat: "no-repeat", height: "100%", width: "100%" }} className={classes.itemCarousel}>
              <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-end", zIndex: 2, position: "relative" }}>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} className={classes.titleDescription}>
                    <LogoIcon sx={{ color: "secondary.main", fontSize: "1.8rem", marginRight: 1 }} />
                    <Typography variant="h6" component="h6" sx={{ textAlign: "left", color: "primary.contrastText" }}>Experiencia<br /> y confiabilidad</Typography>
                  </Box>
                  <Box sx={{ width: "100%", paddingLeft: 4.7 }} className={classes.description}>
                    <Typography variant="body1" sx={{ color: "primary.contrastText", fontSize: 14 }}>
                      Nuestros proyectos tienen el enfoque PMI (Project Management Institute) lo que asegura el alto estándar en el desarrollo de nuestros proyectos.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.contItemCarousel} >
            <Box sx={{ background: `url(${seguridad})`, backgroundSize: "cover", backgroundPosition: "top center", backgroundRepeat: "no-repeat", height: "100%", width: "100%" }} className={classes.itemCarousel}>
              <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-end", zIndex: 2, position: "relative" }}>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} className={classes.titleDescription}>
                    <LogoIcon sx={{ color: "secondary.main", fontSize: "1.8rem", marginRight: 1 }} />
                    <Typography variant="h6" component="h6" sx={{ textAlign: "left", color: "primary.contrastText" }}>Seguridad<br /> garantizada</Typography>
                  </Box>
                  <Box sx={{ width: "100%", paddingLeft: 4.7 }} className={classes.description}>
                    <Typography variant="body1" sx={{ color: "primary.contrastText", fontSize: 14 }}>
                      Nuestra política de seguridad está enfocada en cero accidentes. Nuestro compromiso es con nuestros trabajadores, eliminando peligros y minimizando riesgos, previniendo lesiones y promoviendo el cuidado de la salud. En CARMEN nos aseguramos de que todas nuestras acciones cumplan a cabalidad los estándares de seguridad.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.contItemCarousel} >
            <Box sx={{ background: `url(${respaldo})`, backgroundSize: "cover", backgroundPosition: "top center", backgroundRepeat: "no-repeat", height: "100%", width: "100%" }} className={classes.itemCarousel}>
              <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-end", zIndex: 2, position: "relative" }}>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} className={classes.titleDescription}>
                    <LogoIcon sx={{ color: "secondary.main", fontSize: "1.8rem", marginRight: 1 }} />
                    <Typography variant="h6" component="h6" sx={{ textAlign: "left", color: "primary.contrastText" }}>Respaldo<br /> financiero</Typography>
                  </Box>
                  <Box sx={{ width: "100%", paddingLeft: 4.7 }} className={classes.description}>
                    <Typography variant="body1" sx={{ color: "primary.contrastText", fontSize: 14 }}>
                      Formamos parte del Grupo Inca que cuenta con más de 50 años en el mercado agroindustrial, textil, hotelero, retail, entre otros.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.contItemCarousel} >
            <Box sx={{ background: `url(${innovacion})`, backgroundSize: "cover", backgroundPosition: "top center", backgroundRepeat: "no-repeat", height: "100%", width: "100%" }} className={classes.itemCarousel}>
              <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-end", zIndex: 2, position: "relative" }}>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} className={classes.titleDescription}>
                    <LogoIcon sx={{ color: "secondary.main", fontSize: "1.8rem", marginRight: 1 }} />
                    <Typography variant="h6" component="h6" sx={{ textAlign: "left", color: "primary.contrastText" }}>Innovación</Typography>
                  </Box>
                  <Box sx={{ width: "100%", paddingLeft: 4.7 }} className={classes.description}>
                    <Typography variant="body1" sx={{ color: "primary.contrastText", fontSize: 14 }}>
                      Trabajamos con el enfoque Lean Construction para optimizar procesos constructivos y minimizar desperdicios.
                    </Typography>
                    <Typography variant="body1" sx={{ color: "primary.contrastText", fontSize: 14 }}>
                      Hemos adoptado el BIM (Building Information Modeling) como una nueva forma de pensar.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.contItemCarousel} >
            <Box sx={{ background: `url(${calidad})`, backgroundSize: "cover", backgroundPosition: "top center", backgroundRepeat: "no-repeat", height: "100%", width: "100%" }} className={classes.itemCarousel}>
              <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "flex-end", zIndex: 2, position: "relative" }}>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} className={classes.titleDescription}>
                    <LogoIcon sx={{ color: "secondary.main", fontSize: "1.8rem", marginRight: 1 }} />
                    <Typography variant="h6" component="h6" sx={{ textAlign: "left", color: "primary.contrastText" }}>Calidad</Typography>
                  </Box>
                  <Box sx={{ width: "100%", paddingLeft: 4.7 }} className={classes.description}>
                    <Typography variant="body1" sx={{ color: "primary.contrastText", fontSize: 14 }}>
                      La calidad es la esencia de nuestras actividades. Nuestros clientes son lo más importante del día, por eso toda nuestra gestión y el diseño de nuestros procesos están orientados para satisfacer y superar sus expectativas
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Slider>
      </Box>
    </>
  );
}