import React from "react";

import { SvgIcon } from "@mui/material";

export default function QualityIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 86 86">
      <path d="M85.5,40.6c-0.1,0.4-0.1,0.8-0.1,1.1c0,3.3-0.1,6.6,0,9.9c0,3.3-0.9,6.2-3.3,8.7c-1.2,1.2-2.2,2.5-3.3,3.7
		c-0.4,0.4-0.9,0.6-1.5,0.5c-0.5-0.1-0.8-0.5-0.9-1c-0.2-0.6,0.2-1,0.5-1.4c1-1.2,2-2.4,3.1-3.5c1.8-1.9,2.6-4.1,2.6-6.7
		c0-6.7,0.1-13.5,0.2-20.2c0-0.3,0-0.7,0-1c-0.1-1.3-1-2.2-2.3-2.3c-1.4,0-2.5,0.8-2.6,2.2c-0.3,3.1-0.5,6.2-0.7,9.3
		c-0.2,2.5-0.3,5-0.5,7.4c-0.2,1.7-1,3.1-2.1,4.3c-3.1,3.4-6.2,6.8-9.3,10.1c-1.6,1.7-4.3,1.9-6,0.4c-1.9-1.6-2.1-4.2-0.5-6.1
		c2.4-2.8,4.9-5.4,7.3-8.1c0.4-0.4,0.7-0.8,1-1.2c0.8-0.9,0.7-2,0-2.7c-0.8-0.7-1.9-0.6-2.7,0.2c-0.7,0.7-1.4,1.4-2.2,2.2
		c-3.8,3.9-7.6,7.8-11.4,11.7c-2.6,2.7-3.5,5.9-2.9,9.6c0.9,4.7,1.7,9.3,2.5,14c0.1,0.7,0.4,1,1.2,1c3.9,0,7.8,0,11.7,0
		c0.8,0,1-0.3,1-1.1c0-1.4-0.2-2.9,0.1-4.3c0.2-1.1,0.7-2.1,1.4-2.9c2.4-2.7,4.8-5.4,7.2-8.1c0.2-0.2,0.5-0.5,0.7-0.7
		c0.6-0.3,1.1-0.3,1.6,0.2c0.4,0.4,0.6,0.9,0.3,1.5c-0.1,0.2-0.3,0.5-0.5,0.7c-2.3,2.6-4.6,5.2-6.9,7.8c-0.8,0.9-1.2,1.9-1.2,3.1
		c0.1,1.6,0,3.3,0,4.9c0,1.1-0.5,1.6-1.6,1.6c-5.2,0-10.3,0-15.5,0c-1.2,0-1.6-0.3-1.8-1.5c-0.7-4-1.5-8.1-2.2-12.1
		c-0.3-1.5-0.6-3-0.8-4.6c-0.5-4.3,0.8-8,3.9-11.1c4.5-4.6,9-9.2,13.5-13.8c1.8-1.9,4.6-1.9,6.5-0.1c1.8,1.7,1.9,4.5,0.1,6.4
		c-2.3,2.5-4.6,5-6.8,7.6c-0.4,0.5-0.9,1-1.3,1.5c-0.7,0.8-0.6,1.8,0,2.4c0.7,0.6,1.6,0.6,2.3-0.1c1.5-1.6,3-3.2,4.5-4.8
		c1.5-1.6,2.9-3.3,4.5-4.9c1.4-1.4,1.8-3,2-4.8c0.3-5,0.7-10,1-15c0.2-2.4,2.1-4.4,4.4-4.7c2.5-0.4,4.7,1,5.6,3.4
		c0.1,0.2,0.2,0.4,0.3,0.6C85.5,33.3,85.5,37,85.5,40.6z"/>
	<path d="M19.3,0.5c0.4,0.3,0.6,0.6,0.8,1C21,3.7,22,5.9,22.9,8.1c0.4,0.9,0.2,1.6-0.5,1.9c-0.7,0.4-1.5,0.1-1.9-0.7
		c-1-2.3-2-4.7-3-7c-0.3-0.8-0.1-1.4,0.6-1.9C18.5,0.5,18.9,0.5,19.3,0.5z"/>
	<path d="M67.7,0.5c0.8,0.6,0.9,1.3,0.5,2.1c-0.9,2.2-1.9,4.4-2.8,6.5c-0.4,0.9-1.1,1.2-1.8,0.9c-0.7-0.3-1-1.1-0.6-1.9
		c0.9-2.2,1.9-4.5,2.8-6.7c0.2-0.4,0.4-0.7,0.8-1C67,0.5,67.4,0.5,67.7,0.5z"/>
	<path d="M28.3,85.5c-2.5,0-5.1,0-7.6,0c-1.4,0-1.8-0.4-1.8-1.8c0-1.5,0-3.1,0-4.6c0-1.2-0.4-2.2-1.2-3.1
		c-2.7-3.1-5.5-6.2-8.2-9.2c-2.1-2.3-4.1-4.7-6.2-7c-1.7-2-2.6-4.3-2.7-7c-0.1-7.2-0.1-14.4-0.2-21.5c-0.1-3.1,2.3-5.4,4.8-5.5
		c3-0.1,5.3,2,5.5,5.2c0.3,5.1,0.7,10.1,1,15.2c0.1,1.5,0.6,2.7,1.6,3.9c3,3.2,6,6.5,9,9.8c0.5,0.5,1,1,1.8,0.8
		c1.3-0.3,1.7-1.7,0.7-2.8c-1.5-1.7-3.1-3.4-4.7-5.2c-1.2-1.3-2.4-2.6-3.5-3.9c-1.7-1.9-1.6-4.7,0.2-6.4c1.8-1.7,4.6-1.7,6.4,0.2
		c4.7,4.8,9.5,9.6,14.2,14.5c3.1,3.2,3.8,7.2,3.1,11.6c-0.9,5.1-1.8,10.1-2.8,15.2c-0.3,1.7-0.6,1.9-2.2,1.9
		C33.3,85.5,30.8,85.5,28.3,85.5z M28.5,82.8c2,0,3.9,0,5.9,0c0.7,0,1-0.2,1.2-0.9c0.7-3.9,1.4-7.8,2.1-11.7
		c0.3-1.6,0.7-3.2,0.6-4.8c-0.1-3-1.4-5.4-3.5-7.5c-4.1-4.1-8.1-8.3-12.1-12.4c-0.4-0.5-0.9-0.9-1.4-1.3c-0.7-0.6-1.7-0.5-2.4,0.1
		c-0.7,0.7-0.8,1.6-0.2,2.4c0.2,0.3,0.5,0.6,0.8,0.9c2.5,2.7,4.9,5.4,7.4,8.1c0.8,0.9,1.4,1.8,1.4,3c0.1,1.8-0.9,3.5-2.6,4.2
		c-1.7,0.8-3.6,0.4-4.9-1c-3.3-3.5-6.5-7-9.7-10.6c-1-1.1-1.6-2.3-1.8-3.8c-0.3-3.1-0.5-6.2-0.7-9.3c-0.2-2.5-0.3-5-0.5-7.4
		c-0.1-1.2-1-2.1-2.1-2.3c-1.2-0.2-2.2,0.4-2.7,1.5c-0.2,0.5-0.2,1-0.2,1.6c0.1,6.9,0.2,13.8,0.2,20.7c0,2.4,0.8,4.4,2.4,6.2
		c4.8,5.3,9.5,10.7,14.2,16.1c0.8,0.9,1.3,1.9,1.4,3c0.2,1.4,0.1,2.9,0.1,4.3c0,0.7,0.2,1,1,1C24.6,82.7,26.5,82.8,28.5,82.8z"/>
	<path d="M64.5,19c0.1,1.5-0.6,2.7-1.7,3.7c-1.7,1.7-3.5,3.4-5.2,5.1c-0.7,0.7-1,1.5-0.8,2.5c0.5,2.5,0.8,5,1.3,7.4
		c0.3,1.9-0.3,3.5-1.8,4.7c-1.5,1.2-3.2,1.4-5,0.6c-2.3-1.1-4.5-2.3-6.7-3.5c-1.1-0.6-2.1-0.6-3.3,0c-2,1.1-4.1,2.2-6.2,3.3
		c-1.8,1-3.7,1-5.4-0.3c-1.7-1.3-2.3-3-1.9-5c0.4-2.4,0.8-4.9,1.3-7.3c0.2-0.9-0.1-1.6-0.7-2.2c-0.8-0.8-1.5-1.5-2.3-2.3
		c-0.8-0.8-0.8-1.5-0.2-2.1c0.5-0.5,1.4-0.5,2.1,0.2c0.8,0.7,1.5,1.5,2.3,2.2c1.4,1.3,1.9,2.9,1.6,4.8c-0.4,2.3-0.8,4.7-1.2,7
		c-0.2,1-0.2,2,0.8,2.7c0.9,0.7,1.9,0.4,2.8-0.1c2.1-1.1,4.3-2.2,6.4-3.4c1.6-0.8,3.1-0.9,4.7,0c2.1,1.1,4.1,2.2,6.2,3.2
		c0.3,0.1,0.5,0.3,0.8,0.4c0.8,0.4,1.7,0.4,2.4-0.2c0.7-0.6,0.9-1.4,0.8-2.2c-0.3-1.7-0.6-3.4-0.9-5.1c-0.1-0.9-0.3-1.7-0.4-2.6
		c-0.3-1.7,0.3-3.2,1.5-4.4c1.7-1.7,3.5-3.4,5.2-5.1c0.8-0.7,1.2-1.6,0.9-2.7c-0.3-1.1-1.2-1.4-2.2-1.5c-2.5-0.3-4.9-0.7-7.4-1.1
		c-1.7-0.3-2.9-1.2-3.7-2.8c-1.1-2.2-2.2-4.4-3.3-6.6c-0.4-0.9-1-1.6-2.1-1.6c-1.1,0-1.7,0.7-2.2,1.6c-1,2.1-2.1,4.3-3.1,6.4
		c-0.9,1.9-2.4,2.8-4.4,3.1c-2.4,0.3-4.8,0.7-7.1,1c-1.9,0.3-2.7,2.1-1.5,3.6c0.6,0.9,0.6,1.6,0,2.1c-0.7,0.5-1.4,0.4-2.1-0.4
		c-2.4-2.8-0.9-7.2,2.7-7.9c2.6-0.5,5.2-0.8,7.8-1.2c1.1-0.1,1.8-0.6,2.2-1.6c1-2.2,2.2-4.4,3.2-6.5C39.4,3,40.9,2,43,2
		c2.1,0,3.5,1.1,4.5,2.9c1.1,2.2,2.2,4.4,3.3,6.6c0.5,0.9,1.1,1.4,2.2,1.6c2.6,0.4,5.1,0.7,7.7,1.2C62.9,14.6,64.5,16.6,64.5,19z"/>
	<path d="M8.5,10.7c0.2,0,0.3,0,0.5,0.1c2.5,0.8,5,1.6,7.6,2.5c0.6,0.2,0.9,0.6,1,1.2c0.1,0.9-0.8,1.7-1.7,1.4
		c-2.6-0.8-5.2-1.7-7.8-2.5c-0.7-0.2-1-0.8-0.9-1.5C7.3,11.1,7.8,10.8,8.5,10.7z"/>
	<path d="M69.7,15.9c-0.6-0.1-1.1-0.3-1.3-0.9c-0.2-0.6-0.1-1.1,0.4-1.5c0.2-0.2,0.5-0.3,0.7-0.4
		c2.4-0.8,4.7-1.5,7.1-2.3c1-0.3,1.7,0,2,0.7c0.3,0.8-0.1,1.5-1.1,1.8c-2.4,0.8-4.9,1.6-7.3,2.4C70.1,15.9,69.9,15.9,69.7,15.9z"/>
	<path d="M74.9,24.9c0,1-0.8,1.6-1.7,1.4c-1.8-0.6-3.6-1.2-5.4-1.9c-0.7-0.3-0.9-1-0.7-1.7c0.2-0.7,0.8-1,1.4-0.8
		c1.9,0.5,3.8,1.2,5.7,2C74.7,24.1,74.8,24.5,74.9,24.9z"/>
	<path d="M18.9,23.3c0,0.5-0.3,0.9-0.9,1.1c-1.7,0.6-3.5,1.3-5.2,1.8c-0.7,0.2-1.4-0.1-1.7-0.8c-0.2-0.6,0-1.4,0.7-1.6
		c1.8-0.7,3.6-1.3,5.4-1.9C18.1,21.6,18.9,22.3,18.9,23.3z"/>
    </SvgIcon>
  )
}
