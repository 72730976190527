import React from "react";

import { SvgIcon } from "@mui/material";

export default function LogoIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 31.3 35.7">
      <path d="M16.1,33.8v-16l13.6-7.6v15.8L16.1,33.8z M1,10.1l13.6,7.6v16L1,25.9V10.1z M29,8.9l-13.6,7.6L1.7,8.9L15.4,1
	L29,8.9z"/>
    </SvgIcon>
  )
}
