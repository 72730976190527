import * as React from "react";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, Button, ButtonProps, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import VideoModal from "Components/VideoModal";
import useStyles from "./styles";


const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: ".1rem solid #ffffff",
  "&:hover": {
    border: ".1rem solid #ffffff",
    background: "#ffffff",
    color: "#913617",
  },
}));

export default function DescriptionVideo() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [playVideo, setPlayVideo] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setPlayVideo(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPlayVideo(false)
  };

  return (
    <>
    <Box className={classes.descriptionVideo}>
      <Box className={classes.textDescriptionVideo}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item lg={8} sm={10} xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="subtitle1" component="div" sx={{ color: "primary.contrastText" }} className={classes.titleDescriptionVideo}>
                Gestionamos nuestra eficiencia para lograr mejores resultados para nuestros clientes.
              </Typography>
              <Typography sx={{ color: "primary.contrastText" }} >
                Promovemos una cultura de excelencia y mejora continua en todas nuestras actividades.
              </Typography>
              <ColorButton variant="outlined" size="medium" color="info" startIcon={<PlayCircleIcon />} className={classes.buttonDescriptionVideo} onClick={handleClickOpen}>VER VIDEO </ColorButton>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
    <VideoModal open={open} onClose={handleClose} openVideo={playVideo}/>
    </>
  );
}
