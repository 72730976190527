import React from "react";

import { SvgIcon } from "@mui/material";

export default function ShieldIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 72.4 84.5">
      <path d="M35.1,84.5c-7.5-2.2-13.6-6.6-19.1-11.9C10.2,67.1,5.9,60.5,3.2,53c-1.6-4.3-2.6-8.8-2.9-13.4
		C-0.2,31.9,0.1,24.3,0,16.7c0-1.4,0.5-2,2-2.4c9.3-2.6,18.2-6.1,26.7-10.6c1.9-1,3.8-2,5.6-3.1c1.3-0.8,2.3-0.8,3.6,0
		c6.2,3.7,12.6,6.7,19.3,9.3c4.3,1.7,8.6,3.1,13.1,4.4c1.6,0.4,2,1,2,2.6c0,5.8,0,11.7,0,17.5c-0.1,8.9-1.9,17.4-6.1,25.2
		c-2.9,5.5-6.8,10.4-11.4,14.5c-3.7,3.3-7.7,6.2-12.2,8.4c-1.7,0.8-3.4,1.4-5.3,1.9C36.6,84.5,35.8,84.5,35.1,84.5z M3.8,28.6
		c0.1,4.1-0.2,8.9,0.5,13.6c1,6.8,3.3,13.1,7,18.9c4,6.4,9.3,11.5,15.6,15.6c2.5,1.6,5,3,7.8,3.9c0.8,0.3,1.7,0.4,2.5,0.1
		c2-0.6,3.9-1.5,5.7-2.5c8.2-4.7,14.8-11.1,19.5-19.4c3.8-6.8,5.9-14.2,6.1-22.1c0.1-6,0-11.9,0.1-17.9c0-0.9-0.3-1.2-1.1-1.5
		c-6.9-2-13.7-4.6-20.2-7.7c-3.5-1.6-7-3.4-10.3-5.4C36.4,4,36,4,35.5,4.3c-1,0.6-2,1.1-3,1.7c-8.8,4.8-18.1,8.6-27.7,11.4
		c-0.7,0.2-1,0.6-1,1.3C3.9,21.8,3.8,24.9,3.8,28.6z"/>
	<path d="M58.3,31.5c0,1.5-0.7,3-1.9,4.2C52.2,39.8,48.1,43.9,44,48c-3,3-6,6-8.9,8.9c-2.8,2.8-6.2,2.8-9,0.1
		c-3.3-3.3-6.6-6.6-9.9-9.9c-2.5-2.5-2.7-5.7-0.6-8.3c1.9-2.4,5.7-2.8,8-0.8c2.2,1.8,4.1,4,6.1,6c0.8,0.8,0.8,0.8,1.6,0
		c5.5-5.5,11.1-11.1,16.6-16.6c1.1-1.1,2.2-1.8,3.8-2C55.3,25,58.3,27.9,58.3,31.5z M30.5,55.3c0.8,0,1.3-0.3,1.8-0.9
		c7.1-7.1,14.2-14.2,21.4-21.4c0.1-0.1,0.2-0.2,0.3-0.4c0.6-0.7,0.7-1.6,0.3-2.4c-0.4-0.9-1.2-1.2-2.1-1.1c-0.7,0-1.1,0.5-1.6,0.9
		c-6.1,6.1-12.1,12.1-18.2,18.2c-1.4,1.4-2.3,1.4-3.7,0c-2.4-2.4-4.7-4.7-7.1-7.1c-0.9-0.9-2.2-0.9-3-0.1c-0.9,0.8-0.9,2.2,0,3.1
		c3.4,3.5,6.9,6.9,10.3,10.3C29.3,55.1,29.9,55.3,30.5,55.3z"/>
	<path d="M18.6,16.3c1,0,1.6,0.4,1.9,1.2c0.3,0.7,0.1,1.6-0.5,2.1c-0.3,0.3-0.6,0.4-1,0.5c-2.4,0.9-4.7,1.7-7.1,2.5
		c-0.8,0.3-1,0.6-1,1.4c0.1,1.4,0,2.8,0,4.2c0,1.2-0.7,2-1.8,2.1c-1.1,0-1.9-0.8-1.9-2c0-2.2,0-4.4,0-6.6c0-1.1,0.7-1.7,1.7-2
		c3-1,6-2,8.9-3.1C18.1,16.3,18.4,16.3,18.6,16.3z"/>
	<path d="M58.3,58.9c0,0.4-0.2,0.8-0.4,1.2c-2.9,4.4-6.5,8.1-10.7,11.4c-1,0.8-2.1,0.7-2.7-0.1
		c-0.7-0.9-0.6-1.9,0.4-2.8c1.9-1.5,3.7-3.2,5.4-4.9c1.5-1.6,2.9-3.3,4.1-5.1c0.3-0.4,0.5-0.8,0.9-1.1c0.5-0.5,1.2-0.6,1.9-0.3
		C57.9,57.5,58.3,58,58.3,58.9z"/>
	<path d="M41.5,71.3c1,0,1.8,0.9,1.8,1.9c0,1-1,1.9-2,1.9c-1,0-1.8-0.8-1.8-1.8C39.5,72.1,40.5,71.3,41.5,71.3z"/>
	<path d="M25.7,16c0,1-0.8,1.9-1.9,1.9c-1,0-1.8-0.9-1.8-1.9c0-0.9,0.9-1.8,1.9-1.8C24.8,14.1,25.7,15,25.7,16z"/>
    </SvgIcon>
  )
}
