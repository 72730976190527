import { Box, Container, Grid, Typography } from "@mui/material";

import useStyles from "./styles";

export default function BackupText() {
  const classes = useStyles();

  return (
    <Box className={classes.sectionBackupTest}>
      <Container>
        <Grid container alignItems="flex-start" justifyContent="center">
          <Grid item lg={8} sm={10} xs={12}>
            <Typography variant="subtitle2" component="div" sx={{ color: "primary.main", textAlign: "center", marginBottom: "2.5rem" }}>
              Años de trayectoria en el<br /> mercado nos respaldan
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Somos una compañía constructora con años de experiencia en importantes proyectos de ingeniería y construcción en el sur del país. Hemos demostrado nuestra capacidad para realizar obras de alta calidad, empleando tecnología avanzada en construcciones, y basándonos en sólidos pilares de gestión que aseguran un crecimiento sostenible: enfoque en el cliente, mejora continua, aseguramiento de la calidad, seguridad y cuidado del medio ambiente. 
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
