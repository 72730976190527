import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    contImage: {
      height: "22rem",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        height: "15rem",
      },
      "& img": {
        width: "100%", 
        height: "100%",
        objectFit: "cover",
        objectPosition: "top center",
      },
    },
  })
);