import { Box, Container, Link, Grid, Button, Typography } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { faMapMarkerAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import markerCarmen from "./../../Assets/images/marker.png";
import useStyles from "./styles";

const center = {
  lat: -16.413208568687818,
  lng: -71.54589871283602,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

export default function Contact() {
  const classes = useStyles();

  interface CustomFormData {
    nombre: string;
    asunto: string;
    correo: string;
    telefono: string;
    documento: File | null;
  }

  const [formData, setFormData] = useState<CustomFormData>({
    nombre: "",
    asunto: "",
    correo: "",
    telefono: "",
    documento: null,
  });

  const [formularioEnviado, setFormularioEnviado] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFormData({ ...formData, documento: e.target.files[0] });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData();
    data.append('formType', 'contacto'); // Asegúrate de agregar el campo oculto aquí
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        data.append(key, value instanceof File ? value : String(value));
      }
    });

    try {
      const response = await fetch("/enviar_correo.php", {
        method: "POST",
        body: data,
      });
      const result = await response.json();
      if (response.ok) {
        setFormularioEnviado(true);
        setError("");
        setFormData({
          nombre: "",
          asunto: "",
          correo: "",
          telefono: "",
          documento: null,
        });
      } else {
        setError(result.message || "Hubo un error al enviar el formulario");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Hubo un error al enviar el formulario");
    }
  };

  return (
    <Box className={classes.sectionContact}>
      <Container>
        <Grid
          container
          alignItems="center"
          sx={{ bgcolor: "secondary.light" }}
          className={classes.boxContact}>
          <Grid item xs={12} md={6} sm={4} style={{ padding: "2rem 2rem" }}>
            <Box
              mb={3}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "secondary.main",
              }}>
              <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" />
              <Box ml={1.5}>
                <Typography>
                  DIRECCIÓN: Av. Parra 324, <br /> Oficina 501 - Arequipa
                </Typography>
              </Box>
            </Box>
            <Box mb={4}>
              <Typography sx={{ color: "secondary.main" }} gutterBottom>
                Proyectos Ingeniería y construcción:
              </Typography>
              <Typography>
                Correo:{" "}
                <Link href="mailto:jdiaz@carmen.pe" underline="none">
                  jdiaz@carmen.pe
                </Link>
              </Typography>
              <Typography>
                Celular:{" "}
                <Link href="tel:51973585666 " underline="none">
                  973 585 666{" "}
                </Link>
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "secondary.main" }} gutterBottom>
                Administracion:
              </Typography>
              <Typography>
                Correo:{" "}
                <Link href="mailto:info@carmen.pe" underline="none">
                  info@carmen.pe
                </Link>
              </Typography>
              
              <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  <br />
                  Si eres una empresa o un emprendimiento relacionado, déjanos tus datos y adjunta tu carta de presentación (máximo 2MB).

                  <br />
                </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={4}>
            <form className={classes.formulario} onSubmit={handleSubmit}>
              <input type="hidden" name="formType" value="contacto" />

              <div>
                <input
                  className={classes.customInputs}
                  type="text"
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  className={classes.customInputs}
                  type="text"
                  id="asunto"
                  name="asunto"
                  placeholder="Asunto"
                  value={formData.asunto}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  className={classes.customInputs}
                  type="email"
                  id="correo"
                  name="correo"
                  placeholder="Correo Electrónico"
                  value={formData.correo}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  className={classes.customInputs}
                  type="tel"
                  id="telefono"
                  name="telefono"
                  placeholder="Teléfono"
                  value={formData.telefono}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    backgroundColor: "#913616",
                    color: "white",
                    width: "300px",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  }}>
                  Adjuntar carta de presentación
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx"
                    hidden
                    onChange={handleFileChange}
                    id="documento"
                    name="documento"
                  />
                </Button>
              </div>
              <button
                style={{
                  minWidth: "100px",
                  backgroundColor: "#913616",
                  color: "white",
                  fontWeight: "bold",
                }}
                className={classes.customInputs}
                type="submit">
                Enviar
              </button>
              {formularioEnviado && (
                <p className="exito">Formulario enviado con éxito!</p>
              )}
              {error && <p className="error">{error}</p>}
            </form>
          </Grid>
        </Grid>
      </Container>
      <Box className={classes.contMap}>
        <LoadScript
          googleMapsApiKey="AIzaSyDevCR6IJK8XZ7JEp7EjVxSkruN25-hM_Y"
          language="en"
          region="EN"
          loadingElement={
            <Box className={classes.loaderMap}>
              <FontAwesomeIcon icon={faSpinner} color="white" spin size="2x" />
            </Box>
          }>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={18}>
            <Marker icon={markerCarmen} position={center} />
          </GoogleMap>
        </LoadScript>
      </Box>
    </Box>
  );
}
