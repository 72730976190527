import * as React from "react";

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import bannerOne from "./../../Assets/images/banner_home.jpg";
import useStyles from "./styles";

interface SlidesCarrousel {
  image: string;
  caption: string;
}

const slides = [
  {
    "image": bannerOne,
    "caption": "Más de 6 décadas de evolución permanente, de respeto a la naturaleza y responsabilidad social.",
  },
]

export default function BannerHome() {
  const classes = useStyles();
  const settings = {
    dots: slides.length > 1 ? true : false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      {slides &&
        <Slider {...settings} className={classes.bannerHome} >
          {slides.map((slide: SlidesCarrousel) => (
            <Box className={classes.slideCarousel} sx={{ background: `url(${slide.image})`, backgroundSize: "cover", backgroundPosition: "top center", backgroundRepeat: "no-repeat" }}>
              <Box className={classes.bannerCaption} sx={{ display: "flex", alignItems: "center" }}>
                <Container >
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography variant="subtitle1" component="div" sx={{ color: "primary.contrastText", marginBottom: "1rem" }}>
                        {slide.caption}
                      </Typography>
                      <Button href="/nosotros" variant="contained" color="secondary" size="medium">NOSOTROS</Button>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Box>
          ))}
        </Slider>
      }
    </>
  );
}