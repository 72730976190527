import * as React from "react";

import { Box, Button, ButtonProps, Container, Grid, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import useStyles from "./styles";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: ".1rem solid #913617",
  minWidth: "auto",
  fontFamily: ["'OptimalLTStdBold'", "'sans-serif'"].join(","),
  lineHeight: "100%",
  letterSpacing: ".07rem",
  fontSize: 18,
  padding: "7px 15px",
  "&:hover": {
    border: ".1rem solid #913617",
    background: "#913617",
    color: "#ffffff",
  },
}));

export default function SectionGrayContact() {
  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: "secondary.light" }} className={classes.sectionGrayContact}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item lg={6} sm={10} xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className={classes.contSectionGrayContact}>
              <Typography sx={{ textAlign: "center" }} className={classes.textLeft}>
                ¿Tienes un proyecto en mente?, ponte en contacto con nosotros.
              </Typography>
              <ColorButton href="/contacto" variant="outlined" size="medium" color="secondary">CONTACTO</ColorButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
