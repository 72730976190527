import React from "react";

import { SvgIcon } from "@mui/material";

export default function BookIcon(props: any) {
	return (
		<SvgIcon {...props} viewBox="0 0 97.4 87.5">
			<path d="M1,73.5c0.9-3.7,3.2-6.8,5.1-10C17,44.6,27.9,25.7,38.8,6.9C41.1,3,44.4,1,48.9,1c4.3,0,7.6,2.1,9.8,5.8
		c2.4,4,4.7,8.1,7,12.2c9.2,15.9,18.3,31.7,27.5,47.6c1.3,2.2,2.7,4.4,3.3,6.9c0,1.3,0,2.6,0,3.9c-0.1,0.4-0.2,0.8-0.3,1.2
		c-1.3,4.7-5.8,8.2-10.7,8.2c-24.5,0-48.9,0-73.4,0c-2.9,0-5.4-1.1-7.6-3.1c-1.8-1.7-2.9-3.9-3.5-6.3C1,76.1,1,74.8,1,73.5z
		 M48.8,80.9c1.1,0,2.1,0,3.2,0c11.1,0,22.1,0,33.2,0c3.2,0,5.4-2.1,5.7-5.2c0.1-1.5-0.6-2.8-1.3-4c-4.3-7.5-8.6-14.9-13-22.4
		C69,36.2,61.3,23.1,53.9,10c-2.4-4.2-7.9-4-10.2,0c-9.6,16.8-19.3,33.4-29,50.2c-2.4,4.1-4.8,8.3-7.2,12.4
		c-0.9,1.6-1.1,3.4-0.3,5.1c1,2.3,2.9,3.3,5.4,3.3C24.6,80.9,36.7,80.9,48.8,80.9z"/>
			<path d="M45.9,42.8c0-4.3,0-8.6,0-12.8c0-0.9,0.3-1.2,1.1-1.1c1.2,0.1,2.4,0.1,3.5,0c0.9,0,1.1,0.3,1.1,1.1
		c0,6,0,11.9,0,17.9c0,2.7,0,5.4,0,8.1c0,0.8-0.3,1-1,1c-1.2,0-2.5,0-3.7,0c-0.8,0-1-0.3-1-1C45.9,51.6,45.9,47.2,45.9,42.8z"/>
			<path d="M52.6,66.2c0,2.2-1.7,3.8-3.8,3.8c-2.1,0-3.8-1.7-3.8-3.8c0-2.2,1.6-3.8,3.8-3.8
		C50.9,62.4,52.6,64.1,52.6,66.2z"/>
		</SvgIcon>
	)
}
