import * as React from "react";

import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Img } from "react-image";

import logoGrupoInca from "./../../Assets/images/logo_grupo_inca.png";
import useStyles from "./styles";

export default function Introduction() {
  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: "primary.light" }} className={classes.introduction}>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item md={6} xs={12}>
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className={classes.textSomosIntro}>
              <Typography sx={{ letterSpacing: "normal" }}>
                Somos parte de:
              </Typography>
              <Link
                href="https://grupoinca.com/es/"
                target="_blank"
                sx={{ display: "block" }}>
                <Img src={logoGrupoInca} className={classes.logo} />
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className={classes.textIntroduction}>
            <Typography variant="body1">
              Somos una compañía constructora arequipeña que forma parte del
              Grupo Inca.Hemos desarrollado importantes proyectos de ingeniería
              y construcción en el sur del país, teniendo en consideración
              pilares de gestión orientados a asegurar un crecimiento
              sostenible: enfoque en el cliente,mejora continua y aseguramiento
              de la calidad, seguridad y cuidado del medio ambiente.
            </Typography>            
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
