import React from "react";

import { SvgIcon } from "@mui/material";

export default function FacebookIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 76 65.4">
      <path d="M76,53c-1.1,2.7-2.3,3.5-5.2,3.5c-0.7,0-1.3,0-2,0c-1.6,0-1.6,0-1.6,1.6c0,1.8,0,3.6,0,5.3
		c0,0.7-0.1,1.3-0.8,1.7c-0.7,0.4-1.3,0.2-1.9-0.2c-4-2.7-8-5.3-12-8c-0.5-0.3-1-0.5-1.6-0.5c-6.2,0-12.4,0-18.6,0
		c-2.9,0-4.8-1.9-4.8-4.8c0-3.1,0-6.1,0-9.2c0-0.7-0.2-0.8-0.9-0.9c-1.4-0.1-2.5,0.3-3.6,1.1c-3.5,2.4-7.2,4.8-10.7,7.2
		c-0.2,0.1-0.4,0.2-0.6,0.4c-0.6,0.4-1.2,0.5-1.8,0.1c-0.6-0.3-0.8-0.8-0.8-1.5c0-2.1,0-4.3,0-6.4c0-0.7-0.2-0.9-0.8-0.8
		c-1.1,0-2.2,0-3.3,0c-2.8,0.2-4.5-1.4-5.1-3.5C0,26.6,0,15,0,3.5C1,1,2.3,0,4.5,0c13.1,0,26.2,0,39.3,0c2.7,0,4.6,1.9,4.6,4.6
		c0,3.1,0,6.2,0,9.3c0,1,0,1,1,1c7.3,0,14.5,0,21.8,0c0.5,0,0.9,0,1.4,0.1c1.8,0.5,2.8,1.7,3.4,3.4C76,29.9,76,41.5,76,53z M12.4,46
		c0.4-0.1,0.6-0.3,0.8-0.4c3.2-2.2,6.5-4.3,9.7-6.5c0.6-0.4,1.1-0.5,1.8-0.5c6.2,0,12.5,0,18.7,0c1.5,0,2-0.5,2-2
		c0-10.5,0-21.1,0-31.6c0-1.4-0.5-1.9-1.9-1.9c-12.8,0-25.7,0-38.5,0C3.6,3.1,3,3.6,3,5c0,10.6,0,21.1,0,31.7c0,1.4,0.6,1.9,2,1.9
		c1.8,0,3.5,0,5.3,0c1.7,0,2.1,0.4,2.1,2.2C12.4,42.5,12.4,44.2,12.4,46z M73,35.7c0-5.3,0-10.5,0-15.8c0-1.4-0.5-1.9-1.9-2
		c-7.3,0-14.5,0-21.8,0c-0.6,0-0.8,0.2-0.8,0.8c0,2.4,0,4.8,0,7.1c0,0.7,0.3,0.9,0.9,0.9c3.2,0,6.5,0,9.7,0c2.2,0,4.3,0,6.5,0
		c1,0,1.7,0.7,1.6,1.7c-0.1,0.8-0.7,1.3-1.7,1.3c-5.3,0-10.6,0-16,0c-1,0-1,0-1,1c0,1.3,0,2.7,0,4c0,0.7,0.2,0.9,0.9,0.9
		c3.7,0,7.4,0,11.1,0c1.7,0,3.5,0,5.2,0c1,0,1.7,0.8,1.6,1.7c-0.1,0.8-0.7,1.3-1.7,1.3c-5.4,0-10.9,0-16.3,0c-0.6,0-0.9,0.1-1.2,0.7
		c-0.9,1.5-2.2,2.3-4,2.3c-4.1,0-8.3,0-12.4,0c-0.6,0-0.9,0.2-0.8,0.8c0,2.9,0,5.8,0,8.8c0,1.7,0.5,2.2,2.2,2.2c6.3,0,12.5,0,18.8,0
		c0.8,0,1.4,0.2,2.1,0.6c3.2,2.1,6.4,4.3,9.6,6.4c0.2,0.1,0.4,0.5,0.7,0.3c0.2-0.1,0.1-0.5,0.1-0.7c0-1.6,0-3.2,0-4.8
		c0-1.2,0.6-1.8,1.8-1.8c1.7,0,3.3,0,5,0c1.7,0,2.2-0.5,2.2-2.2C73,46.1,73,40.9,73,35.7z"/>
      <path d="M16.2,30.1c0.3-1.8,0.6-3.6,1-5.5c0.1-0.6,0-1.1-0.5-1.6c-1.3-1.2-2.5-2.4-3.7-3.6c-0.5-0.5-0.9-1.1-0.6-1.8
		c0.3-0.7,0.9-1,1.6-1.1c1.7-0.2,3.4-0.5,5.1-0.7c0.7-0.1,1.1-0.4,1.4-1c0.7-1.5,1.5-3,2.2-4.5c0.3-0.7,0.8-1.2,1.6-1.2
		c0.8,0,1.3,0.6,1.6,1.2c0.8,1.6,1.6,3.1,2.3,4.7c0.2,0.5,0.5,0.7,1.1,0.8c1.8,0.2,3.6,0.5,5.4,0.8c0.7,0.1,1.3,0.4,1.5,1.2
		c0.2,0.7-0.1,1.3-0.6,1.7c-1.3,1.3-2.6,2.5-3.9,3.8c-0.4,0.4-0.5,0.8-0.4,1.3c0.3,1.7,0.6,3.5,0.9,5.3c0.1,0.7,0,1.3-0.6,1.8
		c-0.6,0.5-1.3,0.4-1.9,0c-1.6-0.9-3.2-1.7-4.8-2.5c-0.4-0.2-0.8-0.2-1.2,0c-1.6,0.9-3.3,1.7-4.9,2.6C17.4,32.2,16.1,31.4,16.2,30.1
		z M24.1,14.1c-0.6,1.1-1.1,2.1-1.5,3.1c-0.4,0.9-1.1,1.4-2.1,1.5c-1.1,0.1-2.2,0.3-3.2,0.5c0.9,0.9,1.7,1.8,2.6,2.7
		c0.5,0.5,0.7,1.1,0.6,1.9c-0.2,1.2-0.4,2.3-0.6,3.7c1.2-0.6,2.2-1.2,3.2-1.7c0.7-0.4,1.4-0.4,2.1,0c0.9,0.5,1.8,0.9,2.7,1.4
		c0.1,0.1,0.3,0.2,0.5,0.1c0.2-0.1,0-0.3,0-0.5c-0.1-0.9-0.3-1.8-0.5-2.6c-0.3-1.1,0-1.9,0.8-2.6c0.8-0.7,1.5-1.5,2.3-2.4
		c-1.2-0.2-2.3-0.4-3.4-0.5c-0.9-0.1-1.6-0.5-2-1.4C25.3,16.2,24.7,15.2,24.1,14.1z"/>
      <path d="M54.7,47.6c-3.5,0-7,0-10.5,0c-0.7,0-1.3-0.2-1.6-0.8c-0.5-1,0.1-2.1,1.2-2.2c0.1,0,0.3,0,0.4,0
		c7,0,14.1,0,21.1,0c1,0,1.6,0.4,1.8,1.1c0.2,1-0.5,1.9-1.6,1.9c-2.2,0-4.4,0-6.5,0C57.6,47.6,56.1,47.6,54.7,47.6z"/>
      <path d="M39.5,46.1c0,0.9-0.7,1.5-1.6,1.5c-0.9,0-1.5-0.7-1.5-1.6c0-0.8,0.7-1.5,1.5-1.5
		C38.9,44.5,39.5,45.2,39.5,46.1z"/>
    </SvgIcon>
  )
}
