import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import * as React from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "Components/CustomIcons";
import { Box, Container, IconButton, Typography } from "@mui/material";

import { Img } from "react-image";
import Slider from "react-slick";
import logoArcaContinenta from "./../../Assets/images/arca_continental.png";
import logoArquimia from "./../../Assets/images/arquimia.png";
import logoBackus from "./../../Assets/images/backus.png";
import logoBdp from "./../../Assets/images/bdp.png";
import logoBengalaInmobiliaria from "./../../Assets/images/bengala_inmobiliaria.png";
import logoCissac from "./../../Assets/images/cissac.png";
import logoColcaLodge from "./../../Assets/images/colca_lodge.png";
import logoDanielAlcidesCarrion from "./../../Assets/images/dac.png";
import logoDisoil from "./../../Assets/images/disoil.png";
import logoInRetail from "./../../Assets/images/in_retail.png";
import logoIncaTops from "./../../Assets/images/inca_tops.png";
import logoIncalpacaTpx from "./../../Assets/images/incalpaca_tpx.png";
import logoInternationalSos from "./../../Assets/images/international_sos.png";
import logoIronMountain from "./../../Assets/images/iron_mountain.png";
import logoJjc from "./../../Assets/images/jjc.png";
import logoKuna from "./../../Assets/images/kuna.png";
import logoMolitalia from "./../../Assets/images/molitalia.png";
import logoMolyCop from "./../../Assets/images/moly_cop.jpg";
import logoNave from "./../../Assets/images/nave_two.png";
import logoPatioDelEkeko from "./../../Assets/images/patio_del_ekeko.png";
import logoScharff from "./../../Assets/images/scharff.png";
import logoSkf from "./../../Assets/images/skf.png";
import logoUCSP from "./../../Assets/images/san_pablo.png";
import logoAgroInca from "./../../Assets/images/agroinca.png";
import logoVainsa from "./../../Assets/images/vainsa.png";
import logoSupermecados from "./../../Assets/images/supermercados.png";
import useStyles from "./styles";

export default function OurClients() {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    prevArrow: <div><IconButton aria-label="arrow-left" color="secondary"><ArrowLeftIcon /></IconButton></div>,
    nextArrow: <div><IconButton aria-label="arrow-left" color="secondary"><ArrowRightIcon /></IconButton></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box className={classes.ourClients}>
      <Container>
        <Typography variant="h4" component="h4" sx={{ color: "secondary.main", textAlign: "center" }}>
          NUESTROS CLIENTES
        </Typography>
        <Box>
          <Slider {...settings}>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoPatioDelEkeko} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoBackus} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoDisoil} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoArcaContinenta} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoDanielAlcidesCarrion} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoBengalaInmobiliaria} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoJjc} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoCissac} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoInternationalSos} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoIronMountain} />
              </Box>
            </Box>
{/*             <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoRealInmobiliaria} />
              </Box>
            </Box> */}
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoMolyCop} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoIncalpacaTpx} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoInRetail} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoArquimia} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoKuna} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoScharff} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoIncaTops} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoNave} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoColcaLodge} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoBdp} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoMolitalia} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoSupermecados} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoUCSP} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoSkf} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoAgroInca} />
              </Box>
            </Box>
            <Box className={classes.contLogos}>
              <Box className={classes.contLogos}>
                <Img src={logoVainsa} />
              </Box>
            </Box>

            
          </Slider>
        </Box>
      </Container>
    </Box>
  );
}
