import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    sectionBackupTest: {
      padding: "2rem 0 5rem",
    },
  })
);