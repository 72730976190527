import * as React from "react";

import { Box, Container, Grid, Typography } from "@mui/material";
import { CheckIcon, CommentsIcon, ConstructionIcon, DesignIcon, FinanceIcon, GroupIcon, HandsTwoIcon, HeartIcon, HelmetIcon, LawsIcon, LeavesIcon, NoteBookIcon, QualityIcon, ShieldTwoIcon, WarningIcon } from "Components/CustomIcons";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Img } from "react-image"
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import keyImg from "./../../Assets/images/key.png";
import { styled } from "@mui/material/styles";
import useStyles from "./styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "inherit" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  padding: `${theme.spacing(.8)} 4%`,
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.primary.contrastText,
    fontSize: "1.3rem",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: `${theme.spacing(6)} 4%`,
  border: "none",
  background: "#d2d2d2",
}));

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}
export default function Sig() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box className={classes.sectionSig}>
      <Container>
        <Box>
          <Box sx={{ bgcolor: "primary.main" }} className={classes.titleSig}>
            <Typography sx={{ color: "primary.contrastText" }}>REQUISITOS DEL CLIENTE</Typography>
          </Box>
          <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography variant="h3" component="h3" >Procesos Estratégicos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container >
                <Grid item sm={3} xs={12}>
                  <Box sx={{ textAlign: "center", width: "fit-content", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} className={`${classes.itemPlanification} itemLeft`}>
                    <Box className={classes.contIcon}>
                      <NoteBookIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                    </Box>
                    <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                      PLANIFICACIÓN<br /> ESTRATÉGICA
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={9} xs={12} >
                  <Box sx={{ bgcolor: "#bdbdbd", borderRadius: 5 }} className={classes.sectionGray}>
                    <Grid container >
                      <Grid item md={3} sm={6} xs={12}>
                        <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                          <Box className={classes.contIcon}>
                            <CheckIcon className={classes.iconSig} sx={{ fontSize: "3.5rem" }} />
                          </Box>
                          <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                            GESTIÓN<br /> DE CALIDAD
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                          <Box className={classes.contIcon}>
                            <HelmetIcon className={classes.iconSig} sx={{ fontSize: "4.5rem" }} />
                          </Box>
                          <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                            GESTIÓN<br /> DE SEGURIDAD
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                          <Box className={classes.contIcon}>
                            <LeavesIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                          </Box>
                          <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                            GESTIÓN<br /> AMBIENTAL
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={3} sm={6} xs={12}>
                        <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                          <Box className={classes.contIcon}>
                            <WarningIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                          </Box>
                          <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                            GESTIÓN<br /> DE RIESGOS
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography variant="h3" component="h3">Procesos Operativos</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container >
                <Grid item sm={3} xs={12}>
                  <Box sx={{ textAlign: "center", width: "fit-content", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} className={`${classes.itemPlanification} itemLeft`}>
                    <Box className={classes.contIcon}>
                      <LawsIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                    </Box>
                    <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                      GESTIÓN <br />DE LICITACIÓN
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={9} xs={12}>
                  <Box sx={{ bgcolor: "#bdbdbd", borderRadius: 5 }} className={classes.sectionGrayTwo}>
                    <Grid container >
                      <Grid item sm={6} xs={12}>
                        <Box sx={{ display: "flex", position: "relative", alignItems: "center" }}  >
                          <Box className={classes.key}>
                            <Img src={keyImg} />
                          </Box>
                          <Box className={classes.textKey}>
                            <Box sx={{ display: "flex", alignItems: "center" }} className={classes.itemKey}>
                              <Box className={classes.contIcon} pr={2} sx={{ marginBottom: 0 }} >
                                <DesignIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                              </Box>
                              <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                                DISEÑO E<br /> INGENIERÍA
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }} className={classes.itemKey}>
                              <Box className={classes.contIcon} pr={2} sx={{ marginBottom: 0 }} >
                                <ConstructionIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                              </Box>
                              <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                                CONSTRUCCIÓN
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Box sx={{ display: "flex", position: "relative", alignItems: "center" }} pt={5.6}>
                          <Box className={classes.key}>
                            <Img src={keyImg} />
                          </Box>
                          <Box className={classes.textKey}>
                            <Box sx={{ display: "flex", alignItems: "center" }} className={classes.itemKey}>
                              <Box className={classes.contIcon} pr={2} sx={{ marginBottom: 0 }} >
                                <CommentsIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                              </Box>
                              <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                                COMPRAS Y SUBCONTRATACIONES
                              </Typography>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center" }} className={classes.itemKey}>
                              <Box className={classes.contIcon} pr={2} sx={{ marginBottom: 0 }} >
                                <HeartIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                              </Box>
                              <Typography sx={{ fontSize: 14, lineHeight: 1.15 }}>
                                SEGURIDAD Y SALUD OCUPACIONAL
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography variant="h3" component="h3">Procesos de Apoyo</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ bgcolor: "#bdbdbd", borderRadius: 5 }} className={classes.sectionGray}>
                <Grid container >
                  <Grid item md={2} sm={4} xs={12}>
                    <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                      <Box className={classes.contIcon}>
                        <GroupIcon className={classes.iconSig} sx={{ fontSize: "3.5rem" }} />
                      </Box>
                      <Typography component="div" sx={{ fontSize: 14, lineHeight: 1.15 }}>
                        RECLUTAMIENTO<br /> Y SELECCIÓN DE PERSONAL
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={2} sm={4} xs={12}>
                    <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                      <Box className={classes.contIcon}>
                        <QualityIcon className={classes.iconSig} sx={{ fontSize: "3.5rem" }} />
                      </Box>
                      <Typography component="div" sx={{ fontSize: 14, lineHeight: 1.15 }}>
                        GESTIÓN<br /> DE TALENTO HUMANO
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={2} sm={4} xs={12}>
                    <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                      <Box className={classes.contIcon}>
                        <CheckIcon className={classes.iconSig} sx={{ fontSize: "3.5rem" }} />
                      </Box>
                      <Typography component="div" sx={{ fontSize: 14, lineHeight: 1.15 }}>
                        AUDITORÍAS
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={2} sm={4} xs={12}>
                    <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                      <Box className={classes.contIcon}>
                        <HandsTwoIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                      </Box>
                      <Typography component="div" sx={{ fontSize: 14, lineHeight: 1.15 }}>
                        GESTIÓN<br /> DE CALIDAD EN CONSTRUCCIÓN
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={2} sm={4} xs={12}>
                    <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                      <Box className={classes.contIcon}>
                        <ShieldTwoIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                      </Box>
                      <Typography component="div" sx={{ fontSize: 14, lineHeight: 1.15 }}>
                        GESTIÓN<br /> DE SEGURIDAD EN CONSTRUCCIÓN
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={2} sm={4} xs={12}>
                    <Box sx={{ textAlign: "center" }} className={classes.itemPlanification}>
                      <Box className={classes.contIcon}>
                        <FinanceIcon className={classes.iconSig} sx={{ fontSize: "inherit" }} />
                      </Box>
                      <Typography component="div" sx={{ fontSize: 14, lineHeight: 1.15 }}>
                        GESTIÓN<br /> FINANCIERA
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}
