import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import * as React from "react";

import { Box, Container, Grid, Typography } from "@mui/material";

import Slider from "react-slick";
import useStyles from "./styles";

export default function MissionVision() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <Box className={classes.missionVission}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item lg={8} sm={10} xs={12}>
              <Slider {...settings} className={classes.carouselMissionVision}>
                <Box className={classes.wrapperMissionVission}>
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{ color: "secondary.main", textAlign: "center" }}>
                    VISIÓN
                  </Typography>
                  <Typography sx={{ textAlign: "center" }}>
                    Para el año 2026 CARMEN será la división constructora del
                    Grupo Inca reconocida por ofrecer un servicio que busca
                    trascender en el tiempo.
                  </Typography>
                </Box>
                <Box className={classes.wrapperMissionVission}>
                  <Typography
                    variant="h4"
                    component="h4"
                    sx={{ color: "secondary.main", textAlign: "center" }}>
                    MISIÓN
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{ textAlign: "center" }}>
                    Brindar servicios eficientes e innovadores de ingeniería y
                    construcción con altos estándares de calidad, seguridad y
                    cuidado del medio ambiente; promoviendo el desarrollo
                    integral de nuestros colaboradores.
                  </Typography>
                </Box>
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
