import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";
import img from "./../../Assets/images/bg_footer.jpg"

export default makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      backgroundImage: `url(${img})`,
      backgroundSize: "cover",
      backgroundPosition: "top center",
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "rgba(40 40 40 / .87)",
        zIndex: 0,
      },
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, 0.45) 0%, transparent 50%)",
        zIndex: 1,
      },
    },
    contFooter: {
      zIndex: 2,
      position: "relative",
      padding: "6rem 0 3rem",
      [theme.breakpoints.down("md")]: {
        padding: "5rem 0 0",
      },
    },
    contTextFooter: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    logoCarmen: {
      width: 180,
      display: "block",
      marginBottom: "1.5rem",
      [theme.breakpoints.down("md")]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    logoGrupoInca: {
      width: 130,
      marginLeft: ".6rem",
    },
    titleItems: {
      position: "relative",
      paddingLeft: "2.2rem",
      paddingBottom: "1.2rem",
      "&::before": {
        content: "''",
        position: "absolute",
        top: -4,
        left: 0,
        width: "1.5rem",
        height: "1.5rem",
        background: theme.palette.secondary.main,
      },
    },
    itemsLinkFooter: {
      paddingLeft: "2.2rem",
      margin: 0,
      "& li": {
        paddingBottom: ".3rem",
        listStyle: "none",
        "& a": {
          textDecoration: "none",
          textTransform: "uppercase",
          fontSize: 14,
          lineHeight: 1,
        },
        "&:last-child": {
          paddingBottom: 0,
        },
      },
    },
    itemsLinkSocialFooter: {
      paddingLeft: "2.2rem",
      margin: 0,
      display: "flex",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        margin: "2.5rem 0",
        paddingLeft: 0,
      },
      "& li": {
        listStyle: "none",
        paddingRight: ".8rem",
        "& a": {
          textDecoration: "none",
          display: "block",
          "& svg": {
            fontSize: 25,
          },
        },
        "&:last-child": {
          paddingRight: 0,
        },
      },
    },
    textSomosParte: {
      marginTop: "2rem",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
    },
    contText: {
      position: "relative",
      zIndex: 3,
      padding: "2.5rem 0",
    },
  })
);