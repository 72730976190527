import * as React from "react";

import BannerModelThree from "Components/BannerModelThree";
import IntroductionEngineering from "Components/IntroductionEngineering";
import PictureAlone from "Components/PictureAlone";
import bannerServices from "./../../Assets/images/estructura-BIM.png";
import imgEngineering from "./../../Assets/images/sistema-bim.png"

const TitleBanner = () => {

  return (
    <div>
      Nuestra experiencia<br /> en ingeniería<br /> nos respalda
    </div>
  );
};
export default function EngineeringPage() {

  return (
    <>
      <BannerModelThree image={bannerServices} title={<TitleBanner />} />
      <IntroductionEngineering />
      <PictureAlone picture={imgEngineering} />
    </>
  );
}
