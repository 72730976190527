import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    introduction: {
      padding: "4.5rem 0",
    },
    logo: {
      width: "17rem",
      marginLeft: ".8rem",
      [theme.breakpoints.down("sm")]: {
        width: "11rem",
        marginTop: 15,
      },
    },
    textIntroduction: {
      "& p": {
        marginBottom: ".9rem",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },
    textSomosIntro: {
      width: "100%",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        marginBottom: "1rem",
      },
    },
  })
);