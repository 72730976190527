import * as React from "react";

import { Box, Container, Grid, Typography } from "@mui/material";
import { HandsIcon, LeavesIcon, ShieldIcon } from "Components/CustomIcons";
import useStyles from "./styles";

export default function Liabilities() {
  const classes = useStyles();

  return (
    <Box className={classes.liabilities}>
      <Container>
        <Typography variant="subtitle2" component="div" sx={{ color: "primary.main", textAlign: "center", marginBottom: "4rem" }}>
          Comprometidos con nuestro trabajo
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <HandsIcon sx={{ color: "secondary.dark", fontSize: "6rem" }} className={classes.icons} />
              <Typography variant="h6" component="h6" sx={{ textAlign: "center" }}>Enfocados en<br /> lo que hacemos</Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <ShieldIcon sx={{ color: "secondary.dark", fontSize: "6rem" }} className={classes.icons} />
              <Typography variant="h6" component="h6" sx={{ textAlign: "center" }}>Enfocados en<br /> la seguridad</Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <LeavesIcon sx={{ color: "secondary.dark", fontSize: "6rem" }} className={classes.icons} />
              <Typography variant="h6" component="h6" sx={{ textAlign: "center" }}>Enfocados en<br /> el medio ambiente</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
