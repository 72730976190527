import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import useStyles from "./styles";

export default function WorkWithUs() {
  const classes = useStyles();

  interface CustomFormData {
    nombre: string;
    asunto: string;
    correo: string;
    telefono: string;
    documento: File | null;
  }

  const [formData, setFormData] = useState<CustomFormData>({
    nombre: "",
    asunto: "",
    correo: "",
    telefono: "",
    documento: null,
  });

  const [formularioEnviado, setFormularioEnviado] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFormData({ ...formData, documento: e.target.files[0] });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData();
    data.append('formType', 'trabajo'); // Asegúrate de agregar el campo oculto aquí
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        data.append(key, value instanceof File ? value : String(value));
      }
    });

    try {
      const response = await fetch("/enviar_correo.php", {
        method: "POST",
        body: data,
      });
      const result = await response.json();
      if (response.ok) {
        setFormularioEnviado(true);
        setError("");
        setFormData({
          nombre: "",
          asunto: "",
          correo: "",
          telefono: "",
          documento: null,
        });
      } else {
        setError(result.message || "Hubo un error al enviar el formulario");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Hubo un error al enviar el formulario");
    }
  };

  const loadImage = (imageName: string) =>
    require(`./../../Assets/images/${imageName}`).default;

  return (
    <Box className={classes.sectionContact}>
      <Container>
        <Box>
          <Box
            sx={{ bgcolor: "secondary.light" }}
            className={classes.boxContact}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={6} sm={4} style={{ padding: "2rem 2rem" }}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{
                    color: "primary.main",
                    textAlign: "left",
                    marginBottom: "1.8rem",
                  }}
                  className={classes.textCapion}>
                  <div>
                    Trabaja con
                    <br />
                    Nosotros
                  </div>
                </Typography>
                <Typography variant="body1" sx={{ textAlign: "justify" }}>
                  Déjanos tus datos y adjunta tu CV (máximo 2MB), de encajar con
                  alguno de nuestros perfiles nos pondremos en contacto contigo.
                  <br />
                  <br />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} sm={4}>
                <form className={classes.formulario} onSubmit={handleSubmit}>
                <input type="hidden" name="formType" value="trabajo"/>
                  <div>
                    <input
                      className={classes.customInputs}
                      type="text"
                      id="nombre"
                      name="nombre"
                      placeholder="Nombre"
                      value={formData.nombre}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <input
                      className={classes.customInputs}
                      type="text"
                      id="asunto"
                      name="asunto"
                      placeholder="Asunto"
                      value={formData.asunto}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <input
                      className={classes.customInputs}
                      type="email"
                      id="correo"
                      name="correo"
                      placeholder="Correo Electrónico"
                      value={formData.correo}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <input
                      className={classes.customInputs}
                      type="tel"
                      id="telefono"
                      name="telefono"
                      placeholder="Teléfono"
                      value={formData.telefono}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      style={{
                        backgroundColor: "#913616",
                        color: "white",
                        width: "300px",
                        textTransform: "capitalize",
                        fontSize: "12px",
                      }}>
                      Adjuntar CV
                      <input
                        type="file"
                        accept=".pdf, .doc, .docx"
                        hidden
                        onChange={handleFileChange}
                        id="documento"
                        name="documento"
                      />
                    </Button>
                  </div>
                  <button
                    style={{
                      minWidth: "100px",
                      backgroundColor: "#913616",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    className={classes.customInputs}
                    type="submit">
                    Enviar
                  </button>
                  {formularioEnviado && (
                    <p className="exito">Formulario enviado con éxito!</p>
                  )}
                  {error && <p className="error">{error}</p>}
                </form>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Grid item xs={12} md={12} sm={12}>
        <img
          src={loadImage("wwu24.png")}
          alt="workwithus"
          style={{ width: "100%" }}
        />
      </Grid>
    </Box>
  );
}
