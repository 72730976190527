import React from "react";

import { SvgIcon } from "@mui/material";

export default function DesignIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 73.2 72.6">
      <path d="M0,60.2c0.8-1.1,1.9-1.8,2.9-2.6c10.5-8.8,21-17.7,31.6-26.5c8.7-7.3,17.5-14.7,26.2-22
		c3.3-2.8,6.7-5.6,10-8.4c0.2-0.2,0.4-0.4,0.7-0.5c0.8-0.4,1.7,0.1,1.8,1c0,0.3,0,0.5,0,0.8c0,22.9,0,45.8,0,68.7c0,1.6-0.4,2-2,2
		c-23.1,0-46.2,0-69.2,0c-0.8,0-1.5-0.1-2-0.8C0,68,0,64.1,0,60.2z M65.7,16.2c-0.3,0.2-0.5,0.4-0.7,0.6c-6.3,5.3-12.7,10.7-19.1,16
		c-0.5,0.4-0.5,0.6,0,1.1c6.4,6.4,12.8,12.8,19.2,19.1c0.2,0.2,0.3,0.4,0.6,0.4C65.7,41,65.7,28.7,65.7,16.2z M70.7,4.1
		c-0.4,0.3-0.6,0.5-0.9,0.7c-1.7,1.4-3.4,2.8-5,4.2c-8.1,6.8-16.1,13.5-24.2,20.3c-9.7,8.2-19.4,16.3-29.2,24.5
		c-2.8,2.3-5.6,4.7-8.4,7c-0.4,0.3-0.6,0.7-0.6,1.2c0,0.4,0,0.9,0,1.4c0,0.2,0,0.5,0.2,0.5c0.9,0,1.8,0.3,2.5-0.3
		c1.7-1.4,3.4-2.8,5-4.2c7.8-6.5,15.6-13.1,23.4-19.6c8.8-7.4,17.6-14.8,26.4-22.1c3.3-2.8,6.6-5.6,10-8.4c0.3-0.2,0.7-0.4,0.7-0.9
		C70.7,6.9,70.7,5.6,70.7,4.1z M36.6,66.5c-10.6,0-21.3,0-31.9,0c-0.5,0-1,0-1.6,0c-0.4,0-0.6,0.1-0.6,0.6c0,0.8,0,1.6,0,2.4
		c0,0.4,0.2,0.6,0.6,0.6c0.2,0,0.4,0,0.6,0c21.9,0,43.9,0,65.8,0c0.1,0,0.3,0,0.4,0c0.6,0.1,0.8-0.1,0.8-0.8c0-0.6,0-1.1,0-1.7
		c0-1,0-1-1-1C58.7,66.5,47.6,66.5,36.6,66.5z M14.7,58.9c0.4,0,0.5,0.1,0.6,0.1c6.4,0,12.8,0,19.1,0c0.7,0,0.8-0.3,0.8-0.9
		c0-5.2,0-10.4,0-15.6c0-0.2,0.1-0.4-0.2-0.7C28.4,47.5,21.6,53.2,14.7,58.9z M42.8,41.5c0,0.2,0,0.5,0,0.7c0,5.3,0,10.5,0,15.8
		c0,1,0,1,1,1c5.3,0,10.5,0,15.8,0c0.2,0,0.5,0.1,0.7-0.1C54.4,53.1,48.6,47.4,42.8,41.5z M9,63.8c0.4,0.2,0.7,0.1,1,0.1
		c19.8,0,39.6,0,59.4,0c0.3,0,0.5,0,0.8,0c0.1,0,0.3,0,0.4-0.2c0.2-0.6,0.2-1.2,0-1.8c-0.1-0.5-0.7-0.2-1-0.2c-16.5,0-33,0-49.6,0
		c-2.6,0-5.2,0-7.9,0c-0.3,0-0.7,0-0.9,0.2C10.5,62.5,9.8,63.1,9,63.8z M70.7,12c-0.6,0.5-1.1,1-1.6,1.3c-0.6,0.4-0.8,0.9-0.7,1.6
		c0,14.3,0,28.6,0,42.9c0,0.2,0,0.4,0,0.6c0,0.3,0.2,0.5,0.5,0.5c0.2,0,0.5,0,0.7,0c1.1,0,1.1,0,1.1-1.1c0-15,0-29.9,0-44.9
		C70.7,12.7,70.7,12.5,70.7,12z M65.7,58.1c0.1-0.6-0.2-1-0.6-1.4c-6.9-6.9-13.9-13.9-20.8-20.8c-1-1-1-1-2.1-0.1
		c-0.7,0.6-0.7,0.6,0,1.2c7.1,7.1,14.2,14.2,21.4,21.4c0.2,0.2,0.3,0.3,0.5,0.4c0.5,0.3,1,0.3,1.4,0.1
		C65.9,58.8,65.7,58.4,65.7,58.1z M40.1,48.8c0-3.1,0-6.2,0-9.3c0-0.2,0-0.5,0-0.7c-0.1-0.3-0.3-0.6-0.6-0.5c-0.6,0.1-1.5,1.3-1.5,2
		c0,5.8,0,11.7,0,17.5c0,1.3,0,1.3,1.3,1.3c0.9,0,0.9,0,0.9-1C40.1,54.9,40.1,51.9,40.1,48.8z"/>
    </SvgIcon>
  )
}
