import CssBaseline from "@mui/material/CssBaseline";
import Footer from "Components/Footer";
import Header from "Components/Header";
import Routes from "Routes";
import useStyles from "./styles";

function App() {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <div className="App">
        <Header />
        <div className={classes.cont}>
          <Routes />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
