import React from "react";

import { SvgIcon } from "@mui/material";

export default function FinanceIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 83.8 83.1">
      <path d="M83.8,61.5c-0.4,2.1-0.8,4.3-1.7,6.3c-3.7,8.5-10.1,13.6-19.2,15c-4.6,0.7-9.1,0-13.3-1.9
		c-0.5-0.2-0.8-0.3-1.2,0.2c-1.1,1.5-2.6,2-4.4,2c-8.8,0-17.5,0-26.3,0c-3.9,0-7.8,0-11.7,0c-2.5,0-4.4-1.5-5-3.8
		c-0.1-0.4-0.1-0.8-0.1-1.1c0-1.5,0-3,0-4.5c0-1.9,0.9-3.4,2.5-4.3c0.5-0.3,0.6-0.5,0.3-1c-0.3-0.4-0.4-1-0.4-1.5c0-1.8,0-3.5,0-5.3
		c0-0.5,0.1-1,0.3-1.4c0.1-0.4,0.1-0.6-0.3-0.8C1,58.5-0.1,56.7,0,54.2c0-1.4,0-2.7,0-4.1c0-2.6,2.1-4.7,4.7-4.7c0.6,0,1.3,0,1.9,0
		c0.4,0,0.4-0.1,0.4-0.5c-0.6-2.7-0.6-5.4,0-8.1c0.1-0.6-0.2-0.5-0.5-0.5c-2.3-0.3-3.9-1.7-4.4-3.8C2,32.2,2,31.8,2,31.5
		c0-1.6,0-3.2,0-4.7c0-2.6,2.1-4.7,4.7-4.8c0.7,0,1.5-0.1,2.2,0c0.8,0.1,1.2-0.2,1.5-0.9c3-5.3,6.1-10.6,9.2-15.9
		c1-1.7,2.5-2.8,4.5-3.1c2.8-0.5,5.2,0.7,6.7,3.3c1.5,2.6,3,5.2,4.5,7.9c1.5,2.7,3.1,5.3,4.6,8c0.4,0.6,0.8,0.9,1.5,0.8
		c1.4-0.1,2.8,0,4.2,0c2.6,0,4.7,1.9,4.8,4.5c0.1,2.2,0.4,4.5-0.3,6.7c-0.1,0.3,0,0.4,0.3,0.4c1.2,0.1,2.2,0.5,3.1,1.3
		c0.3,0.2,0.6,0.1,0.9,0.1c6.3-1.3,12.2-0.3,17.8,3.1c0.4,0.2,0.8,0.5,1.1,0.8c0.5,0.5,0.6,1,0.2,1.6c-0.3,0.6-0.9,0.7-1.6,0.5
		c-0.3-0.1-0.6-0.3-0.8-0.5C58.2,32.1,40.5,40,38.2,55.5c-1.8,11.9,6.3,22.9,18.2,24.7c11.7,1.8,23-6.6,24.6-18.3
		c0.9-6.7-0.9-12.6-5.3-17.6c-0.1-0.1-0.1-0.1-0.2-0.2c-0.6-0.8-0.7-1.6,0-2.1c0.6-0.5,1.3-0.4,2,0.3c1.2,1.3,2.3,2.8,3.2,4.4
		c1.6,2.8,2.6,5.8,3,8.9c0,0.1,0.1,0.3,0.1,0.4C83.8,57.9,83.8,59.7,83.8,61.5z M11.3,33.6c1.5,0,3,0,4.5,0c0.3,0,0.9,0.1,0.8-0.5
		c0-0.6-0.5-0.4-0.9-0.4c-0.5,0-0.9,0-1.4,0c-0.6,0-1.2-0.1-1.7-0.4c-0.7-0.3-1-0.8-0.8-1.5c0.1-0.7,0.8-1.1,1.5-1
		c1.4,0.3,2.7,0.2,4.1,0.2c1.6,0,2,0.5,2,2.1c0,5.9,0,11.9,0,17.8c0,1.6,0.7,2.3,2.3,2.3c2.2,0,4.5,0,6.7,0c2,0,2.6-0.6,2.6-2.6
		c0-5.7,0-11.3,0-17c0-0.4,0-0.7,0-1.1c0.1-1.1,0.5-1.5,1.5-1.5c0.9-0.1,1.9-0.1,2.9,0c3,0.2,4.9-3,3.4-5.7
		c-0.4-0.8-0.9-1.5-1.3-2.3c-2.9-5.1-5.9-10.2-8.8-15.3c-1.7-3-5.2-3-6.9,0c-3.3,5.7-6.7,11.5-10,17.3c-0.5,0.9-0.9,1.9-0.7,3
		c0.1,0.8-0.3,1.5-1.1,1.6c-0.7,0.1-1.3-0.4-1.5-1.2c-0.1-0.7-0.2-1.3-0.1-2c0.1-0.3,0.1-0.7-0.3-0.8c-1.8-0.6-3.5,0.4-3.6,2.3
		c-0.1,1.4-0.1,2.8,0,4.2c0.1,1.6,1,2.4,2.5,2.4C8.5,33.7,9.9,33.6,11.3,33.6z M25.1,80.4c6.3,0,12.6,0,19,0c0.7,0,1.3-0.1,1.8-0.5
		c0.5-0.4,0.6-0.7,0-1.1c-2.7-1.8-4.9-4.1-6.7-6.8c-0.3-0.4-0.6-0.6-1.1-0.6c-10.7,0-21.3,0-32,0c-1.7,0-2.6,0.9-2.7,2.6
		c0,1.2,0,2.4,0,3.6c0,2,0.9,2.8,2.8,2.8C12.5,80.4,18.8,80.4,25.1,80.4z M22.6,68.8c4.6,0,9.3,0,13.9,0c1,0,1,0,0.6-0.9
		c-1-2.4-1.6-4.8-1.7-7.4c0-0.7-0.3-0.8-0.9-0.8c-8.7,0-17.3,0-26,0c-1.6,0-2.5,0.8-2.6,2.5c0,1.3,0,2.7,0,4
		c0.1,1.6,0.9,2.4,2.5,2.5c0.3,0,0.5,0,0.8,0C13.7,68.8,18.1,68.8,22.6,68.8z M19.7,57c4.9,0,9.9,0,14.8,0c0.5,0,0.9,0,0.9-0.7
		c0.3-2.7,1-5.3,2.2-7.8c0.2-0.4,0.1-0.5-0.4-0.5c-1,0-2,0-2.9,0c-0.5,0-0.7,0.2-0.6,0.7c0,0.6,0,1.2,0,1.8c-0.1,2.3-1.7,4.2-4,4.3
		c-2.9,0.2-5.9,0.2-8.8,0c-2-0.1-3.6-1.6-4-3.5c-0.2-0.9-0.1-1.7-0.1-2.6c0-0.5-0.2-0.7-0.7-0.7c-3.6,0-7.2,0-10.8,0
		c-1.8,0-2.6,1-2.6,2.7c0,1.2,0,2.3,0,3.5c0,1.9,0.9,2.8,2.8,2.8C10.1,57,14.9,57,19.7,57z M49.9,36.3c-5.1,0-10.3,0-15.4,0
		c-0.6,0-0.8,0.2-0.8,0.8c0,2.5,0,5,0,7.5c0,0.6,0.2,0.8,0.8,0.8c1.3,0,2.7,0,4,0c0.6,0,1-0.2,1.4-0.7c1.1-1.6,2.4-3.1,3.9-4.3
		C45.6,38.7,47.7,37.4,49.9,36.3z M40,33.6C40,33.6,40,33.6,40,33.6c1.9,0,3.8,0,5.6,0c1.2,0,2.2-0.9,2.2-2.1c0.1-1.5,0.1-3.1,0-4.7
		c0-1.2-1-2.1-2.2-2.2c-1,0-2,0-3,0c-0.6,0-0.8,0.1-0.8,0.8c0.4,3.5-1.8,6.5-5.2,7.2c-0.7,0.1-1.5,0-2.2,0.1c-0.3,0-0.9-0.2-0.8,0.5
		c0,0.5,0.5,0.4,0.8,0.4C36.3,33.6,38.2,33.6,40,33.6z M16.7,40.9c0-1.2,0-2.5,0-3.7c0-0.7-0.2-0.9-0.9-0.9c-1.4,0-2.8,0-4.2,0
		c-1.5,0-2.4,0.9-2.4,2.4c0,1.4,0,2.8,0,4.2c0,1.6,0.9,2.5,2.5,2.5c1.4,0,2.8,0,4.2,0c0.6,0,0.8-0.2,0.8-0.8
		C16.7,43.3,16.7,42.1,16.7,40.9z"/>
      <path d="M59.6,41.9c9.3,0,16.9,7.6,16.8,16.8c-0.1,9.9-8.1,16.9-16.9,16.8c-9.3,0-16.9-7.6-16.8-16.9
		C42.8,48.7,51.1,41.8,59.6,41.9z M59.6,72.9c7.9,0,14.2-6.3,14.2-14.1c0-7.5-5.9-14.3-14.2-14.3c-8.4,0-14.2,6.8-14.2,14.2
		C45.4,66.6,51.7,72.9,59.6,72.9z"/>
      <path d="M63.6,11.5c0,1.4-0.4,2.3-1.1,3c-1,0.9-2,1.8-3.1,2.7c-1.7,1.4-3.8,1.2-5.3-0.4c-1.5-1.5-1.5-3.6-0.1-5.2
		c0.8-0.9,1.6-1.8,2.4-2.7c1.3-1.4,2.8-1.8,4.3-1.2C62.3,8.4,63.6,10.2,63.6,11.5z M59.2,10.1c-0.4,0-0.7,0.2-1,0.5
		c-0.8,0.9-1.5,1.8-2.3,2.6c-0.5,0.6-0.5,1.2,0.1,1.8c0.5,0.5,1.1,0.6,1.7,0c0.9-0.7,1.7-1.5,2.6-2.3c0.4-0.4,0.8-0.8,0.5-1.4
		C60.5,10.8,60,10.2,59.2,10.1z"/>
      <path d="M71.1,25.1c0,1.8-1.1,3.3-2.7,3.7c-1.6,0.4-3.2,0.7-4.8,0.9c-1.7,0.2-3.3-1.1-3.8-2.9c-0.5-1.9,0.2-3.8,2-4.5
		c1.5-0.7,3-1.2,4.6-1.6c1.5-0.4,3,0.3,3.8,1.6C70.8,23.2,70.9,24.2,71.1,25.1z M63.8,27.1c1.1-0.2,2.5-0.5,3.8-0.8
		c0.7-0.2,0.9-0.8,0.7-1.7c-0.2-1-0.7-1.5-1.4-1.3c-1.4,0.4-2.7,0.8-4,1.4c-0.6,0.2-0.8,0.8-0.6,1.4C62.5,26.7,62.9,27.1,63.8,27.1z
		"/>
      <path d="M41.3,7.4c0.3-1.6,0.5-3.4,1.1-5.1c0.6-1.8,2.4-2.6,4.3-2.3c1.8,0.4,3.3,1.2,3.7,3.2c0.1,0.6,0.1,1.1-0.1,1.6
		c-0.5,1.4-0.9,2.9-1.5,4.3c-0.7,1.7-2.4,2.5-4.2,2.1C42.6,10.9,41.4,9.5,41.3,7.4z M45.3,8.8c0.6,0,1-0.3,1.2-0.9
		c0.4-1.1,0.7-2.2,1.1-3.2c0.3-1.1,0.1-1.5-1.1-1.8c-1.1-0.3-1.6-0.1-1.9,1C44.4,4.9,44.2,6,44,7.1C43.8,8.1,44.4,8.7,45.3,8.8z"/>
      <path d="M58.1,62.2c0-0.8,0-1.7,0-2.5c0-0.5-0.2-0.9-0.7-1c-0.6-0.2-1.1-0.5-1.6-0.8c-3.2-1.7-2.8-5.4-0.6-7.3
		c0.6-0.5,1.2-0.9,2-1.1c0.7-0.1,1-0.4,1-1.2c0-0.6,0.3-1.1,0.9-1.3c0.9-0.3,1.7,0.3,1.7,1.3c0,0.7,0.2,1,0.9,1.1
		c0.9,0.1,1.7,0.5,2.5,1c0.7,0.5,0.9,1.2,0.5,1.8c-0.4,0.6-1.1,0.8-1.9,0.4c-2-1-2-1-2,1.2c0,0.8,0,1.7,0,2.5c0,0.5,0.2,0.8,0.7,0.9
		c0.7,0.2,1.5,0.5,2.1,0.8c3,1.7,3.6,5.2,1.4,7.8C64,67,62.9,67.7,61.5,68c-0.5,0.1-0.7,0.4-0.7,0.9c0,0.1,0,0.3,0,0.4
		c-0.1,0.8-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.5-1.3-1.3c0-1-0.4-1.2-1.3-1.3c-1.1-0.1-2.1-0.5-3.1-1c-0.9-0.5-1.2-1.2-0.8-1.9
		c0.4-0.7,1.1-0.9,2-0.5c0.8,0.4,1.5,0.7,2.4,0.8c0.6,0.1,0.7-0.1,0.7-0.7C58.1,64,58.1,63.1,58.1,62.2z M60.8,62.6
		c0,0.8,0,1.5,0,2.3c0,0.3,0.1,0.5,0.5,0.4c1.2-0.3,2.4-1.7,2.4-2.9c0-1.2-1-2.2-2.3-2.5c-0.4-0.1-0.5,0-0.5,0.4
		C60.8,61.1,60.8,61.9,60.8,62.6z M58.1,54c0-0.5,0-1,0-1.5c0-0.4-0.1-0.6-0.5-0.4c-0.8,0.3-1.6,1.3-1.5,2.1c0,0.7,0.8,1.4,1.6,1.7
		c0.4,0.1,0.4-0.1,0.4-0.4C58.1,55,58.1,54.5,58.1,54z"/>
    </SvgIcon>
  )
}
