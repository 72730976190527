import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    sectionContact: {
      paddingTop: "calc(3.8rem + 3rem + 64px)",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "calc(3.8rem + 64px)",
      },
    },
    boxContact: {
      padding: "4.2rem 6%",
      borderRadius: 45,
      margin: ".5rem 0 3rem",
    },
    contMap: {
      height: "25rem",
    },
    loaderMap: {
      position: "relative",
      width: "100%",
      height: "100%",
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textCapion: {
      paddingBottom: 16,
      position: "relative",
      display: "flex",
      justifyContent: "flex-start",
      "&::after": {
        content: "''",
        position: "absolute",
        bottom: 0,
        width: 94,
        height: ".15rem",
        background: theme.palette.secondary.main,
      },
    },
    error: {
      margin: '2px 2px',
      fontSize: '12px',
      color: 'red',
      maxWidth: '290px',
    },
    exito: {
      textAlign: 'center',
      fontSize: '14px',
      paddingTop: '20px',
      color: '#02a802',
    },
    formulario: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px'
    },
    customInputs: {
      backgroundColor: 'transparent',
      borderRadius: '18px',
      minWidth: '300px',
      height: '32px',
      padding: '0 10px'
    }
  })
);