import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    liabilities: {
      padding: "4.5rem 0",
    },
    icons: {
      marginBottom: "1.5rem",
    },
  })
);