import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    header: {
      minHeight: "70px !important",
      [theme.breakpoints.down("md")]: {
        minHeight: "55px !important",
      },
    },
    headerTop: {
      padding: ".5rem 0",
      height: "3.8rem",
      display: "flex",
      width: "100%",
      alignItems: "center",
      overflow: "hidden",
    },

    hasSubMenu: {
      position: "relative",
    },
    textAddress: {
      "& h5": {
        fontWeight: 400,
        marginBottom: 0,
        letterSpacing: ".07rem",
        [theme.breakpoints.down("md")]: {
          fontSize: 11,
        },
      },
      "& a": {
        marginLeft: ".6rem",
        fontFamily: `"Helvetica Neue", sans-serif`,
        fontWeight: "bold",
        transition: "all .4s",
        width: "fit-content",
        [theme.breakpoints.down("md")]: {
          display: "block",
          marginLeft: 0,
          marginTop: ".15rem",
        },
        "& svg": {
          marginRight: ".1rem",
          fontSize: ".9rem",
          marginBottom: "-.1rem",
          [theme.breakpoints.down("md")]: {
            fontSize: ".8rem",
          },
        },
        "&:hover": {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    iconLinkedin: {
      "& svg": {
        color: "#5e5e5e",
        fontSize: "6.2rem",
        height: "auto",
        transition: "all .4s ease",
      },
      "&:hover": {
        "& svg": {
          color: theme.palette.primary.light,
        },
      },
    },
    headerInfo: {
      height: "3rem",
      display: "flex",
      alignItems: "center",
      padding: ".5rem 0",
      overflow: "hidden",
      marginTop: "0",
      transition: "all .2s ease-in-out",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
      "& h5": {
        textShadow: `0 0 ${theme.palette.primary.main}`,
        letterSpacing: ".08rem",
      },
      "& a": {
        textShadow: `0 0 ${theme.palette.secondary.main}`,
        transition: "all .4s",
        "&:hover": {
          textShadow: `0 0  1px ${theme.palette.secondary.main}`,
        },
      },
      "&.hiddenHeader": {
        height: 0,
        padding: 0,
        transition: "all .2s ease-in",
      },
    },
    logo: {
      display: "block",
      "& img": {
        width: "8rem",
        transition: "all 0.5s ease-out",
      },
    },
    contHeader: {
      transition: "background-color .1s ease-out",
    },
    navMenu: {
      display: "flex",
      alignItems: "center",
      padding: 0,
      margin: 0,
      "& li": {
        listStyle: "none",
        padding: ".2rem 1.2rem",
        textAlign: "center",
        "& a": {
          textDecoration: "none",
          textTransform: "uppercase",
          letterSpacing: 0,
          fontSize: 14,
          position: "relative",
          lineHeight: 1.2,
          padding: ".2rem 0",
          display: "flex",
          justifyContent: "center",
          color: "inherit",
          "&:hover": {
            "&::after": {
              width: "85%",
            },
          },
          "&.linkContacto": {
            background: theme.palette.secondary.main,
            padding: "0.4rem 0.5rem",
            borderRadius: 10,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            zIndex: 1,
            transition: ".4s all",
            border: `.1rem solid ${theme.palette.secondary.main}`,
            color: theme.palette.primary.contrastText,
            "&:before": {
              content: "''",
              position: "absolute",
              width: 0,
              height: "100%",
              bottom: 0,
              left: "-40%",
              zIndex: - 1,
              transition: "0.4s all ease-in",
              transform: "skew(50deg)",
              transformOrigin: "top left",
            },
            "&:hover": {
              color: theme.palette.secondary.main,
              "&::before": {
                width: "140%",
                background: theme.palette.primary.contrastText,
              },
            },
          },
          "&.active-link": {
            "&::after": {
              width: "85%",
            },
          },
        },
        "&:not(:last-child)": {
          "& a::after": {
            content: "''",
            background: theme.palette.primary.contrastText,
            position: "absolute",
            width: 0,
            bottom: 0,
            transition: ".3s",
            height: ".1rem",
          },
        },
        "&:last-child": {
          padding: "0 0 0 1rem",
        },

      },
      "& $hasSubMenu": {
        padding: 0,
        "& > div": {
          display: "block",
          width: "100%",
          height: "100%",
          padding: ".4rem 1.2rem",
          position: "relative",
          textTransform: "uppercase",
          textDecoration: "none",
          letterSpacing: 0,
          fontSize: 14,
          lineHeight: 1.2,
          color: "inherit",
          cursor: "pointer",
        },
        "& > a::after": {
          display: "none",
        },
        "&:hover": {
          background: theme.palette.secondary.main,
          color: theme.palette.primary.contrastText,
          "& $subMenu": {
            marginTop: 0,
            visibility: "visible",
            opacity: 1,
            transition: "0.4s all, opacity 0.2s ease",
          },

        },
      },
      "&.changeHover": {
        "& li": {
          "&:not(:last-child)": {
            "& a::after": {
              background: theme.palette.secondary.main,
            },
          },
        },
      },

    },
    subMenu: {
      overflow: "hidden",
      marginTop: ".5rem",
      width: "100%",
      boxsizing: "border-box",
      position: "absolute",
      opacity: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: ".5rem .5rem .8rem",
      background: theme.palette.secondary.main,
      transition: "0.2s all",
      visibility: "hidden",
      left: 0,
      "& li": {
        textAlign: "center",
        padding: ".2rem 0",
        "&:last-child": {
          padding: ".2rem 0",
        },
        "& a": {
          textTransform: "capitalize",
          color: theme.palette.primary.contrastText,
          "&::after": {
            background: `${theme.palette.primary.contrastText} !important`,
          },
        },
      },
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        "z-index": "1 !important",
      },
    },
    drawerWrapper: {
      minWidth: 320,
      padding: "1.4rem 1.6rem",
    },
    closeMenuIcon: {
      marginRight: "-12px !important",
    },
    drawerStyle: {
      background: theme.palette.primary.main,
    },
    menuResponsive: {
      "& li": {
        listStyle: "none",
        padding: ".45rem 0",
        "& a": {
          textDecoration: "none",
          letterSpacing: 0,
          position: "relative",
          lineHeight: 1.2,
          padding: ".2rem 0",
          display: "flex",
          color: "inherit",
          "&:hover": {
            "&::after": {
              width: "100%",
            },
          },
          "&.linkContacto": {
            background: theme.palette.secondary.main,
            marginTop: 8,
            padding: "0.5rem 1.5rem",
            borderRadius: 10,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            zIndex: 1,
            transition: ".4s all",
            border: `.1rem solid ${theme.palette.secondary.main}`,
            color: theme.palette.primary.contrastText,
            "&:before": {
              content: "''",
              position: "absolute",
              width: 0,
              height: "100%",
              bottom: 0,
              left: "-40%",
              zIndex: - 1,
              transition: "0.4s all ease-in",
              transform: "skew(50deg)",
              transformOrigin: "top left",
            },
            "&:hover": {
              color: theme.palette.secondary.main,
              "&::before": {
                width: "140%",
                background: theme.palette.primary.contrastText,
              },
            },
          },
          "&.active-link": {
            "&::after": {
              width: "100%",
            },
          },
        },
        "&:not(:last-child)": {
          "& a::after": {
            content: "''",
            background: theme.palette.secondary.dark,
            position: "absolute",
            width: 0,
            bottom: 0,
            transition: ".3s",
            height: ".1rem",
          },
        },
      },
    },
  })
);