import * as React from "react";

import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Img } from "react-image";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import bannerOne from "./../../Assets/images/banner_us.jpg";
import logoGrupoInca from "./../../Assets/images/logo_grupo_inca_black.png";
import useStyles from "./styles";

export default function BannerUs() {
  const classes = useStyles();

  return (
    <Box className={classes.contBannerUs}>
      <Box className={classes.bannerUs} sx={{ background: `url(${bannerOne})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "top center" }}>
        <Box className={classes.bannerCaption} sx={{ display: "flex", alignItems: "center" }}>
          <Container >
            <Grid container alignItems="flex-start" justifyContent="center">
              <Grid item xs={12} sm={10} md={7}>
                <Typography variant="subtitle1" component="div" sx={{ color: "primary.main", textAlign: "center", fontSize: 32, marginBottom: "1.8rem" }} className={classes.textCapion}>
                  Contamos con el respaldo de uno de los grupos más importantes del sur del país
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography sx={{ letterSpacing: "normal" }}>
                    Somos parte de:
                  </Typography>
                  <Link href="https://grupoinca.com/es/" target="_blank" sx={{ display: "block" }}>
                    <Img src={logoGrupoInca} className={classes.logo} />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}