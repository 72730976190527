import * as React from "react";

import { Dialog, IconButton, useMediaQuery } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player/youtube";
import useStyles from "./styles";
import { useTheme } from "@mui/material/styles";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  openVideo: boolean;
}
export default function VideoModal({open, onClose, openVideo}: ModalProps) {
  const classes = useStyles();
  const theme = useTheme();
  const matchLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      classes={{paper: classes.modalPaper}}
      maxWidth="lg"
    >
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: -19,
            top: -19,
            color: (theme) => theme.palette.primary.contrastText,
            background: (theme) => theme.palette.secondary.main,
            borderRadius: 0,
            padding: ".3rem",
          }}
          className={classes.btnClose}
        >
          <CloseIcon />
        </IconButton>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=8P0JWig9I9E"
        width="100%"
        height={matchLg ? "700px" : "600px"}
        playing={openVideo}
      />
    </Dialog>
  );
}
