import BannerModelThree from "Components/BannerModelThree";
import CardsPortafolio from "Components/CardsPortafolio";
import SectionGrayContact from "Components/SectionGrayContact";
import * as React from "react";
import { useParams } from "react-router-dom";

const portafolioData = require("./../../_portafolio.json");

const loadImage = (imageName: string) => (require(`./../../Assets/images/${imageName}`).default);


interface ISlidesCarrousel {
  title: string;
  slug: string;
  subTitle: string;
  image: string;
  imageBanner: string;
  itemPortafolio: {
    title: string;
    content: string;
    image: string;
  }
}

export default function InternalPortafolioPage() {
  const { slug } = useParams<{ slug: string }>();
  console.log(slug);
  
  const portafolio: ISlidesCarrousel = findPortafolioBySlug(slug);
  console.log(portafolio);
  
  return (
    <>
      <BannerModelThree image={loadImage(portafolio.imageBanner)} title={portafolio.subTitle} />
      <CardsPortafolio itemsPortafolio={portafolio.itemPortafolio} />
      <SectionGrayContact />
    </>
  );
}
function findPortafolioBySlug(slug: string) {
  return portafolioData.find((o: { slug: string; }) => o.slug === slug);
}
