import * as React from "react";

import { Box, Container } from "@mui/material";

import { Img } from "react-image";
import LogoCapeco from '../../Assets/images/capeco.png';
import LogoCCIA from '../../Assets/images/ccia.png';
import LogoCertidicacion from '../../Assets/images/certidicacion.png';
import LogoInacal from '../../Assets/images/inacal.png';
import LogoIco_uno from '../../Assets/images/ico_uno.png';
import LogoIco_dos from '../../Assets/images/ico_dos.png';
import LogoIco_tres from '../../Assets/images/ico_tres.png';
import LogoIco_cuatro from '../../Assets/images/ico_cuatro.png';


import useStyles from "./styles";

interface IRecognitionsProps {
  bg: string;
}
export default function Recognitions({ bg }: IRecognitionsProps) {
  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: bg }} className={classes.recognitions}>
      <Container>
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <Box className={classes.itemLogo}>
            <Img src={LogoIco_uno} />
          </Box>
          <Box className={classes.itemLogo}>
            <Img src={LogoIco_dos} />
          </Box>          
          <Box className={classes.itemLogo}>
            <Img src={LogoIco_tres} />
          </Box>
          <Box className={classes.itemLogo}>
            <Img src={LogoIco_cuatro} />
          </Box>
          <Box className={classes.itemLogo}>
            <Img src={LogoInacal} />
          </Box>
          <Box className={classes.itemLogo}>
            <Img src={LogoCertidicacion} />
          </Box>
          <Box className={classes.itemLogo}>
            <Img src={LogoCapeco} />
          </Box>
          <Box className={classes.itemLogo}>
            <Img src={LogoCCIA} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
