import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    contSectionPortafolio: {
      margin: "4rem 2rem",
    },
    contCarouselService: {
      padding: "2rem 0",
    },
    contItemCarousel: {
      height: "22rem",
      padding: "0 7px",
      overflow: "hidden",
      textDecoration: "none",
    },
    itemCarousel: {
      position: "relative",
      padding: "2rem 8%",
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, .84) 18%, transparent 50%)",
        zIndex: 1,
        transition: "background-image .4s",
      },
    },
    titleDescription: {
      paddinBottom: 0,
    },
    description: {
      height: 0,
      marginBottom: "-100%",
      opacity: 0,
      transition: "margin-bottom .3s cubic-bezier(0.25, 0.46, 0.45, 0.94)",
      "& p": {
        marginBottom: ".4rem",
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
    },
    carouselService: {
      "& .slick-slide": {
        "& > div": {
          display: "flex",
        },
      },
      "& .slick-arrow": {
        display: "flex",
        position: "absolute",
        zIndex: 2,
        width: "auto",
        height: "auto",
        "& buttom": {
          padding: "0 !important",
        },
        "& svg": {
          color: theme.palette.primary.contrastText,
          fontSize: "2.5rem",
        },
        "&::before": {
          display: "none",
        },
        "&.slick-next": {
          right: 5,
        },
        "&.slick-prev": {
          left: 5,
        },
      },
      "& .slick-center": {
        "& $description": {
          height: "auto",
          opacity: 1,
          marginBottom: "0",
        },
        "& $itemCarousel": {
          padding: "3rem 8%",
          "&::after": {
            backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, .8) 50%, transparent 95%)",
          },
        },
        "& $titleDescription": {
          paddingBottom: 20,
        },
      },
    },
  })
);