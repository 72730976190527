import * as React from "react";

import { Box } from "@mui/material";
import { Img } from "react-image";

import useStyles from "./styles";

interface IPictureAloneProps {
  picture: string;
}
export default function PictureAlone({picture}: IPictureAloneProps) {
  const classes = useStyles();
  return (
    <Box className={classes.contImage}>
      <Img src={picture} />
    </Box>
  );
}
