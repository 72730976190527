import React from "react";

import { SvgIcon } from "@mui/material";

export default function HandsIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 121.8 120.3">
      <path d="M0,52.8C6.4,44.9,12.8,37,19.2,29.1c1.1-1.4,2.2-2.7,3.3-4.1c1.4-1.8,2.5-1.9,4.4-0.7
		c3.8,2.4,7.7,4.9,11.5,7.3c0.9,0.6,1.9,1,2.9,1.2c3.7,1,7.3,2,11,3c0.7,0.2,1.4,0.2,2.2,0c3.6-1.1,7.2-2.1,10.7-3.1
		c1.4-0.4,2.8-0.6,4.2-0.6c4.6,0,9.2,0,13.8,0c0.9,0,1.7-0.2,2.5-0.7c3.8-2.4,7.5-4.8,11.3-7.2c1.8-1.2,3-1,4.3,0.7
		c6.6,8.5,13.1,17,19.6,25.5c1.3,1.7,1.2,2.9-0.4,4.2c-3.4,2.9-6.8,5.8-10.2,8.8c-0.6,0.5-1.2,1.1-1.5,1.9
		c-2.1,4.5-4.3,9.1-6.5,13.6c-0.4,0.9-0.5,1.7-0.1,2.7c2.3,5.3-1.1,11.2-6.8,11.9c-0.6,0.1-0.9,0.2-1,0.8c-0.7,4.4-3.2,7.1-7.7,7.7
		c-0.8,0.1-0.8,0.6-0.9,1.2c-0.7,3.7-2.7,6.3-6.4,7.1c-1.5,0.3-2,0.9-2.4,2.4c-1.4,6-8.5,8.5-13.4,4.7c-1-0.8-1.5-0.8-2.4,0.1
		c-5,4.8-12.5,2.7-14.4-3.9c-0.2-0.8-0.6-1-1.3-1c-4.5-0.1-7.7-2.8-8.5-7.3c-0.2-0.9-0.5-1.3-1.5-1.5c-4.3-0.7-7.2-4.2-7.2-8.5
		c0-0.8-0.2-1.1-1-1.3C21.4,92.7,19,86,22.4,81c0.5-0.7,0.5-1.2,0-1.9c-3.1-4.1-6.2-8.3-9.3-12.5c-1.3-1.8-3.2-3-4.8-4.4
		C5.9,60.2,3.4,58.1,1,56c-0.4-0.3-0.6-0.7-1-1C0,54.3,0,53.5,0,52.8z M41.9,81.4c4.3-0.4,8.6,3.1,9,7.4c0.1,0.8,0.3,1.3,1.2,1.2
		c0.3,0,0.6,0.1,0.9,0.2c4,1,6.5,4.3,6.4,8.7c0,1,0.2,1.6,1.2,1.8c0.8,0.2,1.5,0.6,2.2,1.1c3.1,2.2,4.3,6.1,3.1,9.9
		c-0.1,0.4-0.4,0.8,0,1.3c1,1.1,2.6,1.5,4,1c1.4-0.5,2.2-1.7,2.2-3.2c0-1.2-0.5-2-1.3-2.8c-1.8-1.8-3.6-3.6-5.3-5.4
		c-0.7-0.7-1-1.6-0.6-2.6c0.3-0.9,1-1.5,2-1.7c1-0.2,1.8,0.3,2.4,1c1.8,1.8,3.6,3.7,5.5,5.5c1.1,1.1,2.5,1.3,4,0.6
		c1.3-0.6,2-1.8,1.9-3.4c0-1.1-0.6-1.9-1.4-2.7c-3.3-3.3-6.6-6.6-9.8-10c-1.2-1.2-1.2-2.6-0.1-3.7c1-1,2.4-0.9,3.6,0.2
		c0.3,0.3,0.6,0.6,0.9,0.9c3,3,6,6.1,9,9.1c1,1,2.1,1.6,3.5,1.3c2.9-0.7,3.7-3.9,1.6-6.1c-4.9-4.9-9.7-9.9-14.6-14.8
		c-0.4-0.4-0.7-0.7-1-1.2c-0.6-1-0.4-2.3,0.5-3.1c0.9-0.8,2.2-0.9,3.1-0.1c0.4,0.3,0.7,0.6,1,1c4.8,4.9,9.6,9.7,14.5,14.6
		c1.7,1.7,3.8,1.7,5.3,0.2c1.4-1.4,1.3-3.6-0.3-5.2C87.5,73.2,78.4,64.1,69.3,55c-0.5-0.5-1.1-0.8-1.9-0.9c-2.6-0.2-5.3-0.3-7.9-0.5
		c-1.2-0.1-1.8,0.3-2.3,1.3c-1.7,3.1-3.4,6.2-5.2,9.2c-1.7,2.9-4.4,4.3-7.8,4.2c-3.5-0.2-6-2-7.4-5.3c-1.1-2.5-0.9-5,0.4-7.4
		c2.8-5,5.7-10.1,8.5-15.1c0.5-0.9,0.3-1.2-0.6-1.4c-1.5-0.3-2.9-0.7-4.3-1.2c-0.9-0.3-1.4-0.1-2,0.6c-6.8,8.3-13.6,16.6-20.4,24.9
		c-0.6,0.7-0.6,1.1,0,1.8c2.6,3.4,5.2,6.9,7.7,10.4c0.5,0.7,0.9,0.7,1.6,0.2c4.9-3.1,11-1,12.8,4.5C40.7,81.3,41.1,81.7,41.9,81.4z
		 M75.7,37.2c-2.3,0-4.7,0-7,0c-0.6,0-1.1,0.1-1.6,0.2c-4.3,1.3-8.6,2.7-13,3.7c-2.6,0.6-4.2,1.9-5.4,4.3c-2.2,4.3-4.7,8.4-7.1,12.7
		c-0.7,1.3-0.6,2.5,0.2,3.7c0.8,1.1,2,1.6,3.3,1.4c1.3-0.2,2.2-1,2.8-2.1c2.1-3.7,4.1-7.3,6.2-11c0.7-1.3,1.8-1.8,3.3-1.7
		c3.7,0.3,7.3,0.6,11,0.8c1.6,0.1,2.8,0.6,3.9,1.7c8.2,8.2,16.4,16.4,24.6,24.6c1,1,1,1,1.6-0.3c1.8-3.7,3.6-7.5,5.4-11.2
		c0.3-0.6,0.3-1-0.1-1.5C97.4,54.3,91,46.2,84.6,38c-0.5-0.6-1-0.8-1.7-0.8C80.5,37.2,78.1,37.2,75.7,37.2z M34.6,35.5
		c0.1-0.4-0.3-0.5-0.5-0.7c-2.7-1.7-5.3-3.4-8-5.1c-0.6-0.4-0.9-0.2-1.3,0.3c-6.1,7.6-12.2,15.2-18.3,22.7c-0.5,0.7-0.4,1,0.2,1.6
		c2.2,1.9,4.5,3.8,6.7,5.7c0.6,0.5,0.9,0.4,1.3-0.1c6.5-7.9,13-15.9,19.5-23.8C34.4,35.9,34.5,35.7,34.6,35.5z M98.1,29.5
		c-0.1,0.1-0.3,0.1-0.5,0.3c-2.7,1.7-5.4,3.4-8.1,5.1c-0.6,0.4-0.4,0.7-0.1,1.1c0.8,1.1,1.7,2.1,2.5,3.2c4.9,6.3,9.9,12.6,14.8,18.8
		c0.4,0.6,0.8,1.2,1.7,0.4c2.2-1.9,4.4-3.8,6.7-5.7c0.6-0.5,0.6-0.9,0.1-1.5c-5.5-7.1-10.9-14.2-16.4-21.2
		C98.7,29.8,98.5,29.6,98.1,29.5z M36.8,99.1c1,0,1.7-0.3,2.3-0.8c2-2,4.1-4,6-6c1.3-1.4,1.1-3.5-0.2-4.7c-1.3-1.3-3.3-1.5-4.7-0.2
		c-2.1,1.9-4.1,3.9-6,6c-1,1.1-1.2,2.5-0.4,3.9C34.4,98.5,35.5,99,36.8,99.1z M45.3,107.7c0.8,0,1.6-0.2,2.2-0.7
		c2.1-2,4.2-4,6.1-6.1c1.2-1.3,1-3.5-0.2-4.8c-1.2-1.2-3.4-1.4-4.7-0.1c-2.1,1.9-4.1,3.9-6,6c-1,1.1-1.1,2.5-0.4,3.9
		C42.9,107.1,44,107.6,45.3,107.7z M29.5,89.4c0.4,0,1,0,1.5-0.4c1.7-1.2,3.3-2.6,4.4-4.4c0.9-1.4,0.4-3.3-0.8-4.3
		c-1.3-1.1-3.1-1.2-4.4-0.1c-1.3,1.1-2.4,2.3-3.5,3.5c-1,1.1-1,2.5-0.3,3.8C26.9,88.8,28,89.4,29.5,89.4z M51.6,111.7
		c0,1.4,0.6,2.4,1.8,3.1c1.3,0.7,2.6,0.7,3.7-0.2c1.4-1.1,2.6-2.3,3.7-3.7c0.9-1.2,1-2.5,0.3-3.8c-0.7-1.3-1.9-1.8-3.4-1.7
		c-1.8,0.1-5.8,4-6,5.8C51.6,111.3,51.6,111.5,51.6,111.7z"/>
	<path d="M73.8,28c-2,0-3.2-2-2.2-3.7c0.2-0.4,0.5-0.7,0.8-1C77.5,18.1,82.7,13,87.8,7.8c0.7-0.7,1.5-1.3,2.6-1.1
		c1.9,0.3,2.7,2.4,1.6,4c-0.2,0.3-0.5,0.5-0.7,0.8C86.2,16.6,81.1,21.7,76,26.8C75.3,27.4,74.7,28,73.8,28z"/>
	<path d="M47.7,28c-0.5,0-1.2-0.3-1.7-0.9c-5.3-5.3-10.6-10.6-15.9-16c-1.2-1.2-1.2-2.7-0.1-3.7c1.1-1,2.5-1,3.7,0.2
		c4.1,4.1,8.2,8.2,12.3,12.3c1.3,1.3,2.5,2.5,3.8,3.8c0.8,0.8,0.9,1.7,0.5,2.7C49.8,27.4,49.1,27.9,47.7,28z"/>
	<path d="M63.3,18.8c0,2.1,0,4.3,0,6.4c0,1.7-0.9,2.7-2.4,2.7c-1.5,0-2.5-1-2.5-2.7c0-4.3,0-8.7,0-13
		c0-1.7,1-2.7,2.5-2.7c1.4,0,2.4,1.1,2.4,2.7C63.3,14.4,63.3,16.6,63.3,18.8z"/>
	<path d="M63.3,2.5c0,1.4-1.1,2.5-2.5,2.4c-1.4,0-2.4-1-2.4-2.4c0-1.4,1-2.5,2.4-2.5C62.2,0,63.3,1,63.3,2.5z"/>
    </SvgIcon>
  )
}
