import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    ourClients: {
      padding: "4.5rem 0",
      "& h4": {
        marginBottom: "3rem",
      },
      "& .slick-track": {
        display: "flex",
        alignItems: "center",
      },
      "& .slick-slider": {
        padding: "0 40px",
      },
      "& .slick-arrow": {
        width: "auto",
        height: "auto",
        zIndex: 5,
        "& svg": {
          color: theme.palette.secondary.main,
          fontSize: 20,
        },
        "&::before": {
          display: "none",
        },
        "&.slick-next": {
          right: 0,
        },
        "&.slick-prev": {
          left: 0,
        },
      },
    },
    contLogos: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        maxWidth: 180,
        maxHeight: 75,
        objectFit: "scale-down",
        transition: "all .3s ease",
        "&:hover": {
          opacity: .8,
          filter: "grayscale(1)",
        },
      },
    },
  })
);