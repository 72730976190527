import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import * as React from "react";

import { Box, Button, ButtonProps, Container, Grid, Typography } from "@mui/material";

import { LogoIcon } from "Components/CustomIcons";
import PortafolioModal from "./../PortafolioModal"
import { styled } from "@mui/material/styles";
import useStyles from "./styles";

const loadImage = (imageName: string) => (require(`./../../Assets/images/${imageName}`).default);


interface IItemPortafolio {
  title: string;
  text: string;
  desc: string;
  image: string;
};

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: ".1rem solid #ffffff",
  "&:hover": {
    border: ".1rem solid #ffffff",
    background: "#ffffff",
    color: "#913617",
  },
}));
export default function CardsPortafolio({ itemsPortafolio }: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [idModal, setIdModal] = React.useState<any>()
  const handleClickOpen = (item: any) => {
    setOpen(true)
    return (

      setIdModal(item)
    )
  }
  const handleClose = () => {
    setOpen(false);
    setIdModal({});
  }

  return (
    <>
      <Box className={classes.gridPortafolio}>
        <Container>
          <Grid container spacing={3} alignItems="center">
            {itemsPortafolio.map((item: IItemPortafolio, index: number) => {
              return (
                <>
                  <Grid item xs={12} onClick={() => handleClickOpen(item)}>
                    <Box className={classes.contCardPortafolio} key={index}>
                      <Box sx={{ background: `url(${loadImage(item.image)})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "100%", width: "100%" }} className={classes.cardPortafolio}>
                        <Box sx={{ position: "relative", width: "100%", height: "100%", display: "flex", alignItems: "flex-end", zIndex: 2 }}>
                          <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexWrap: "wrap", maxHeight: "100%", overflow: "hidden", position: "absolute", bottom: 0, left: 0 }}>
                            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} className={classes.titleCard}>
                              <LogoIcon sx={{ color: "secondary.main", fontSize: "1.9rem", marginRight: 1 }} />
                              <Typography variant="h6" component="h6" sx={{ textAlign: "left", color: "primary.contrastText" }}>{item.title}</Typography>
                            </Box>
                            <Box sx={{ width: "100%", paddingLeft: 4.7 }} className={classes.descriptionCard}>
                              <Typography variant="body1" sx={{ color: "primary.contrastText" }}>
                                {item.text}
                              </Typography>
                              <ColorButton variant="outlined" size="medium" color="info" >CONOCE MÁS</ColorButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </>
              )
            })}
          </Grid>
        </Container>
      </Box>
      {
        open && idModal.gallery.length >= 1 ? 
        <PortafolioModal openModal={open} closeModal={handleClose} cont={idModal} />:
        ""
      }
    </>
  );
}