import * as React from "react";
import WorkWithUs from '../../Components/WorkWithUs/index';


export default function WorkWithUsPage() {

  return (
    <>
      <WorkWithUs />
    </>
  );
}
