import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    missionVission: {
      padding: "5rem 0",
      [theme.breakpoints.down("sm")]: {
        padding: "4.5rem 0",
      },
      "& h4": {
        marginBottom: "1rem",
      },
    },
    wrapperMissionVission: {
      background: theme.palette.primary.contrastText,
    },
    carouselMissionVision: {
      paddingBottom: "2.5rem",
      "& .slick-dots": {
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
        "& li": {
          width: "auto",
          height: "auto",
          "& button": {
            width: ".8rem",
            height: ".8rem",
            "&::before": {
              content: "''",
              width: ".8rem",
              height: ".8rem",
              border: `.1rem solid ${theme.palette.secondary.main}`,
              background: "transparent",
              borderRadius: 50,
              opacity: 1,
              overflow: "hidden",
            },
            "&:hover": {
              "&::before": {
                background: "rgba(145 54 23 / .80)",
              },
            },
          },
          "&.slick-active": {
            "& button": {
              "&::before": {
                background: theme.palette.secondary.main,
              },
            },
          },
        },
      },
    },
    listMission: {
      justifyContent: "center",
      padding: 0,
      margin: ".8rem 0",
      "& li": {
        display: "flex",
        justifyContent: "center",
        "&::before": {
          content: "''",
          width: ".45rem",
          height: ".45rem",
          borderRadius: "50px",
          background: theme.palette.secondary.main,
          marginTop: 5,
          marginRight: 8,
        },
      },
    },
  })
);