import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    recognitions: {
      padding: "2.5rem 0",
    },
    itemLogo: {
      padding: ".8rem 1.2rem",
      opacity: .6,
      "& img": {
        height: "2.8rem",
      },
    },
  })
);