import React from "react";

import { SvgIcon } from "@mui/material";

export default function BookIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 163.2 117.3">
      <path d="M161.6,113.9c-1.8,2.2-2.9,2.4-5.4,1.1c-8.7-4.2-17.9-6.8-27.5-8.2c-5.9-0.9-11.9-1.3-17.8-1.3
	c-8.1,0.1-16.1,0.9-23.9,2.8c-3.5,0.9-6.9,0.8-10.4,0c-8.6-2.1-17.4-2.9-26.2-2.7c-15.1,0.3-29.7,3.1-43.4,9.7c-2.8,1.3-5-0.2-5-3.4
	c0-22.7,0-45.5,0-68.2C2,34,2,24.4,2,14.8c0-3.1,1.1-4.3,4.2-4.3c2.5-0.1,5.1-0.1,7.6,0c1.2,0,1.7-0.4,1.6-1.6c-0.1-0.6,0-1.1,0-1.7
	c0.1-1.7,0.9-2.8,2.6-3.3c9.1-2.3,18.2-3.3,27.6-2.6c6.9,0.5,13.7,1.8,20.1,4.5c5.5,2.3,10.5,5.4,14.8,9.6c0.9,0.8,1.4,1,2.4,0.1
	c7.6-7.2,16.7-11.2,26.9-13.1c11.7-2.2,23.2-1.4,34.7,1.3c2.8,0.7,3.6,1.7,3.7,4.7c0,2.3,0,2.3,2.3,2.3c2.4,0,4.8,0.1,7.2,0
	c1.7-0.1,3,0.5,3.9,2C161.6,46.4,161.6,80.1,161.6,113.9z M21.9,50.2c0,12.7,0,25.4,0,38.2c0,2.4,0,2.4,2.3,2.2
	c0.6-0.1,1.2,0,1.9-0.1c9.2-1,18.3-0.9,27.5,0.4c8.3,1.2,16.2,3.5,23.5,7.7c0.4,0.3,0.9,0.8,1.4,0.6c0.6-0.3,0.3-1,0.3-1.6
	c0-13.5-0.1-27-0.2-40.5c0-10.7-0.1-21.4,0-32.1c0-1.7-0.5-2.9-1.7-4c-5.1-4.9-11-8.4-17.7-10.4C47.4,6.9,35.5,7.1,23.6,9.4
	c-1.3,0.3-1.7,0.8-1.7,2.2C21.9,24.4,21.9,37.3,21.9,50.2z M85.1,99.2c0.4-0.1,0.6-0.2,0.7-0.2c9.9-5.9,20.9-8.3,32.2-9.1
	c7.2-0.5,14.4-0.2,21.6,0.6c2.1,0.2,2.1,0.2,2.1-2c0-25.5,0-51.1,0-76.6c0-1.7-0.5-2.3-2.1-2.6C129.9,7.5,120.2,7,110.5,9
	c-9.2,1.9-17.3,5.6-24,12.3c-1,1-1.4,2-1.4,3.4c0,24.2,0,48.4,0,72.6C85.1,97.9,85.1,98.5,85.1,99.2z M101.9,99.4
	c6.7-0.4,13.4-0.5,20.1,0.1c7.5,0.6,14.9,2,22.1,4.1c3.2,0.9,6.4,2,9.6,3.2c1.6,0.6,1.6,0.6,1.6-1.2c0-0.2,0-0.4,0-0.6
	c0-28.5,0-57,0-85.5c0-2.4,0-2.4-2.5-2.4c-0.6,0-1.2,0-1.9,0c-2.9,0-2.6-0.2-2.6,2.7c0,24.6,0,49.1,0,73.7c0,3.3-1.5,4.5-4.7,4.1
	c-5.2-0.7-10.5-1.2-15.8-1.3c-4.8-0.1-9.7,0-14.4,0.6C109.5,97.4,105.6,98.1,101.9,99.4z M61.8,99.4c-4.8-1.6-9.7-2.4-14.7-2.9
	c-9.1-0.8-18.1-0.2-27.1,1.1c-2.5,0.4-4.1-0.8-4.4-3.4c-0.1-0.7,0-1.3,0-2c0-24.1,0-48.3,0-72.4c0-0.5,0-1,0-1.6
	c0-0.9-0.3-1.2-1.2-1.2c-1.4,0-2.8,0.1-4.2,0c-1.2-0.1-1.6,0.4-1.6,1.6c0,29,0,58,0,87.1c0,1.4,0.3,1.6,1.6,1.1
	c7.4-2.9,15-4.9,22.8-6.3c5.9-1,11.9-1.5,18-1.5C54.4,99,58.1,98.9,61.8,99.4z"/>
    </SvgIcon>
  )
}
