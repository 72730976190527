import React from "react";

import { SvgIcon } from "@mui/material";

export default function NoteBookIcon(props: any) {
	return (
		<SvgIcon {...props} viewBox="0 0 96.4 96.4">
			<path d="M18.3,0C40.2,0,62,0,83.9,0c0.6,0.2,1.3,0.4,1.9,0.7c3.3,1.7,4.9,4.3,4.9,8c0,17.5,0,35.1,0,52.6
		c0,0.7,0.1,1.3,0.6,1.9c4,4.6,5.7,9.9,4.9,15.9c-1.3,10-9.7,17.3-19.7,17.3c-18.7,0-37.4,0-56.2,0c-5.2,0-8.9-3.7-8.9-8.9
		c0-2.6,0-2.6-2.7-2.6c-2.5,0-4.6-0.8-6.4-2.5c-1.2-1.2-1.9-2.7-2.4-4.3c0-1.1,0-2.3,0-3.4c0.1-0.3,0.2-0.5,0.3-0.8
		c0.7-2.4,2.2-4.1,4.4-5.2c1.8-0.9,3.7-0.9,5.6-0.8c0.8,0,1.1-0.2,1.1-1.1c0-2.6,0-5.1,0-7.7c0-2.7,0.4-2.4-2.4-2.4
		c-2.4,0-4.6-0.7-6.4-2.3c-1.4-1.2-2.1-2.9-2.6-4.6c0-1.1,0-2.3,0-3.4c0.1-0.3,0.2-0.5,0.3-0.8c0.7-2.4,2.2-4.1,4.4-5.2
		c1.8-0.9,3.7-0.9,5.6-0.8c0.8,0,1.1-0.2,1.1-1.1c0-2.6,0-5.1,0-7.7c0-2.7,0.4-2.4-2.4-2.4c-2.4,0-4.6-0.7-6.4-2.3
		C1.3,25,0.5,23.4,0,21.6c0-1.1,0-2.3,0-3.4c0.1-0.2,0.2-0.4,0.2-0.6c1.4-4.1,4.3-6.2,8.7-6.2c2.5,0,2.5,0,2.5-2.5
		c0-2.4,0.7-4.4,2.2-6.2C14.9,1.3,16.5,0.6,18.3,0z M62.3,90.6c0-0.4-0.2-0.5-0.4-0.7c-4.1-4.7-5.8-10.1-5-16.4
		c1.6-12.5,15.1-20.4,26.8-15.7c1.2,0.5,1.2,0.5,1.2-0.8c0-15.9,0-31.7,0-47.6c0-0.4,0-0.9-0.1-1.3c-0.2-1.4-1-2.2-2.4-2.4
		c-0.5-0.1-0.9-0.1-1.4-0.1c-20,0-40,0-60,0c-0.3,0-0.6,0-0.9,0c-1.8,0.1-2.8,1.1-2.9,2.9c0,0.7,0,1.4,0,2.2c0,0.7,0.2,1.1,0.9,1.5
		c3.1,1.6,4.8,4.1,4.8,7.6c0,3.5-1.7,6.1-4.8,7.7c-0.6,0.3-0.9,0.7-0.9,1.4c0,3.4,0,6.7,0,10.1c0,0.7,0.2,1.1,0.9,1.5
		c3.1,1.6,4.7,4.1,4.8,7.6c0,3.5-1.7,6.1-4.8,7.7c-0.6,0.3-0.9,0.7-0.9,1.4c0,3.3,0,6.6,0,9.9c0,0.9,0.3,1.3,1.1,1.7
		c5.8,3,6.2,11.3,0.7,14.7c-1.4,0.9-1.8,1.7-1.7,3.3c0.1,2.8,1.1,3.8,3.9,3.8c13.4,0,26.8,0,40.2,0C61.6,90.6,61.9,90.6,62.3,90.6z
		 M62.6,76.3c0.1,7.6,5.6,14.1,13.9,14.3c7.3,0.1,14-5.5,14.1-14c0.2-7.4-5.4-14-13.8-14.2C69.3,62.3,62.9,67.9,62.6,76.3z
		 M11.4,22.7c0.8,0,1.7,0,2.5,0c1.8,0,3.1-1.1,3.1-2.7c0-1.6-1.2-2.7-3.1-2.8c-1.7,0-3.4,0-5.2,0c-1.8,0-3,1.2-3,2.7
		c0,1.6,1.2,2.7,3.1,2.8C9.7,22.7,10.5,22.7,11.4,22.7z M11.2,50.9c1.1,0,1.9,0,2.8,0c0.7,0,1.3-0.1,1.9-0.5
		c0.9-0.7,1.4-1.8,1.1-2.9c-0.3-1.2-1.3-2-2.7-2.1c-1.9-0.1-3.8,0-5.6,0c-0.5,0-1,0.1-1.4,0.4c-0.9,0.5-1.5,1.6-1.3,2.6
		c0.2,1.2,0.9,2,2,2.3C9,51.1,10.2,50.9,11.2,50.9z M11.4,73.7C11.4,73.7,11.4,73.7,11.4,73.7c-0.9,0-1.8-0.1-2.6,0
		c-1.4,0.1-2.5,0.8-2.8,1.9c-0.6,1.8,0.7,3.5,2.8,3.6c1.8,0.1,3.5,0,5.3,0c0.6,0,1.2-0.1,1.8-0.5c1-0.7,1.4-1.8,1.1-2.9
		c-0.3-1.2-1.2-1.9-2.5-2.1C13.4,73.6,12.4,73.7,11.4,73.7z"/>
			<path d="M51.1,20c0,0.8-0.3,1.5-0.9,2.1c-3.7,3.7-7.4,7.5-11.2,11.2c-1.3,1.3-3,1.3-4.3,0c-2.1-2.1-4.2-4.1-6.2-6.2
		c-1.2-1.3-1.3-3.1-0.1-4.2c1.2-1.2,2.9-1.1,4.2,0.1c1.2,1.2,2.4,2.4,3.6,3.6c0.6,0.6,0.9,0.5,1.4,0c2.8-2.8,5.6-5.6,8.4-8.4
		c1.4-1.4,3.4-1.3,4.5,0.1C50.9,18.7,51.1,19.3,51.1,20z"/>
			<path d="M51.1,42.5c0,0.8-0.3,1.5-0.9,2.1c-3.7,3.7-7.4,7.5-11.2,11.2c-1.3,1.3-3,1.3-4.3,0c-2.1-2.1-4.2-4.1-6.2-6.2
		c-1.2-1.3-1.3-3.1-0.1-4.2c1.2-1.2,3-1.1,4.3,0.1c1.2,1.2,2.4,2.4,3.6,3.6c0.5,0.5,0.8,0.5,1.3,0c2.8-2.8,5.5-5.5,8.3-8.3
		c0.9-0.9,1.9-1.3,3.1-1C50.2,40.1,51.1,41.2,51.1,42.5z"/>
			<path d="M36.9,79.3c-0.8,0-1.5-0.3-2.1-0.9c-2.1-2.1-4.2-4.2-6.3-6.3c-1.2-1.3-1.2-3.1,0-4.2c1.1-1.1,2.9-1.1,4.1,0.1
		c1.2,1.2,2.4,2.4,3.6,3.6c0.6,0.6,0.9,0.5,1.5,0c2.7-2.7,5.4-5.5,8.2-8.2c0.6-0.6,1.2-1,2-1.1c1.3-0.1,2.4,0.5,3,1.6
		c0.5,1.1,0.4,2.4-0.5,3.3c-1.1,1.2-2.2,2.3-3.4,3.4c-2.5,2.5-5.1,5.1-7.6,7.6C38.6,78.8,37.9,79.3,36.9,79.3z"/>
			<path d="M68.1,28.5c-2.7,0-5.4,0-8.2,0c-1.9,0-3.1-1.2-3.1-2.9c0-1.7,1.2-2.9,3.1-2.9c5.5,0,11,0,16.5,0
		c1.9,0,3.1,1.2,3.1,2.9c0,1.7-1.2,2.8-3.1,2.9C73.6,28.5,70.9,28.5,68.1,28.5z"/>
			<path d="M68.2,51.1c-2.8,0-5.5,0-8.3,0c-1.9,0-3.1-1.2-3.1-2.9c0-1.8,1.2-2.9,3.2-2.9c5.4,0,10.9,0,16.3,0
		c1.9,0,3.2,1.2,3.2,2.9c0,1.7-1.3,2.9-3.2,2.9C73.6,51.1,70.9,51.1,68.2,51.1z"/>
			<path d="M79.4,79.3c-0.9,0-1.9,0-2.8,0c-1.7,0-2.8-1.2-2.9-2.8c0-1.9,0-3.8,0-5.7c0-1.7,1.3-2.8,2.9-2.9
		c1.7,0,2.8,1.2,2.9,2.9c0,0.9-0.4,1.9,0.2,2.5c0.6,0.6,1.6,0.2,2.4,0.2c1.7,0.1,2.9,1.2,2.9,2.9c0,1.7-1.2,2.9-2.9,2.9
		C81.3,79.3,80.3,79.3,79.4,79.3z"/>
		</SvgIcon>
	)
}
