import React from "react";

import { SvgIcon } from "@mui/material";

export default function ArrowLeftIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 21.7">
      <path d="M10.9,0c-0.2,0.1-0.4,0.2-0.5,0.4c-3,2.9-5.9,5.8-8.9,8.7c-0.4,0.3-0.7,0.7-1.1,1c-0.5,0.5-0.5,1,0,1.4
		c3.4,3.3,6.7,6.5,10.1,9.8c0.3,0.3,0.6,0.4,1,0.2c0.3-0.1,0.5-0.4,0.6-0.7c0-0.2,0-0.4-0.2-0.6c-0.1-0.1-0.1-0.2-0.2-0.2
		c-3.1-3-6.1-6-9.2-9c-0.2-0.2-0.2-0.2,0-0.4c3.1-3,6.2-6,9.3-9C11.9,1.3,12,1.1,12,0.8c0-0.4-0.3-0.6-0.6-0.8C11.2,0,11,0,10.9,0z"
      />
    </SvgIcon>
  )
}
