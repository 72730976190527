import * as React from "react";

import { Box, Container, Grid, Typography } from "@mui/material";
import { BookIcon, CheckIcon, ModelingIcon, ScrewIcon } from "Components/CustomIcons";
import useStyles from "./styles";


export default function IntroductionEngineering() {
  const classes = useStyles();

  return (
    <Box className={classes.liabilities}>
      <Container>
        <Typography variant="subtitle2" component="div" sx={{ color: "primary.main", textAlign: "center", marginBottom: "3rem" }}>
          Comprometidos con nuestro trabajo
        </Typography>
        <Grid container justifyContent="center">
          <Grid item lg={8} sm={10} xs={12}>
            <Typography variant="body1" sx={{ textAlign: "center", marginBottom: "5rem" }}>Brindamos mayor confianza a nuestros clientes para maximizar el valor de sus inversiones.</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <ModelingIcon sx={{ color: "secondary.dark", fontSize: "6rem" }} className={classes.icons} />
              <Typography variant="h6" component="h6" sx={{ textAlign: "center" }}>Modelamiento<br /> y gestión de BIM<br /> de proyectos</Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <CheckIcon sx={{ color: "secondary.dark", fontSize: "6rem" }} className={classes.icons} />
              <Typography variant="h6" component="h6" sx={{ textAlign: "center" }}>Sevicios<br /> integrales</Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <BookIcon sx={{ color: "secondary.dark", fontSize: "6rem" }} className={classes.icons} />
              <Typography variant="h6" component="h6" sx={{ textAlign: "center" }}>Estudios de<br /> factibilidad</Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={3} sm={4} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <ScrewIcon sx={{ color: "secondary.dark", fontSize: "6rem" }} className={classes.icons} />
              <Typography variant="h6" component="h6" sx={{ textAlign: "center" }}>Expedientes<br /> técnicos</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
