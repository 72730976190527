import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    bannerHome: {
      width: "100%",
      paddingTop: "calc(3.8rem + 3rem)",
      background: theme.palette.primary.main,
      "& .slick-dots": {
        bottom: 25,
        display: "flex !important",
        justifyContent: "center",
        alignItems: "center",
        "& li": {
          width: "auto",
          height: "auto",
          "& button": {
            width: ".8rem",
            height: ".8rem",
            "&::before": {
              content: "''",
              width: ".8rem",
              height: ".8rem",
              border: `.1rem solid ${theme.palette.primary.contrastText}`,
              background: theme.palette.primary.contrastText,
              borderRadius: 50,
              opacity: 1,
              overflow: "hidden",
            },
            "&:hover": {
              "&::before": {
                background: "rgba(255 255 255 / .50)",
              } ,             
            },
          },
          "&.slick-active": {
            "& button": {
              "&::before": {
                background: "transparent",
              },
            },
          },
        },
      },
    },
    slideCarousel: {
      width: "100%",
      height: "calc(85vh - (3.8rem + 2.8rem))",
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 3.8rem)",
      },
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "rgba(40 40 40 / .80)",
        zIndex: 0,
      },
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.57) 0%, transparent 35%)",
        zIndex: 1,
      },
    },
    bannerCaption: {
      position: "relative",
      height: "100%",
      width: "100%",
      zIndex: 2,
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
  })
);