import React from "react";

import { SvgIcon } from "@mui/material";

export default function LawsIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 91.3 102">
      <path d="M4.7,0c21.5,0,43,0,64.5,0c0.2,0.1,0.3,0.1,0.5,0.2c2.8,1.1,4.2,3.1,4.2,6.1c0,1.1,0,2.1,0,3.2
		c-0.1,0.9,0.3,1.2,1.2,1.2c3.2,0,6.4,0.1,9.6,0c4-0.2,6.7,2.9,6.7,6.7c-0.1,26-0.1,52,0,78c0,3.9-2.9,6.8-6.7,6.7
		c-9.5-0.1-19.1,0-28.6,0c-10.7,0-21.5,0-32.2,0c-3.7,0-6.2-2.6-6.2-6.3c0-1.1,0-2.2,0-3.3c0.1-0.9-0.3-1.2-1.2-1.2
		c-3.3,0-6.7,0-10,0C2.6,91.2,0,88.7,0,85C0,58.7,0,32.4,0,6.2c0-0.6,0-1.1,0.2-1.7C0.8,2.1,2.4,0.8,4.7,0z M4.1,45.5
		c0,12.9,0,25.9,0,38.8c0,2.1,0.7,2.8,2.8,2.8c15.7,0,31.4,0,47.1,0c1.3,0,1.3,0,1.3-1.3c0-2.7,0-5.4,0-8.1c0-3,2-5,5-5
		c2.6,0,5.2,0,7.8,0c1.6,0,1.6,0,1.6-1.5c0-2.8,0-5.5,0-8.3c0-2.4,0-4.8,0-7.2c0-1.4,0.9-2.3,2.2-2.2c1.2,0.1,1.9,0.9,1.9,2.4
		c0,6,0,12.1,0,18.1c0,1.2-0.4,2-1.2,2.8c-4.4,4.3-8.7,8.7-13.1,13.1c-0.9,0.9-1.8,1.2-3,1.2c-11,0-22.1,0-33.1,0
		c-0.3,0-0.6,0-0.9,0c-0.6,0-0.9,0.3-0.9,0.9c0,0.9,0,1.9,0,2.8c0,2.2,0.7,2.9,2.9,2.9c19.9,0,39.8,0,59.7,0c2.2,0,2.9-0.7,2.9-2.9
		c0-25.7,0-51.5,0-77.2c0-2.2-0.7-2.9-2.9-2.9c-3,0-6,0-9.1,0c-1.4,0-1.4,0-1.4,1.4c0,6.8,0,13.7,0,20.5c0,0.3,0,0.7,0,1
		c-0.1,1.3-0.9,2.2-2.1,2.2c-1.2,0-2-0.9-2-2.1c0-0.4,0-0.7,0-1.1c0-9.7,0-19.4,0-29.1c0-0.4,0-0.7,0-1.1c-0.1-1.5-0.8-2.2-2.3-2.3
		c-0.3,0-0.7,0-1,0c-19.6,0-39.3,0-58.9,0c-0.4,0-0.8,0-1.2,0c-1.3,0.1-2,0.8-2.1,2.1c0,0.4,0,0.8,0,1.2C4.1,20.1,4.1,32.8,4.1,45.5
		z M66.3,76.9c-2,0-3.9,0-5.8,0c-0.6,0-1,0.3-1,0.9c0,2,0,4,0,6C61.8,81.5,64,79.2,66.3,76.9z"/>
      <path d="M37,15c7.4,0,14.8,0,22.2,0c1.8,0,2.8,0.7,2.8,2.1c0,1.3-1,2.1-2.8,2.1c-14.9,0-29.8,0-44.6,0
		c-0.3,0-0.6,0-0.9,0c-1.1-0.1-1.9-1-1.9-2.1c0-1,0.8-1.9,1.9-2c0.4,0,0.8,0,1.2,0C22.2,15,29.6,15,37,15z"/>
      <path d="M36.9,25.4c7.3,0,14.6,0,22,0c0.4,0,0.7,0,1.1,0c1.2,0.1,2,1,2,2.1c0,1.1-0.8,1.9-2,2c-0.3,0-0.7,0-1,0
		c-14.8,0-29.6,0-44.3,0c-0.3,0-0.7,0-1,0c-1.1-0.1-1.9-1-1.9-2c0-1.1,0.8-2,1.9-2.1c0.4,0,0.8,0,1.2,0
		C22.2,25.4,29.6,25.4,36.9,25.4z"/>
      <path d="M37,35.7c7.4,0,14.8,0,22.2,0c1.8,0,2.8,0.7,2.8,2.1c0,1.3-1,2.1-2.8,2.1c-14.9,0-29.8,0-44.6,0
		c-0.3,0-0.6,0-0.9,0c-1.1-0.1-1.9-1-1.9-2.1c0-1,0.8-1.9,1.9-2c0.4,0,0.8,0,1.2,0C22.2,35.7,29.6,35.7,37,35.7z"/>
      <path d="M17.8,70.9c-4,0-7.1-3.3-7.1-7.4c0-4,3.3-7.1,7.4-7.1c3.9,0.1,7.1,3.3,7,7.4C25.2,67.8,21.9,71,17.8,70.9z
		 M21,63.8c0.1-1.6-1.3-3.1-2.9-3.2c-1.6-0.1-3.1,1.3-3.2,2.9c-0.1,1.6,1.3,3.1,2.9,3.2C19.5,66.8,20.9,65.5,21,63.8z"/>
      <path d="M24.4,46.1c3.3,0,6.7,0,10,0c1.2,0,2,0.5,2.3,1.5c0.4,1.3-0.6,2.6-2,2.6c-1.6,0.1-3.2,0-4.9,0
		c-5.2,0-10.5,0-15.7,0c-1.8,0-2.9-1.5-2.1-3c0.4-0.9,1.2-1.2,2.1-1.2C17.5,46.1,21,46.1,24.4,46.1z"/>
      <path d="M37.2,65.7c-2.1,0-4.2,0-6.4,0c-1.5,0-2.4-0.8-2.4-2.1c0-1.2,0.9-2,2.3-2c4.3,0,8.6,0,12.9,0
		c1.5,0,2.4,0.8,2.4,2.1c0,1.2-0.9,2-2.4,2C41.5,65.7,39.4,65.7,37.2,65.7z"/>
      <path d="M42,48.2c0-1.2,0.9-2.1,2.1-2.1c1.1,0,2,0.9,2,2c0,1.2-0.9,2.1-2.1,2.1C42.9,50.2,42,49.3,42,48.2z" />
      <path d="M73.8,46.9c0,1.1-0.9,2.1-2.1,2.1c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2.1C72.8,44.8,73.7,45.7,73.8,46.9z" />
    </SvgIcon>
  )
}
