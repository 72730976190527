import React from "react";

import { SvgIcon } from "@mui/material";

export default function ArrowRightIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 11.7 21.7">
      <path d="M1.1,0c0.2,0.1,0.4,0.2,0.5,0.4c2.9,2.9,5.8,5.8,8.7,8.7c0.3,0.3,0.7,0.7,1.1,1c0.5,0.5,0.5,1,0,1.4
		c-3.3,3.3-6.6,6.5-9.8,9.8c-0.3,0.3-0.6,0.4-1,0.2C0.2,21.5,0,21.2,0,20.9c0-0.2,0-0.4,0.2-0.6c0.1-0.1,0.1-0.2,0.2-0.2
		c3-3,6-6,9-9c0.2-0.2,0.2-0.2,0-0.4c-3-3-6-6-9.1-9C0.1,1.3,0,1.1,0,0.8C0,0.4,0.3,0.1,0.6,0C0.8,0,0.9,0,1.1,0z"/>
    </SvgIcon>
  )
}
