import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    carrouselGallery: {
      width: "100%",
      marginBottom: "1.2rem",
    },
    btnClose: {
      transition: "all .4s ease !important",
      "& svg": {
        fontSize: 28,        
      },
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main} !important`,
        transform: "scale(1.1)",
      },
    },
    boxImage: {
      padding: 6,
      width: "100%",
      height: 340,
      overflow: "hidden",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectPosition: "top center",
      },
    },
  })
);