import * as React from "react";

import BackupText from "Components/BackupText";
import BannerUs from "Components/BannerUs";
import PictureAlone from "Components/PictureAlone";
import Recognitions from "Components/Recognitions";
import SectionGrayContact from "Components/SectionGrayContact";
import ServicesUs from "Components/ServicesUs";

import imgUs from "./../../Assets/images/footer_us.jpg"

export default function UsPage() {
  return (
    <>
      <BannerUs />
      <ServicesUs />
      <Recognitions bg={"primary.contrastText"} />
      <BackupText />
      <PictureAlone picture={imgUs} />
      <SectionGrayContact />
    </>
  );
}
