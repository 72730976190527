import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    sectionGrayContact: {
      padding: "3.4rem 0",
      [theme.breakpoints.down("sm")]: {
        padding: "3rem 0",
      },
    },
    textLeft: {
      paddingRight: "1.5rem",
      [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
        paddingBottom: "1rem",
      },   
    },
    contSectionGrayContact: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },      
    },
  })
);