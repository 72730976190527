import HelveticaNeueBold from "./../fonts/HelveticaNeuBold.ttf";
import HelveticaRegular from "./../fonts/Helvetica.ttf";
import Optimal from "./../fonts/Optima-LT-Std-Demi.ttf";
import OptimalBold from "./../fonts/Optima-LT-Std-Bold.ttf";
import OptimalRegular from "./../fonts/Optima-LT-Std-Medium.ttf";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#282828",
      contrastText: "#ffffff",
      light: "#d4d4d4",
      dark: "#000000",
    },
    secondary: {
      main: "#913617",
      light: "rgba(212 212 212 / .4)",
      dark: "#803617",
    },
    info: {
      main: "#ffffff",
    },
    text: {
      secondary: "#c5c5c5",
      
    },
  },
  typography: {
    fontFamily: ["'HelveticaRegular'", "'sans-serif'"].join(","),
    fontSize: 15,
    h2: {
      fontFamily: ["'OptimalLTStdDemiBold'", "'sans-serif'"].join(","),
      lineHeight: .9,
      letterSpacing: "normal",
      fontSize: 80,
    },
    h3: {
      color: "#ffffff",
      fontFamily: ["'OptimalLTStdDemiBold'", "'sans-serif'"].join(","),
      fontSize: 18,
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    h5: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    h4: {
      fontSize: "1.6rem",
      fontFamily: ["'OptimalLTStdBold'", "'sans-serif'"].join(","),
      lineHeight: 1.2,
      letterSpacing: "normal",
    },
    h6: {
      fontFamily: ["'OptimalLTStdDemiBold'", "'sans-serif'"].join(","),
      lineHeight: 1.2,
      letterSpacing: "normal",
      fontSize: 21,
    },
    subtitle1: {
      fontSize: "30px",
      fontFamily: ["'OptimalLTStdDemiBold'", "'sans-serif'"].join(","),
      lineHeight: 1.1,
      letterSpacing: "normal",
      "@media (max-width:600px)": {
        fontSize: "27px",
      },
    },
    subtitle2: {
      fontSize: "25px",
      fontFamily: ["'OptimalLTStdDemiBold'", "'sans-serif'"].join(","),
      lineHeight: 1,
      marginBottom: "1.2rem",
      letterSpacing: "normal",
      paddingBottom: 16,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      "&::after": {
        content: "''",
        position: "absolute",
        bottom: 0,
        width: 94,
        height: ".25rem",
        background: "#913617",
      },
    },
    button: {
      fontFamily: ["'HelveticaNeueBold'", "'sans-serif'"].join(","),
      fontWeight: 700,
      fontSize: 14,
      letterSpacing: ".07rem",
      lineHeight:  1.2,
    },
    body1: {
      lineHeight: 1.2,
      color: "#282828",
      letterSpacing: ".07rem",
    },
    caption: {
      fontSize: 14,
      lineHeight: 1.2,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'OptimalLTStdDemiBold';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('OptimalLTStdDemiBold'), local('Optimal-DemiBold'), url(${Optimal}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'OptimalLTStdBold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('OptimalLTStdBold'), local('Optimal-Bold'), url(${OptimalBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'HelveticaNeueBold';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('HelveticaNeueBold'), local('HelveticaNeue-Bold'), url(${HelveticaNeueBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'OptimalRegular';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('OptimalRegular'), local('OptimalRegular'), url(${OptimalRegular}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'HelveticaRegular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('HelveticaRegular'), local('HelveticaRegular'), url(${HelveticaRegular}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          padding: "8px 16px",
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0 0 0 / .9)",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
          overflow: "initial",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          transform: "none",
        },
      },
    },
  },
});
