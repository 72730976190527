import React from "react";

import { SvgIcon } from "@mui/material";

export default function LeavesIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 121.8 120.3">
      <path d="M47,1.1c3.1,3.3,6.4,6.4,9.2,10c4.7,5.9,8.3,12.3,10.2,19.7c0.9,3.7,1.4,7.4,1.3,11.2c0,1.5,0,1.5,1.4,1.2
		c4.1-1,8.4-1.3,12.5-2.5c6.6-1.9,12.3-5.4,17.6-9.8c4.2-3.5,7.9-7.5,11.2-11.7c0.8-1,0.9-1,1.4,0.2c2.9,7.2,5.5,14.5,6.8,22.2
		c2.8,15.7,0.8,30.6-7.6,44.4c-4.8,7.9-11.2,14.4-18.4,20.1c-5.7,4.5-11.7,8.5-18.5,11.1c-5.3,2-10.7,3.2-16.3,3.7
		c-5.1,0.4-10.1,0.3-14.9-1.4c-6.4-2.2-11.7-5.9-15.3-11.8c-0.4-0.7-0.9-0.8-1.7-0.8c-8.1,0.8-16.1,0.6-24-1.8
		c-0.9-0.3-1.1-0.6-0.7-1.5c0.7-1.7,1.3-3.4,1.9-5.2c0.3-0.8,0.6-1,1.5-0.8c5.7,1.7,11.6,1.9,17.5,1.7c0.3,0,0.7,0,1-0.1
		c0.6-0.1,0.9-0.4,0.7-1.1c-0.7-3-0.9-6-0.8-9.1c0-0.5-0.1-1-0.7-1.2c-8.5-3.3-13-9.7-14.8-18.3c-2-9.6,0.2-18.4,5.5-26.5
		c3.8-5.8,9-10.3,14.8-14c4.4-2.8,8.5-5.9,11.4-10.3c3.6-5.4,5.9-11.4,7.3-17.8C46.6,1.1,46.8,1.1,47,1.1z M111.8,53.6
		c0-5.1-0.9-11.7-2.7-18c-0.7-2.4-0.7-2.4-2.6-0.7c-8.3,7.7-18.1,12.7-29.3,14.7c-7.9,1.4-15.7,3.1-22.9,6.7
		C45.1,60.9,38.1,67.5,33.9,77c-2.8,6.4-4,12.9-2.4,19.8c0.2,1,0.6,1.3,1.6,1c10.1-2.3,19.3-6.5,27.9-12.2
		c8-5.3,15.1-11.6,21.6-18.5c0.6-0.6,1-0.7,1.6-0.1c1.3,1.3,2.7,2.5,4.1,3.7c0.8,0.6,0.7,1.1,0,1.8c-3.7,4-7.7,7.8-11.9,11.3
		c-11.8,9.9-24.8,17.5-39.8,21.2c-1.2,0.3-1.2,0.3-0.4,1.2c4,4.5,9.3,6.2,15.1,6.7c8.7,0.7,17-1.3,24.8-5.2
		c7.2-3.6,13.2-8.7,18.9-14.3C105.9,82.8,111.9,69.9,111.8,53.6z M14.7,62.8c-0.1,2.2,0.3,4.4,0.8,6.5c1.1,4.5,3.4,8.2,7.6,10.5
		c1.3,0.7,1.4,0.7,1.8-0.7c3.2-10.1,9.1-18.3,17.6-24.6c4.9-3.6,10.2-6.4,16-8.3c0.7-0.2,1.1-0.5,1.1-1.3c0.1-2.8,0.1-5.7-0.3-8.5
		c-1-7.4-3.9-13.9-8.7-19.6c-0.5-0.6-0.8-0.8-1.2,0.1c-3.6,7.9-9.4,13.9-16.7,18.5c-3.4,2.1-6.4,4.6-9.2,7.4
		C18,48.3,14.8,54.8,14.7,62.8z"/>
	<path d="M93.2,55.2c0.2,0.1,0.4,0.2,0.6,0.3c1.6,1.2,3.2,2.5,4.8,3.7c0.5,0.4,0.5,0.8,0.1,1.3
		c-1.3,1.6-2.6,3.1-3.8,4.7c-0.5,0.6-0.9,0.6-1.4,0.1c-1.5-1.3-3-2.5-4.5-3.8c-0.5-0.4-0.7-0.8-0.2-1.4c1.2-1.5,2.5-3,3.7-4.5
		C92.7,55.5,92.8,55.3,93.2,55.2z"/>
    </SvgIcon>
  )
}
