import React from "react";

import { SvgIcon } from "@mui/material";

export default function ModelingIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 118.6 114.3">
      <path d="M93.6,90.8c0.7-1.5-0.5-2.3-1.8-3.1c-7.6-4.6-15.2-9.3-22.8-14c-0.9-0.6-1.6-0.7-2.6,0.1
	c-3.3,2.7-7.2,3.6-11.5,3.1c-0.8-0.1-1.1,0.1-1.3,0.8c-1.6,4.8-3.2,9.5-4.8,14.3c-0.3,0.7-0.1,1.3,0.4,1.9c3.1,3.7,3.8,7.9,1.8,12.4
	c-2,4.4-5.6,6.7-10.4,7c-5.9,0.3-11.5-4.9-11.9-10.7c-0.5-7.6,5.5-12.5,10.7-12.7c0.9,0,1.3-0.4,1.6-1.2c1.6-5,3.3-9.9,5-14.9
	c0.2-0.7,0.2-1.2-0.3-1.7c-0.8-0.9-1.6-1.8-2.1-2.9c-0.3-0.7-0.9-0.9-1.6-0.7c-5,1-9.9,2-14.9,3c-0.8,0.2-0.9,0.8-1.1,1.4
	c-1.3,4.7-4.3,7.7-9.1,8.8c-6.4,1.4-13-3.3-13.9-9.8C2.2,65,7.2,58.8,13.8,58.3c4-0.3,7.3,1.2,9.9,4.2c0.4,0.5,0.8,0.6,1.4,0.5
	c5.2-1.1,10.5-2.1,15.8-3.1c0.6-0.1,0.9-0.4,1-1c0.2-1.4,0.7-2.8,1.3-4c0.4-0.7,0-1.1-0.4-1.6c-2.4-2.4-4.7-4.7-7.1-7.1
	C30,40.5,24.3,34.8,18.6,29.1c-0.7-0.7-1.2-0.8-2.2-0.5C10,30.8,3,26.9,1.3,20.2c-1.6-6.5,2.9-13.3,9.5-14.3
	c8.5-1.3,15.5,6.6,13.1,14.9c-0.3,1-0.1,1.5,0.6,2.2c8.1,8,16.1,16,24.1,24.1c0.7,0.7,1.2,0.8,2.1,0.4c3.3-1.6,6.7-2,10.3-0.9
	c0.8,0.3,1.5,0.1,2.1-0.5C73.4,36.9,83.7,27.8,94,18.7c0.8-0.7,1-1.4,0.6-2.4c-1.5-4.7-0.2-8.9,3.4-12.1c3.7-3.3,8-4,12.6-1.9
	c4.4,2,6.8,5.6,6.9,10.4c0.1,8-7.4,13.7-15.1,11.4c-0.9-0.3-1.5-0.1-2.2,0.5C92,31.8,83.9,39,75.7,46.2c-1.9,1.7-3.7,3.3-5.6,5
	c-0.6,0.5-0.8,0.9-0.3,1.7c2.5,3.6,3.4,7.5,2.4,11.8c-0.2,1,0.3,1.3,1,1.7c6.4,3.9,12.8,7.8,19.2,11.8c1.7,1.1,3.5,2.1,5.2,3.2
	c0.7,0.5,1.3,0.4,2,0c6.8-3.8,15.1-0.3,17,7.3c1.2,4.7-0.1,8.9-4,12c-3.8,3.1-8.1,3.6-12.5,1.4C95.9,100,93.4,95.8,93.6,90.8z
	 M64.1,61.5c0-4-3-6.9-7-6.9c-3.9,0-7,3.1-6.9,7c0,3.9,3.2,7.1,7,7C61.1,68.5,64.1,65.4,64.1,61.5z M18,70c0-1.8-1.5-3.3-3.3-3.2
	c-1.7,0-3.2,1.6-3.2,3.2c0,1.7,1.5,3.2,3.2,3.2C16.5,73.3,18,71.8,18,70z M40.4,104.7c1.8,0,3.3-1.4,3.3-3.2c0-1.7-1.4-3.2-3.2-3.2
	c-1.8,0-3.3,1.5-3.3,3.3C37.2,103.2,38.7,104.6,40.4,104.7z M105.3,94.7c1.8,0,3.2-1.4,3.2-3.1c0-1.8-1.5-3.2-3.3-3.2
	c-1.8,0-3.2,1.5-3.2,3.3C102.1,93.4,103.5,94.7,105.3,94.7z M108.9,12.8c0-1.9-1.3-3.2-3.1-3.2c-1.8,0-3.2,1.4-3.2,3.1
	c0,1.9,1.3,3.2,3.2,3.2C107.7,16,108.9,14.8,108.9,12.8z M9.6,17.6c0,1.8,1.4,3.2,3.1,3.2c1.7,0,3.2-1.6,3.2-3.3
	c0-1.8-1.4-3.2-3.1-3.2C11,14.3,9.6,15.7,9.6,17.6z"/>
    </SvgIcon>
  )
}
