import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";
import img from "./../../Assets/images/bg_section_video.jpg"
export default makeStyles((theme: Theme) =>
  createStyles({
    descriptionVideo: {
      backgroundImage: `url(${img})`,
      backgroundSize: "cover",
      backgroundPosition: "top center",
      padding: "8.5rem 0",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        padding: "6rem 0",
      },
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "rgba(145 54 23 / .94)",
        zIndex: 0,
      },
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundImage: "linear-gradient(to top, rgba(0, 0, 0, 0.45) 0%, transparent 60%)",
        zIndex: 1,
      },
    },
    textDescriptionVideo: {
      position: "relative",
      zIndex: 2,
    },
    titleDescriptionVideo: {
      marginBottom: "1.4rem  !important",
    },
    buttonDescriptionVideo: {
      marginTop: "2rem !important",
    },
  })
);