import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./styles.css";

import { Box, Button, ButtonProps, Container, Grid, Typography } from "@mui/material";

import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useStyles from "./styles";

interface SlidesCarrousel {
  title: string;
  slug: string;
  subTitle: string;
  image: string;
}
const portafolioData = require("./../../_portafolio.json");
const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: ".1rem solid #ffffff",
  "&:hover": {
    border: ".1rem solid #ffffff",
    background: "#ffffff",
    color: "#913617",
  },
}));

const loadImage = (imageName: string) => (require(`./../../Assets/images/${imageName}`).default);
export default function CarouselPortafolio() {
  const classes = useStyles();
  
  const portafolio = portafolioData.map((slide: SlidesCarrousel) => {
    return (
      <Link to={`/portafolio/${slide.slug}`} className={classes.contItemCarousel} style={{}} key={slide.slug}>
        <Grid item xs={12}>
          <Box className={classes.contCardPortafolio} key={slide.slug}>
            <Box sx={{ backgroundImage: `url(${loadImage(slide.image)})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", height: "100%", width: "100%" }} className={classes.cardPortafolio}>
              <Box sx={{ position: "relative", width: "100%", height: "100%", display: "flex", alignItems: "center", zIndex: 2 }}>
                <Box sx={{ width: "100%", display: "flex", alignItems: "center", flexWrap: "wrap", maxHeight: "100%", overflow: "hidden", position: "absolute", bottom: 0, left: 0 }} className={classes.boxDesc}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} className={classes.titleCard}>
                    <Typography variant="h6" component="h6" sx={{ color: "primary.contrastText" }} className="subTitle">{slide.subTitle}</Typography>
                  </Box>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} className={classes.descriptionCard}>
                    <Typography variant="body1" sx={{ color: "primary.contrastText" }} className="titleCard">
                      {slide.title}
                    </Typography>
                    <ColorButton variant="outlined" size="medium" color="info" className="btnMore">CONOCE MÁS</ColorButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Link>
    );
  });

  return (
    <>
      <Box className={classes.contSectionPortafolio}>
        <Container>
          <Typography variant="subtitle2" component="div" sx={{ color: "primary.main", textAlign: "center", marginBottom: "4rem" }}>
            Conoce algunos de nuestros proyectos
          </Typography>
        </Container>
        {portafolioData && (
          <Box className={classes.mainContent}>
              {portafolio}
          </Box>
        )}
      </Box>
    </>
  )
}
