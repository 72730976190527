import { createStyles, makeStyles } from "@mui/styles";

import { Theme } from "@mui/material/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    logoProject: {
      width: 190,
    },
    logoProjectSigular: {
      width: 170,
    },
    contLogoProyect: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    contImage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "1.4rem",
      minHeight: 100,
    },
    titleWelcome: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      paddingBottom: "1rem",
      marginBottom: "2.4rem !important",
      "&::after": {
        position: "absolute",
        content: "''",
        height: ".25rem",
        width: "40%",
        background: theme.palette.secondary.main,
        bottom: 0,
      },
    },
    btnClose: {
      transition: "all .4s ease !important",
      "& svg": {
        fontSize: 28,        
      },
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main} !important`,
        transform: "scale(1.1)",
      },
    },
  })
);