import React from "react";

import { SvgIcon } from "@mui/material";

export default function CheckIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 119.5 119.5">
      <path d="M54.9,118.6c-3-0.4-6.1-0.8-9.1-1.5c-16.3-4.2-28.7-13.5-37.3-28C4.4,82,1.8,74.3,1,66.1
		C-0.6,49,4,33.8,15.3,20.8C24.1,10.7,35.2,4.3,48.4,1.7c3.7-0.7,7.3-1.1,11.1-1.1c2.8,0,4.8,1.9,4.9,4.5c0,2.6-2,4.6-4.7,4.6
		c-5.6,0.1-11,0.8-16.2,2.8C25.2,19.4,14,32.3,10.6,51.4C7.9,66.1,11.6,79.5,21,91.1c8.1,10,18.6,16.1,31.3,17.9
		c14.4,2.1,27.5-1.5,38.9-10.7c9.9-8.1,15.9-18.6,17.9-31.2c0.4-2.4,0.5-4.8,0.6-7.2c0-2,0.9-3.6,2.8-4.4c1.7-0.7,3.4-0.4,4.8,0.8
		c0.7,0.6,1.1,1.4,1.4,2.2c0,2,0,4,0,6c0,0.2-0.1,0.4-0.1,0.6c-0.8,8.6-3.3,16.7-7.7,24.1c-8.5,14.4-21,23.7-37.2,27.9
		c-3,0.8-6,1.1-9,1.5C61.4,118.6,58.1,118.6,54.9,118.6z"/>
      <path d="M116.7,20c0.1,1.3-0.6,2.4-1.5,3.4c-4.6,4.6-9.2,9.2-13.9,13.8c-1.9,1.9-3.9,3.8-5.8,5.8
		C83.5,55,71.4,67.1,59.3,79.1c-2.5,2.5-5.1,2.5-7.6-0.1c-0.2-0.2-0.3-0.3-0.5-0.5c-7-7.6-13.9-15.1-20.9-22.7
		c-1.1-1.2-1.9-2.4-1.7-4.1c0.2-1.9,1.2-3.2,3-3.8c1.8-0.6,3.5-0.2,4.9,1.2c2.2,2.3,4.3,4.6,6.4,6.9c3.9,4.3,7.9,8.6,11.8,12.9
		c0.7,0.8,1.2,0.7,1.9,0c17.2-17.2,34.5-34.4,51.7-51.6c1.5-1.5,3.1-2.3,5.3-1.6C115.5,16.3,116.7,17.9,116.7,20z"/>
    </SvgIcon>
  )
}
