import * as React from "react";

import BannerHome from "Components/BannerHome";
import Building from "Components/Building";
import DescriptionVideo from "Components/DescriptionVideo";
import Introduction from "Components/Introduction";
import MissionVision from "Components/MissionVision";
import OurClients from "Components/OurClients";
import Recognitions from "Components/Recognitions";
import SectionGrayContact from "Components/SectionGrayContact";
import WelcomeModal from "Components/WelcomeModal";

export default function HomePage() {
  return (
    <>
      <BannerHome />
      <Introduction />
      <MissionVision />
      <Recognitions bg={"secondary.light"}/>
      <DescriptionVideo />
      <Building />
      <OurClients />
      <SectionGrayContact />
      <WelcomeModal />
    </>
  );
}
